import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import SearchForm from "./SearchForm/SearchForm";
import AddAppointment from "./AddAppointment/AddAppointment";
import SlotsFilters from "./SlotsFilters/SlotsFilters";
import Calendar from "./Calendar/Calendar";
import SearchResults from "./SearchResults/SearchResults";
import SlotsList from "./SlotsList/SlotsList";
import BookAppointment from "./BookAppointment/BookAppointment";
import SearchFilter from "./SearchFilter/SearchFilter";
import DiscardChangesOverlay from "components/Share/DiscardChangesOverlay/DiscardChangesOverlay";
import { releaseSlot, bookAppointmentError, holdAppointmentError } from "actions/bookAppointment";
import InformationalOverlay from "components/Share/InformationalOverlay/InformationalOverlay";
import { closeBooking, closeInternalEvent } from "actions/panel";
import { clearForm } from "actions/form";

import "./styles.scss";
import locale from "service/locale";
import AddInternalEvent  from "./AddInternalEvent/AddInternalEvent";
import { showSelectedSlot } from "actions/calendarView";

type State = {
  isDiscardChangesOverlayOpen: boolean
};

type Props = {
  contentClassName: string,
  isActiveAppointmentSlots: boolean,
  isActiveSearchResults: boolean,
  isActiveBooking: boolean,
  releaseSlot: () => Function,
  clearForm: () => any,
  bookAppointmentError: () => any,
  holdAppointmentError: () => any,
  closeBooking: () => any,
  isSlotTaken: boolean | null,
  calendars: any[],
  services: any[],
  isActiveInternalEvent: boolean,
  closeInternalEvent: () => any,
  showSelectedSlot: (zIndex: number | null) => Action,
  bookAppointmentId: string
};

const getClassNameContent = (
  isActiveAgenda,
  isActiveSearchResults,
  isActiveAppointmentSlots,
  isActiveBooking,
  isActiveInternalEvent
) => {
  return `patient-care-main-holder ${isActiveAgenda ? "" : ""} ${
    isActiveSearchResults || isActiveAppointmentSlots || isActiveBooking || isActiveInternalEvent
      ? "full-width"
      : ""
  }`;
};

const mapStateToProps = state => ({
  contentClassName: getClassNameContent(
    state.panel.isActiveAgenda,
    state.panel.isActiveSearchResults,
    state.panel.isActiveAppointmentSlots,
    state.panel.isActiveBooking,
    state.panel.isActiveInternalEvent
  ),
  isActiveAppointmentSlots: state.panel.isActiveAppointmentSlots,
  isActiveSearchResults: state.panel.isActiveSearchResults,
  isActiveBooking: state.panel.isActiveBooking,
  isActiveInternalEvent: state.panel.isActiveInternalEvent,
  isSlotTaken: state.book.isSlotTaken,
  bookAppointmentId: state.book.appointmentId,
  calendars: state.branchDetails.calendarsList,
  services: state.branchDetails.services
});

const mapDispatchToProps = (dispatch: Function) => ({
  clearForm: () => dispatch(clearForm()),
  closeBooking: () => dispatch(closeBooking()),
  releaseSlot: () => dispatch(releaseSlot()),
  bookAppointmentError: () => dispatch(bookAppointmentError()),
  holdAppointmentError: () => dispatch(holdAppointmentError()),
  closeInternalEvent: () => dispatch(closeInternalEvent()),
  showSelectedSlot: zIndex => dispatch(showSelectedSlot(zIndex)),
});

class CalendarView extends Component<Props, State> {
  state = {
    isDiscardChangesOverlayOpen: false
  };

  componentDidMount() {
    // #53895
    // This is triggering an error to an incorrect API endpoint.
    // I am not sure why was this added but it's part of another ticket fix so I added an extra
    // condition to releaseSlor only if if has a book.appointmentId
    const { bookAppointmentId, releaseSlot } = this.props;
    if (bookAppointmentId) {
      releaseSlot();
    }
  }

  handleDiscardChanges = () => {
    const { closeBooking, clearForm, releaseSlot, closeInternalEvent, showSelectedSlot } = this.props;
    showSelectedSlot(null);
    this.setState(
      {
        isDiscardChangesOverlayOpen: false
      },
      () => {
        clearForm();
        closeBooking();
        releaseSlot();
        closeInternalEvent();
      }
    );
  };

  handleCloseExpiredSlotDialog = () => {
    const { closeBooking, clearForm, bookAppointmentError, closeInternalEvent } = this.props;
    clearForm();
    closeBooking();
    closeInternalEvent();
    bookAppointmentError();
  };

  handleStay = () => {
    this.setState({ isDiscardChangesOverlayOpen: false });
  };

  openDiscardChangesOverlay = (e: Event) => {
    e && e.preventDefault();
    this.setState({ isDiscardChangesOverlayOpen: true });
  };

  handleCloseTakenSlotDialog = () => {
     const { holdAppointmentError } = this.props;
    holdAppointmentError();
  };

  render() {
    const {
      contentClassName,
      isActiveAppointmentSlots,
      isActiveSearchResults,
      isActiveBooking,
      isActiveInternalEvent,
      isSlotTaken,
      calendars,
      services
    } = this.props;
    const { isDiscardChangesOverlayOpen } = this.state;
    const showNewAppointmentButton = calendars.length && services.length;

    return (
      <Fragment>
        <div className={contentClassName}>
          <div className="patient-care-main-content">
            <div className="patient-care-calendar-filters-holder">
              {isActiveAppointmentSlots ? (
                <SlotsFilters />
              ) : (
                <div className="patient-care-container">
                  <div className="patient-care-row">
                    <SearchForm />
                    {isActiveSearchResults
                      ? <SearchFilter />
                      : showNewAppointmentButton
                        ? <AddAppointment />
                        : null
                    }
                  </div>
                </div>
              )}
            </div>
            <div className={isActiveSearchResults || isActiveAppointmentSlots ? "active-results" : ""}>
              <Calendar />
            </div>
          </div>
        </div>
        {isActiveSearchResults && <SearchResults />}
        {isActiveAppointmentSlots && <SlotsList />}
        {isActiveBooking && (
          <BookAppointment
            handleOpenDiscardChangesOverlay={this.openDiscardChangesOverlay}
            handleDiscardChanges={this.handleDiscardChanges}
            handleCloseExpiredSlotDialog={this.handleCloseExpiredSlotDialog}
          />
        )}
        {isActiveInternalEvent && (
          <AddInternalEvent
            handleOpenDiscardChangesOverlay={this.openDiscardChangesOverlay}
            handleDiscardChanges={this.handleDiscardChanges}
            handleCloseExpiredSlotDialog={this.handleCloseExpiredSlotDialog}
          />
        )}
        {isDiscardChangesOverlayOpen && (
          <DiscardChangesOverlay
            handleDiscardChanges={this.handleDiscardChanges}
            handleStay={this.handleStay}
          />
        )}
        {
          isSlotTaken && (
            <InformationalOverlay
              header={locale.BookAppointment.expiredSlotModalHeader}
              content={locale.BookAppointment.takenSlotModalContent}
              buttonOk={locale.BookAppointment.expiredSlotModalButton}
              handleOk={this.handleCloseTakenSlotDialog}
            />
          )
        }
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarView);
