import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { ReportsSidebar, ReportsSidebarMenu } from 'components/Pages/Reports/wrappers';
import * as RoutesConstants from 'constants/RoutesConstants';
import * as RolesConstants from 'constants/RolesConstants';
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import locale from 'service/locale';
import './styles.scss';

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  branchDetails: state.branchDetails,
  organisationDetails: state.organisationDetails,
  spReportsLastUpdatedDateTime: state.reportsSmartPharmacy.metrics.summary.data.lastUpdatedDateTime,
  basicReportsLastRunDate: state.reportsBasic.lastRunDate,
});

type Props = {
  location: any,
};

type State = {
  // isExpand: boolean,
};

class Sidebar extends Component<State, Props> {
  state = {
    // isExpand: true,
  }

  render() {
    const { location, currentRole, organisationDetails, branchDetails, spReportsLastUpdatedDateTime, basicReportsLastRunDate } = this.props;
    const smartPharmacyUrl = `${RoutesConstants.REPORTS}/${RoutesConstants.SMART_PHARMACY}`;
    const basicReportsUrl = `${RoutesConstants.REPORTS}/${RoutesConstants.BASIC}`;
    const customReportsUrl = `${RoutesConstants.REPORTS}/${RoutesConstants.CUSTOM}`;
    const isSuperAdmin = currentRole.role === RolesConstants.ADMIN;

    const renderBasicReportMenuOption = () => {
      return (
        <li>
          <NavLink to={basicReportsUrl}>
            <span>{locale.Reports.sidebar.services}</span>
          </NavLink>
        </li>
      );
    }

    const renderSPReportMenuOption = () => {
      const isOrganizationAdmin = currentRole.role === RolesConstants.ORGANIZATION_ADMIN;
      const isBranchAdmin = currentRole.role === RolesConstants.BRANCH_ADMIN;
      const isSmartPharmacyEnabledOrg = organisationDetails && organisationDetails.supportedFeatures && organisationDetails.supportedFeatures.find(feature => feature.name === 'SmartPharmacy') && organisationDetails.supportedFeatures.find(feature => feature.name === 'SmartPharmacy').isEnabled;
      const isSmartPharmacyEnabledBranch = branchDetails && branchDetails.supportedFeatures && branchDetails.supportedFeatures.find((feature) => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS) && branchDetails.supportedFeatures.find((feature) => feature.name === OrganisationsConstants.SUPPORTEDFEATURE_MEDICATIONNOTIFICATIONS).isEnabled;
      
      if (isSuperAdmin || (isOrganizationAdmin && isSmartPharmacyEnabledOrg) || (isBranchAdmin && isSmartPharmacyEnabledBranch)) {
        return (
          <li>
            <NavLink to={smartPharmacyUrl}>
              <span>{locale.Reports.sidebar.smartPharmacy}</span>
            </NavLink>
          </li>
        );
      }
      return null;
    }

    const renderLastUpdated = () => {
      if (location.pathname === smartPharmacyUrl && spReportsLastUpdatedDateTime) {
        return (
          <p>
            <span>{locale.Reports.sidebar.lastUpdatedAt}</span>
            <span>{moment(spReportsLastUpdatedDateTime).format('hh:mm')} on {moment(spReportsLastUpdatedDateTime).format('DD/MM/YY')}</span>
          </p>
        );
      }
      if (location.pathname === basicReportsUrl && basicReportsLastRunDate) {
        return (
          <p>
            <span>{locale.Reports.sidebar.lastUpdatedAt}</span>
            <span>{moment(basicReportsLastRunDate).format('hh:mm')} on {moment(basicReportsLastRunDate).format('DD/MM/YY')}</span>
          </p>
        );
      }
      return null;
    }

    const renderCustomReportOption =() => {
      if (isSuperAdmin) {
        return (
          <li>
            <NavLink to={customReportsUrl}>
              <span>Custom</span>
            </NavLink>
          </li>
        );
      }
      return null;
    }

    return (
      <ReportsSidebar isExpand={true}>
        <ReportsSidebarMenu>
          <ul>
            {renderBasicReportMenuOption()}
            {renderSPReportMenuOption()}
            {renderCustomReportOption()}
          </ul>
          {renderLastUpdated()}
        </ReportsSidebarMenu>
        {/*
        <ReportsSidebarClose>
          <LinkIcon to="#close" size="larger" icon={isExpand ? <IconContract outline={false} /> : <IconExpand outline={false} />} accessibilitySpan="Close" onClick={(e) => { e.preventDefault(); this.setState({ isExpand: !isExpand }); }} />
        </ReportsSidebarClose>
        */}
      </ReportsSidebar>
    );
  }
}

export default connect(mapStateToProps) (Sidebar);
