import agent from 'service/agent';
import { setOrganisationHeader } from 'actions/router';
import { getToken } from 'helpers/common';
import * as ReportsConstants from 'constants/ReportsConstants';
import type { Organisation, Branch } from "types/organisation";
import type { ReportFilter } from "types/reports"

export const setReportsPending = (): Action => ({
  type: ReportsConstants.GET_REPORTS_PENDING,
});

export const setReportsSuccess = (): Action => ({
  type: ReportsConstants.GET_REPORTS_SUCCESS,
});

export const setReportsError = (): Action => ({
  type: ReportsConstants.GET_REPORTS_ERROR,
});

export const setReportsItems = (reportItems: any): Action => ({
  type: ReportsConstants.SET_REPORTS_ITEMS,
  payload: reportItems,
});

export const setDropdownOrganisation = (organisation: Organisation) => ({
  type: ReportsConstants.SET_REPORT_DROPDOWN_ORGANISATION,
  payload: organisation,
});

export const setDropdownBranch = (branch: Branch): Action => ({
  type: ReportsConstants.SET_REPORT_DROPDOWN_BRANCH,
  payload: branch
});

export const setDropdownService = (service: Service): Action => ({
  type: ReportsConstants.SET_REPORT_DROPDOWN_SERVICE,
  payload: service
})

export const setDropDownStatus = (status: any): Action => ({
  type: ReportsConstants.SET_REPORT_DROPDOWN_STATUS,
  payload: status
})

export const clearDropdownBranch = (): Action => ({
  type: ReportsConstants.CLEAR_REPORT_DROPDOWN_BRANCH,
});

export const clearDropdownService = (): Action => ({
  type: ReportsConstants.CLEAR_REPORT_DROPDOWN_SERVICE,
})

export const setReportFilter = (filter: ReportFilter): Action => ({
  type: ReportsConstants.SET_REPORT_FILTER,
  payload: filter,
});

export const resetReportFiltersOrganisation = () : Action => ({
  type: ReportsConstants.RESET_REPORT_FILTER_ORGANISATION
});

export const resetReportFiltersBranch = () : Action => ({
  type: ReportsConstants.RESET_REPORT_FILTER_BRANCH
});

export const resetReportFiltersRest = () : Action => ({
  type: ReportsConstants.RESET_REPORT_FILTER_REST
})

export const setReportsDownloadOverlay = (isOverlayVisible: boolean): Action => ({
  type: ReportsConstants.SET_REPORTS_DOWNLOAD_OVERLAY,
  payload: isOverlayVisible,
});

export const setReportsDownloadPending = (): Action => ({
  type: ReportsConstants.GET_REPORTS_DOWNLOAD_PENDING,
});

export const setReportsDownloadSuccess = (): Action => ({
  type: ReportsConstants.GET_REPORTS_DOWNLOAD_SUCCESS,
});

export const setReportsDownloadError = (): Action => ({
  type: ReportsConstants.GET_REPORTS_DOWNLOAD_ERROR,
});

export const setReportsDownloadItems = (reportItems: any): Action => ({
  type: ReportsConstants.SET_REPORTS_DOWNLOAD_ITEMS,
  payload: reportItems,
});

export const getReports = (organisationId?: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { filters } = currentState.reportsBasic;
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setReportsPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Reports.getReports(
        accessToken,
        filters
      )
      .then(reportItems => {
        dispatch(setReportsItems(reportItems));
        dispatch(setReportsSuccess());
      })
      .catch(err => {
        dispatch(setReportsError());
        console.log("getReports server error or branch was not found", err);
      });
    });
};

export const getReportsDownload = (organisationId?: string) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { filters } = currentState.reportsBasic;
  organisationId && dispatch(setOrganisationHeader(organisationId));
  dispatch(setReportsDownloadPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Reports.getReportsDownload(
        accessToken,
        filters
      )
      .then(reportItems => {
        dispatch(setReportsDownloadItems(reportItems));
        dispatch(setReportsDownloadSuccess());
      })
      .catch(err => {
        dispatch(setReportsDownloadError());
        console.log("getReportsDownload server error or branch was not found", err);
      });
    });
};

