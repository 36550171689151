import React, { Component, Fragment } from "react";
import Select from "react-select";
import values from "lodash.values";
import find from "lodash.find";

import locale from "service/locale";
import "./styles.scss";

const allStatusesOptions = values(locale.Appointment.status);

type Props = {
  isDisabled?: boolean,
  status: number,
  outcomeStatus: number,
  isPastAppointment: boolean,
  isTodayAppointment: boolean,
  handleAppointmentStatusChange: (value: any) => any,
  isPastAndEndTimeAppointment: boolean,
  bookingMethod: number,
};

class AppointmentStatus extends Component<Props> {

  render() {
    const {
      isDisabled,
      status,
      handleAppointmentStatusChange,
      isPastAppointment,
      isTodayAppointment,
      outcomeStatus,
      isPastAndEndTimeAppointment,
      bookingMethod
    } = this.props;
    const isSelect = isTodayAppointment || isPastAppointment;
    let currentStatusesOptions = allStatusesOptions;
    if (isTodayAppointment) {
      currentStatusesOptions = [
        locale.Appointment.status.provided,
        locale.Appointment.status.notProvided
      ];
    } else if (isPastAppointment) {
      currentStatusesOptions = [
        locale.Appointment.status.provided,
        locale.Appointment.status.notProvided,
        locale.Appointment.status.missed
      ];
    } else {
      currentStatusesOptions = [];
    }
    const selectedStatus = find(allStatusesOptions, {
      status: status,
      outcomeStatus: outcomeStatus
    }) || {
      label: "Select status",
      value: 0,
      color: "grey-light",
      status: 0,
      outcomeStatus: 0
    };
    return (
      <Fragment>
        {((isPastAppointment &&
          status === locale.Appointment.status.booked.status) ||
          selectedStatus.status === 0) && (
          <div className="patient-care-block">
            <p>
              {locale.Appointment.pastAppointmentStatusWarning[0]}
              <br />
              {bookingMethod === locale.Appointment.type.online.value && locale.Appointment.pastAppointmentStatusWarning[1]}
            </p>
          </div>
        )}
        <div className={
          `patient-care-row-status
          ${(isPastAndEndTimeAppointment && status === locale.Appointment.status.booked.status) ? locale.Appointment.pastStatus : selectedStatus.color}`
        }>
          <h3 className="patient-care-status-title">
            {locale.Appointment.appointmentStatusTitle}:
          </h3>
          {
            isSelect
              ? <Select
                id="appointment-status"
                name="appointment-status"
                options={currentStatusesOptions}
                onChange={handleAppointmentStatusChange}
                classNamePrefix="patient-care"
                className="patient-care-select"
                isDisabled={isDisabled}
                value={selectedStatus}
              />
              : <h3 className="patient-care-status">{selectedStatus.label}</h3>
          }
        </div>
        <hr className="patient-care-separator-small" />
      </Fragment>
    );
  }
}

export default AppointmentStatus;
