import type { Action } from "types/actions";
import * as CalendarViewConstants from "constants/CalendarViewConstants";
import locale from 'service/locale';
import { addFilterToCookie } from "helpers/formatData";

export const setEvents = (events: any[]): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_EVENTS,
  payload: events
});

export const setDate = (date: Date): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_DATE,
  payload: date
});

export const setPeriod = (period: string): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_PERIOD,
  payload: period
});

export const setTimeInterval = (startTime: number, endTime: number): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_TIME_INTERVAL,
  payload: { startTime, endTime }
});

export const setResources = (resources: any[] | null): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_RESOURCES,
  payload: resources
});

export const setBranchResources = (resources: any[] | null): Action => ({
  type: CalendarViewConstants.SET_BRANCH_RESOURCES,
  payload: resources
});

export const setBranchName = (branchName: string): Action => ({
  type: CalendarViewConstants.SET_BRANCH_NAME,
  payload: branchName
});

export const setBranchId = (branchId: string): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_BRANCH_ID,
  payload: branchId
});

export const setOrganisationId = (organisationId: string): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_ORGANISATION_ID,
  payload: organisationId
});

export const setCalendarFilters = (calendarFilters: string[]): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_FILTERS,
  payload: calendarFilters
});

export const setServiceFilters = (serviceFilters: string[]): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_SERVICE_FILTERS,
  payload: serviceFilters
});

export const setStatusFilters = (statusFilters: Object): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_APPOINTMENT_STATUS_FILTERS,
  payload: statusFilters
});

export const setCancellationStatus = (status: boolean): Action => ({
  type: CalendarViewConstants.SET_CALENDAR_CANCELLATION_VIEW,
  payload: status
});

export const filterEventsByCalendars = (calendar: Object): Function => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const email = currentState.profile.email;
  const newEventsList = [...currentState.calendarView.events];
  let newResourceMap = [...currentState.calendarView.resourceMap];
  let newCalendarsFilters = [...currentState.calendarView.calendarFilters];
  const existingCalendarIndex = newCalendarsFilters.findIndex(
    newCalendar => newCalendar === calendar.resourceId
  );
  const existingResourceIndex = newResourceMap.findIndex(
    resource => resource.resourceId === calendar.resourceId
  );
  if (existingResourceIndex === -1) {
    newResourceMap.push({
      resourceId: calendar.resourceId,
      resourceTitle: calendar.resourceTitle,
      resourceColor: calendar.resourceColor,
      resourceOpenTimes: calendar.resourceOpenTimes,
      resourceBlockedPeriods: calendar.resourceBlockedPeriods
    });
  } else {
    newResourceMap = [
      ...newResourceMap.slice(0, existingResourceIndex),
      ...newResourceMap.slice(existingResourceIndex + 1)
    ];
  }
  if (existingCalendarIndex === -1) {
    newCalendarsFilters.push(calendar.resourceId);
  } else {
    newCalendarsFilters = [
      ...newCalendarsFilters.slice(0, existingCalendarIndex),
      ...newCalendarsFilters.slice(existingCalendarIndex + 1)
    ];
  }
  dispatch(setCalendarFilters(newCalendarsFilters));
  addFilterToCookie(email, { param: "calendarFilters", value: newCalendarsFilters });
  dispatch(setResources(newResourceMap));
  newEventsList.forEach(event => {
    event.isHiddenCalendar =
      newCalendarsFilters.findIndex(
        calendar => calendar === event.calendar.id
      ) !== -1;
  });
  dispatch(setEvents(newEventsList));
};

export const filterEventsByServices = (serviceId: string): Function => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const email = currentState.profile.email;
  const newEventsList = [...currentState.calendarView.events];
  let newServiceFilters = [...currentState.calendarView.serviceFilters];
  const existingServiceIndex = newServiceFilters.findIndex(
    calendar => calendar === serviceId
  );
  if (existingServiceIndex === -1) {
    newServiceFilters.push(serviceId);
  } else {
    newServiceFilters = [
      ...newServiceFilters.slice(0, existingServiceIndex),
      ...newServiceFilters.slice(existingServiceIndex + 1)
    ];
  }
  dispatch(setServiceFilters(newServiceFilters));
  addFilterToCookie(email, { param: "serviceFilters", value: newServiceFilters });
  newEventsList.forEach(event => {
    event.isHiddenService =
      newServiceFilters.findIndex(
        service => service === event.service.id
      ) !== -1;
  });
  dispatch(setEvents(newEventsList));
};

export const filterEventsByStatuses = (statusFilter: Object): Function => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const newEventsList = [...currentState.calendarView.events];
  dispatch(setStatusFilters(statusFilter));
  newEventsList.forEach(event => {
    if (statusFilter && statusFilter.selectedAppointments && statusFilter.selectedAppointments !== null && statusFilter.selectedAppointments.includes(event.appointmentId)) {
      event.isHiddenStatus = true;
    } else {
      event.isHiddenStatus = false
    }
  });
  dispatch(setEvents(newEventsList));
};

export const filterCancellations = (status: boolean): Function => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const email = currentState.profile.email;
  const newEventsList = [...currentState.calendarView.events];
  newEventsList.forEach(event => {
    if (event.status === locale.Appointment.status.cancelled.status) {
      event.isHiddenCancellation = !status;
    }
  });
  dispatch(setEvents(newEventsList));
  dispatch(setCancellationStatus(status));
  addFilterToCookie(email, { param: "showCancellation", value: status });
};

export const showSelectedSlot = (zIndex: number | null): Action => ({
  type: CalendarViewConstants.SHOW_SELECTED_SLOT,
  payload: zIndex
});
