import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import CalendarFilters from "./CalendarFilters/CalendarFilters";
import CalendarView from "./CalendarView/CalendarView";
import * as RolesConstants from "constants/RolesConstants";
import { DEFAULT_BRANCHID } from "constants/BranchesConstants";
import { getBranchCalendarsList } from "actions/branchDetails";
import { ORGANISATION_TYPE } from 'constants/OrganisationsConstants';

type Props = {
  organisationId: string,
  branchId: string,
  currentRole: any,
  profile: any,
  getBranchCalendarsList: (organisationId: string, branchId: string, includeCustom: boolean) => Function,
};

const mapStateToProps = state => ({
  branchId: state.router.branchId,
  organisationId: state.router.organisationId,
  currentRole: state.roles.profileCurrentRole,
  profile: state.profile
});

const mapDispatchToProps = dispatch => ({
  getBranchCalendarsList: (organisationId, branchId, includeCustom) => dispatch(getBranchCalendarsList(organisationId, branchId, includeCustom))
});

class CalendarPage extends Component<Props> {

  componentDidMount = () => {
    const { organisationId, branchId, getBranchCalendarsList } = this.props;
    const includeCustom = true;
    getBranchCalendarsList(organisationId, branchId, includeCustom);
  };

  render() {
    const { currentRole, profile, branchId } = this.props;
    const isRedirect = ((!branchId || branchId === DEFAULT_BRANCHID) &&
      (currentRole.role === RolesConstants.ADMIN || currentRole.role === RolesConstants.ORGANIZATION_ADMIN)) ||
      currentRole.organisationType === ORGANISATION_TYPE.AFFILIATE;
    return isRedirect ? (
      <Redirect to={profile.adminUrl} />
    ) : (
      <React.Fragment>
        <CalendarFilters />
        <CalendarView />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
