import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import moment from "moment";

import { getParamsString } from "helpers/common";
import { logoutUser } from "actions/login";

import type { Filter } from 'types/filter';
import * as BranchesConstants from "constants/BranchesConstants";
import * as OrganisationsConstants from "constants/OrganisationsConstants";
import { formatDate } from "helpers/formatData";
import locale from "service/locale";
import { Store } from './../store/index';

const superagent = superagentPromise(_superagent, Promise);

/**
 * The window.__ENV variable you could find here `/public/env-*.js`
 * TODO: remove the hardcoded URL below
 * TODO: handle the case when no API url is provided
 */

const apiUrlObj = (window.__ENV && window.__ENV.apiUrl) || {};
let {
  appointmentService,
  organizationService,
  usersService,
  calendarService,
  authService,
  registerService,
  reportsService,
  taskManagerService
} = apiUrlObj;

appointmentService = appointmentService || "http://patientcare--dev.northeurope.cloudapp.azure.com:8211/api/";
organizationService = organizationService || "http://patientcare--dev.northeurope.cloudapp.azure.com:8210/api/";
usersService = usersService || "http://patientcare--dev.northeurope.cloudapp.azure.com:8213/api/";
calendarService = calendarService || "http://patientcare--dev.northeurope.cloudapp.azure.com:8216/api/";
authService = authService || "http://patientcare--dev.northeurope.cloudapp.azure.com:8213/";
registerService = registerService || "https://pc-dev-api.patientplatform.io/staging/landingpage/api/";
reportsService = reportsService || "https://pc-dev-api.patientplatform.io/alpha/reporting/api/";

const handleErrors = err => {
  if (err && err.status >= 500) {
    Store.dispatch(logoutUser({ hasError: true }));
  }
};

/**
 * Need to provide API calls with extra headers
 * Here they are: X-BranchId, X-Role, X-OrganisationId
 * There are a few cases:
 *
 * [x] when user is super admin
 *  [x] if he just logged in
 *    [x] use data returned from API or saved in localstorage
 *  [x] if he switches a branch/org using dropdowns
 *    [x] replace branch&org ids taken from current role with selected ones
 *
 * [x] when user is org admin
 *  [x] if he just logged in
 *    [x] use data returned from API or saved in localstorage
 *  [x] if he switches a branch
 *    [x] replace branch id taken from current role with selected one
 *
 * [x] when user is branch admin/member
 *  [x] if he just logged in
 *    [x] use data returned from API or saved in localstorage
 *
 */
const getCommonHeaders = (isAgendaRequest?: boolean) => {
  const state = Store.getState();
  let headers = {};

  // extract ids & role from current role object
  let { roles: { profileCurrentRole: { organizationId, branchId, role } } } = state;

  // if user is org/super admin and changed branch
  const router = state.router;
  const calendarView = state.calendarView;
  const sessionId = sessionStorage.getItem('x-sessionId');

  if (
    router.branchId
    && router.branchId !== BranchesConstants.DEFAULT_BRANCHID
    && router.branchId.length > 0
  ) {
    branchId = isAgendaRequest ? calendarView.branchId : router.branchId;
  }

  if (
    router.organisationId
    && router.organisationId !== OrganisationsConstants.DEFAULT_ORGANISATIONID
    && router.organisationId.length > 0
  ) {
    organizationId = isAgendaRequest ? calendarView.organisationId : router.organisationId;
  }

  if (role) headers["X-Role"] = role;
  if (branchId) headers["X-BranchId"] = branchId;
  if (organizationId) headers["X-OrganisationId"] = organizationId;
  if (sessionId) headers["X-SessionId"] = sessionId;

  return headers;
};

const responseBody = res => res.body;

const requests = {
  post: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .set("X-Content-Type-Options", "nosniff")
      .set("X-XSS-Protection", "1")
      .set("X-Frame-Options", "sameorigin")
      .set("Referrer-Policy", "no-referrer")
      .on("error", handleErrors)
      .then(responseBody),
  postWithRecaptcha: (url, body, recaptchaToken) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .set("CaptchaResponse", recaptchaToken)
      .set("X-Content-Type-Options", "nosniff")
      .set("X-XSS-Protection", "1")
      .set("X-Frame-Options", "sameorigin")
      .set("Referrer-Policy", "no-referrer")
      .on("error", handleErrors)
      .then(responseBody),
  postData: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Content-Type-Options", "nosniff")
      .set("X-XSS-Protection", "1")
      .set("X-Frame-Options", "sameorigin")
      .set("Referrer-Policy", "no-referrer")
      .on("error", handleErrors)
      .then(responseBody),
  postDataForRecaptcha: (url, body, recaptchaToken) =>
    superagent
      .post(url, body)
      .set("CaptchaResponse", recaptchaToken)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Content-Type-Options", "nosniff")
      .set("X-XSS-Protection", "1")
      .set("X-Frame-Options", "sameorigin")
      .set("Referrer-Policy", "no-referrer")
      .on("error", handleErrors)
      .then(responseBody),
  getWithToken: (url, token, isAgendaRequest?) =>
    superagent
      .get(url)
      .set(getCommonHeaders(isAgendaRequest))
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .set("X-Content-Type-Options", "nosniff")
      .set("X-XSS-Protection", "1")
      .set("X-Frame-Options", "sameorigin")
      .set("Referrer-Policy", "no-referrer")
      .on("error", handleErrors)
      .then(responseBody),
  postWithToken: (url, body, token, isAgendaRequest?) =>
    superagent
      .post(url, body)
      .set(getCommonHeaders(isAgendaRequest))
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .set("X-Content-Type-Options", "nosniff")
      .set("X-XSS-Protection", "1")
      .set("X-Frame-Options", "sameorigin")
      .set("Referrer-Policy", "no-referrer")
      .on("error", handleErrors)
      .then(responseBody),
  deleteWithToken: (url, token) =>
    superagent
      .del(url, token)
      .set(getCommonHeaders())
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .set("X-Content-Type-Options", "nosniff")
      .set("X-XSS-Protection", "1")
      .set("X-Frame-Options", "sameorigin")
      .set("Referrer-Policy", "no-referrer")
      .on("error", handleErrors)
      .then(responseBody),
  putWithToken: (url, body, token, isAgendaRequest?) =>
    superagent
      .put(url, body, token)
      .set(getCommonHeaders(isAgendaRequest))
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .set("X-Content-Type-Options", "nosniff")
      .set("X-XSS-Protection", "1")
      .set("X-Frame-Options", "sameorigin")
      .set("Referrer-Policy", "no-referrer")
      .on("error", handleErrors)
      .then(responseBody)
};

const Register = {
  sendRegister: (data: any, recaptchaToken: string) => {
    return requests.postWithRecaptcha(`${registerService}v1/landingpage`, data, recaptchaToken);
  },
  sendRegisterInterest: (data: any, access_token: string) => {
    return requests.postWithToken(`${registerService}v1/landingpage/SmartPharmacyInterest`, data, access_token);
  },
};

const Auth = {
  getCurrentUser: (access_token: string) => {
    return requests.getWithToken(`${usersService}v1/my`, access_token);
  },
  login: (email: string, password: string, recaptchaToken: string) => {
    const formData =
      "client_id=WebSPA&client_secret=" +
      encodeURIComponent("<secret_value>") +
      "&grant_type=password&username=" +
      encodeURIComponent(email) +
      "&password=" +
      encodeURIComponent(password);
    // TODO: remove `usersService.replace...`
    return requests.postDataForRecaptcha(
      `${authService}connect/token`,
      formData,
      recaptchaToken
    );
  },

  getCurrentUserRoles: (access_token: string) => {
    return requests.getWithToken(
      `${usersService}v1/users/roles/assignable`,
      access_token
    );
  },

  sendResetPasswordEmail: (email: string, recaptchaToken: string) => {
    return requests.postWithRecaptcha(`${usersService}v1/my/forgotpassword`, { email }, recaptchaToken);
  },

  sendResetPasswordEmailFromAdmin: (email: string, access_token: string) => {
    return requests.postWithToken(`${usersService}v1/adb2c/sendpasswordresetemail`, { email }, access_token);
  },

  confirmNewPassword: (data: any) => {
    const { token, userId, newPassword, confirmNewPassword } = data;
    return requests.post(`${usersService}v1/my/forgotpassword/confirm`, {
      token,
      userId,
      newPassword,
      confirmNewPassword
    });
  },
  checkExpiredLink: (data: any) => {
    const { token, userId } = data;
    return requests.post(`${usersService}v1/my/forgotpassword/verify`, {
      token,
      userId
    });
  },
  changePassword: (data: any, access_token: string) => {
    const { password, newPassword, confirmNewPassword } = data;
    return requests.putWithToken(
      `${usersService}v1/adb2c/password`,
      { password, newPassword, confirmNewPassword },
      access_token
    );
  },
  refreshToken: (refresh_token: string) => {
    const formData =
      "grant_type=refresh_token" +
      "&refresh_token=" +
      encodeURIComponent(refresh_token) +
      "&client_id=WebSPA&client_secret=" +
      encodeURIComponent("<secret_value>");
    return requests.postData(
      `${authService}connect/token`,
      formData
    );
  },
  logoutUser: (type: string, token: string) => {
    const formData =
      "client_id=WebSPA" +
      "&token=" +
      encodeURIComponent(token) +
      "&token_type_hint=" +
      type;
    return requests.postData(
      `${authService}connect/revocation`,
      formData,
    );
  }
};

const OrganisationServices = {
  getServicesSummary: (access_token: string, organisationId: string) => {
    return requests.getWithToken(
      `${organizationService}v1/organisations/${organisationId}/summary`,
      access_token
    );
  },
  getServicesList: (access_token: string, organisationId: string) => {
    return requests.getWithToken(
      `${organizationService}v1/organisations/${organisationId}/organisation-service-list`,
      access_token
    );
  },
  getBranchesSummary: (access_token: string, organisationId: string, serviceId: string) => {
    return requests.getWithToken(
      `${organizationService}v1/organisations/${organisationId}/service/${serviceId}/branchsummary`,
      access_token
    );
  },
  getBranchesList: (access_token: string, organisationId: string, serviceId: string) => {
    return requests.getWithToken(
      `${organizationService}v1/organisations/${organisationId}/service/${serviceId}/branches`,
      access_token
    );
  },
  addServicesDetails: (access_token: string, organisationId: string, serviceDetails: any) => {
    return requests.putWithToken(
      `${organizationService}v1/organisations/${organisationId}/service-list`,
      serviceDetails,
      access_token
    );
  },
  deleteServices: (access_token: string, organisationId: string, serviceDetails: any) => {
    return requests.putWithToken(
      `${organizationService}v1/organisations/${organisationId}/service-list-delete`,
      serviceDetails,
      access_token
    );
  },
  updateServicesDetails: (access_token: string, organisationId: string, serviceDetails: any) => {
    return requests.putWithToken(
      `${organizationService}v1/organisations/${organisationId}/service-list-update`,
      serviceDetails,
      access_token
    );
  },
  addTaskServicesDetails: (access_token: string, serviceDetails: any) => {
    return requests.postWithToken(
      `${taskManagerService}v1/Orchestrator`,
      serviceDetails,
      access_token
    );
  },
  editTaskServicesDetails: (access_token: string, serviceDetails: any) => {
    return requests.postWithToken(
      `${taskManagerService}v1/Orchestrator`,
      serviceDetails,
      access_token
    );
  },
  removeTaskServicesDetails: (access_token: string, serviceDetails: any) => {
    return requests.postWithToken(
      `${taskManagerService}v1/Orchestrator`,
      serviceDetails,
      access_token
    );
  },
};

const Reports = {
  getReports: (access_token: string, filter?: ReportFilter) => {
    const { organisationIds, branchIds, serviceIds, statuses, startDate, endDate } = filter;
    return requests.postWithToken(
      `${reportsService}v1/report/basic`,
      {
        organisationIds: organisationIds,
        branchIds: branchIds,
        serviceIds: serviceIds,
        appointmentStatus: statuses,
        startDate,
        endDate
      },
      access_token
    );
  },
  getReportsDownload: (access_token: string, filter?: ReportFilter) => {
    const { organisationIds, branchIds, serviceIds, statuses, startDate, endDate } = filter;
    return requests.postWithToken(
      `${reportsService}v1/report/export`,
      {
        organisationIds: organisationIds,
        branchIds: branchIds,
        serviceIds: serviceIds,
        appointmentStatus: statuses,
        startDate,
        endDate
      },
      access_token
    );
  }
};

const ReportsSmartPharmacy = {
  getSmartPharmacySummaryReport: (access_token: string, filter?: ReportSmartPharmacyFilter) => {
    const { organisationId, branchId, careProviderId, startDate, endDate } = filter;
    return requests.postWithToken(
      `${reportsService}v1/report/smartpharmacy/report/summary`,
      {
        organisationId: organisationId,
        branchId: branchId,
        careProviderId: careProviderId,
        startDate,
        endDate
      },
      access_token
    );
  },
  getSmartPharmacyNominationReport: (access_token: string, filter?: ReportSmartPharmacyFilter) => {
    const { organisationId, branchId, careProviderId, startDate, endDate } = filter;
    return requests.postWithToken(
      `${reportsService}v1/report/smartpharmacy/report/nomination`,
      {
        organisationId: organisationId,
        branchId: branchId,
        careProviderId: careProviderId,
        startDate,
        endDate
      },
      access_token
    );
  },
  getSmartPharmacyMedicationReport: (access_token: string, filter?: ReportSmartPharmacyFilter) => {
    const { organisationId, branchId, careProviderId, startDate, endDate } = filter;
    return requests.postWithToken(
      `${reportsService}v1/report/smartpharmacy/report/medication`,
      {
        organisationId: organisationId,
        branchId: branchId,
        careProviderId: careProviderId,
        startDate,
        endDate
      },
      access_token
    );
  },
  getSmartPharmacyPrescriptionReport: (access_token: string, filter?: ReportSmartPharmacyFilter) => {
    const { organisationId, branchId, careProviderId, startDate, endDate } = filter;
    return requests.postWithToken(
      `${reportsService}v1/report/smartpharmacy/report/prescriptions`,
      {
        organisationId: organisationId,
        branchId: branchId,
        careProviderId: careProviderId,
        startDate,
        endDate
      },
      access_token
    );
  },

  getSmartPharmcyReportsDownload: (access_token: string, filter?: ReportFilter) => {
    const { organisationId, branchId, careProviderId, startDate, endDate } = filter;
    return requests.postWithToken(
      `${reportsService}v1/report/smartpharmacy/export`,
      {
        organisationId: organisationId,
        branchId: branchId,
        careProviderId: careProviderId,
        startDate,
        endDate
      },
      access_token
    );
  }
};

const ReportsCustom = {
  getOrganisationsReports: (access_token: string) => {
    return requests.getWithToken(
      `${reportsService}v1/adhocreports/organisations`,
      access_token
    );
  },
  getServicesReports: (services: any[], access_token: string) => {
    return requests.postWithToken(
      `${reportsService}v1/adhocreports/Services`,
      {
        ServiceRequest: services
      },
      access_token
    );
  },
}

const Appointments = {
  getAppointmentDetails: (appointmentId: string, access_token: string) => {
    return requests.getWithToken(
      `${appointmentService}v1/appointments/${appointmentId}`,
      access_token,
      true
    );
  },
  getInternalEvent: (calendarId: string, eventId: string, access_token: string) => {
    return requests.getWithToken(
      `${appointmentService}v1/appointments/${calendarId}/internal-event/${eventId}`,
      access_token
    );
  },
  getAppointments: (params: any = {}, access_token: string) => {
    return requests.postWithToken(
      `${appointmentService}v1/appointments${getParamsString(params)}`,
      {},
      access_token
    );
  },
  getFilteredAppointments: (params: any = {}, access_token: string) => {
    return requests.getWithToken(
      `${appointmentService}v1/appointments${getParamsString(params)}`,
      access_token
    );
  },
  calculateAppointmentRefund: (appointmentId: string, fullRefund: boolean, access_token: string) => {
    return requests.getWithToken(
      `${appointmentService}v1/appointments/${appointmentId}/calculate-refund?full=${fullRefund}`,
      access_token
    );
  },
  cancelAppointment: (reason: string, appointmentId: string, behalfOfPatient: boolean, access_token: string) => {
    return requests.putWithToken(
      `${appointmentService}v1/appointments/${appointmentId}/cancel`,
      { reason, behalfOfPatient },
      access_token,
      true
    );
  },
  deleteInternalEvent: (calendarId: string, eventId: string, access_token: string) => {
    return requests.deleteWithToken(
      `${appointmentService}v1/appointments/${calendarId}/internal-event/${eventId}`,
      access_token
    );
  },
  completeAppointment: (
    completeAppointmentData: { outcome: string, isPaid: boolean },
    appointmentId: string,
    access_token: string
  ) => {
    return requests.putWithToken(
      `${appointmentService}v1/appointments/${appointmentId}/complete`,
      completeAppointmentData,
      access_token,
      true
    );
  },
  refundCustomer: (
    refundCustomerData: { full: boolean, reason: string },
    appointmentId: string,
    access_token: string
  ) => {
    return requests.putWithToken(
      `${appointmentService}v1/appointments/${appointmentId}/customer-service-refund`,
      refundCustomerData,
      access_token,
      true
    );
  },
  getAppointmentsForCalendar: (
    branchId: string,
    startDate: string,
    endDate: string,
    access_token: string
  ) => {
    return requests.postWithToken(
      `${appointmentService}v1/appointments/search`,
      {
        branchId,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      },
      access_token
    );
  },
  getAllItemsForCalendar: (
    branchId: string,
    startDate: string,
    endDate: string,
    access_token: string
  ) => {
    return requests.postWithToken(
      `${appointmentService}v1/appointments/searchAllItems`,
      {
        branchId,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      },
      access_token
    );
  },
  getAppointmentsForAgenda: (
    branchId: string,
    startDate: string,
    access_token: string
  ) => {
    return requests.postWithToken(
      `${appointmentService}v1/appointments/search`,
      {
        branchId,
        startDate: formatDate(startDate),
        status: "incoming"
      },
      access_token,
      true
    );
  },
  getFutureAppointments: (
    branchId: string,
    startDate: string,
    access_token: string
  ) => {
    return requests.postWithToken(
      `${appointmentService}v1/appointments/search`,
      {
        branchId,
        startDate,
      },
      access_token
    );
  },
  getSlots: (slotData: Object, access_token: string) => {
    const { branchId, serviceId, start, end, reassignedAppointmentId, duration } = slotData;
    return requests.postWithToken(
      `${appointmentService}v1/slots/available`,
      {
        branchId,
        serviceId,
        start: start ? formatDate(start) : null,
        end: start ? formatDate(end) : null,
        reassignedAppointmentId,
        duration
      },
      access_token,
      true
    );
  },
  holdSlot: (appointmentData: Object, access_token: string) => {
    return requests.postWithToken(
      `${appointmentService}v1/slots/hold`,
      appointmentData,
      access_token
    );
  },
  releaseSlot: (appointmentId: string, access_token: string) => {
    return requests.deleteWithToken(
      `${appointmentService}v1/slots/${appointmentId}`,
      access_token
    );
  },
  bookAppointment: (
    bookingData: Object,
    appointmentId: string,
    access_token: string
  ) => {
    return requests.putWithToken(
      `${appointmentService}v1/appointments/${appointmentId}/book`,
      bookingData,
      access_token
    );
  },
  createInternalEvent: (
    eventData: Object,
    appointmentId: string,
    access_token: string
  ) => {
    return requests.putWithToken(
      `${appointmentService}v1/appointments/${appointmentId}/internal-event`,
      eventData,
      access_token
    );
  },
  updateAppointment: (appointmentId: string, data: any, access_token: string) => {
    return requests.putWithToken(
      `${appointmentService}v1/appointments/${appointmentId}/update`,
      data,
      access_token,
      true
    );
  }
};

const Calendars = {
  getCalendarSearch: (access_token: string, organisationId?: string) => {
    return requests.postWithToken(
      `${calendarService}v1/calendars/search`,
      {
        query: "",
        organisationId,
        branchIds: [],
        serviceIds: [],
        paging: {
          itemsPerPage: 30,
          pageNumber: 1
        },
        order: "asc"
      },
      access_token
    );
  },
  getFilteredCalendarSearch: (access_token: string, query: string, branchIds: Object, serviceIds: Object, pageNumber: number, organisationId?: string) => {
    return requests.postWithToken(
      `${calendarService}v1/calendars/search`,
      {
        query,
        organisationId,
        branchIds,
        serviceIds,
        paging: {
          itemsPerPage: 30,
          pageNumber
        },
        order: "asc"
      },
      access_token
    );
  },
  deleteCalendar: (calendarId: string, access_token: string) => {
    return requests.deleteWithToken(
      `${calendarService}v1/calendars/${calendarId}`,
      access_token
    );
  },
  createCalendar: (calendarData: Object, access_token: string) => {
    return requests.postWithToken(
      `${calendarService}v1/calendars`,
      calendarData,
      access_token
    );
  },
  updateCalendar: (calendarData: Object, access_token: string) => {
    return requests.putWithToken(
      `${calendarService}v1/calendars/${calendarData.id}`,
      calendarData,
      access_token
    );
  },
  getBranchCalendars: (branchId: string, access_token: string) => {
    return requests.postWithToken(
      `${calendarService}v1/calendars/list/${branchId}`,
      {
        paging: {
          itemsPerPage: 30,
          pageNumber: 1
        }
      },
      access_token
    );
  },
  getCalendar: (calendarId: string, access_token: string) => {
    return requests.getWithToken(
      `${calendarService}v1/calendars/${calendarId}`,
      access_token
    );
  },
  getCalendarWithParameter: (calendarId: string, access_token: string, parameter: string) => {
    return requests.getWithToken(
      `${calendarService}v1/calendars/${calendarId}${parameter}`,
      access_token
    );
  },
  createInternalEvent: (eventData: Object, access_token: string) => {
    const { calendarId } = eventData
    return requests.postWithToken(
      `${calendarService}v1/calendars/${calendarId}/internal-event`,
      eventData,
      access_token
    );
  },
  deleteInternalEvent: (calendarId: string, eventId: string, access_token: string) => {
    return requests.deleteWithToken(
      `${calendarService}v1/calendars/${calendarId}/internal-event/${eventId}`,
      access_token
    );
  },
  getInternalEvent: (calendarId: string, eventId: string, access_token: string) => {
    return requests.getWithToken(
      `${calendarService}v1/calendars/${calendarId}/internal-event/${eventId}`,
      access_token
    );
  },
  getAppointmentsForCalendar: (calendarId: string, startDate: string, access_token: string) => {
    return requests.postWithToken(`${appointmentService}v1/appointments/search`,
      {
        calendarId,
        startDate: formatDate(startDate)
      },
      access_token);
  },
  getBranchAvailability: (branchId: string, access_token: string) => {
    return requests.getWithToken(`${calendarService}v1/availability/${branchId}`, access_token);
  },
  updateBranchAvailability: (data: Object, branchId: string, access_token: string) => {
    const { openHours, closedDays, bookingCutoff, timeOffModel } = data;
    return requests.putWithToken(`${calendarService}v1/availability/${branchId}`, {
      openHoursModel: {
        days: openHours,
      },
      closedDaysModel: {
        days: closedDays,
      },
      cutOffModel: bookingCutoff ? bookingCutoff : null,
      timeOffModel: timeOffModel
    }, access_token);
  },
};

const Rooms = {
  getRoomsList: (branchId: string, access_token: string) => {
    return requests.postWithToken(`${organizationService}v1/rooms/search`,
      {
        branchId,
        paging: {
          itemsPerPage: 30,
          pageNumber: 1
        }
      },
      access_token);
  },
  createRoom: (room: Object, access_token: string) => {
    return requests.postWithToken(`${organizationService}v1/rooms`, room, access_token);
  },
  updateRoom: (branchId: string, room: Object, access_token: string) => {
    const obj = {
      branchId,
      name: room.name
    };
    return requests.putWithToken(`${organizationService}v1/rooms/${room.id}`, obj, access_token);
  },
  deleteRoom: (roomId: string, access_token: string) => {
    return requests.deleteWithToken(`${organizationService}v1/rooms/${roomId}`, access_token)
  },
  getFutureAppointmentsForRoom: (roomId: string, startDate: string, access_token: string) => {
    const { status } = locale.Appointment.status.booked;
    return requests.postWithToken(`${appointmentService}v1/appointments/search`,
    {
      roomId,
      status,
      startDate: formatDate(startDate)
    },
    access_token);
  }
};

const Organisations = {
  getOrganisations: (access_token: string) => {
    return requests.postWithToken(
      `${organizationService}v1/organisations/search`,
      {
        query: "",
        paging: {
          itemsPerPage: 30,
          pageNumber: 1
        }
      },
      access_token
    );
  },
  getFilteredOrganisations: (
    pageNumber: number,
    searchValue: string,
    itemsPerPage: number,
    access_token: string,
    mode?: number,
    organisationId?: string
  ) => {
    return requests.postWithToken(
      `${organizationService}v1/organisations/search`,
      {
        query: searchValue,
        searchMode: mode,
        organisationId,
        paging: {
          itemsPerPage,
          pageNumber
        }
      },
      access_token
    );
  },
  getOrganisationServices: (access_token: string, organisationId: string,) => {
    return requests.getWithToken(
      `${organizationService}v1/organisations/${organisationId}/services`,
      access_token
    );
  },
  getFilteredDefaultServices: (
    pageNumber: number,
    searchValue: string,
    itemsPerPage: number,
    access_token: string,
  ) => {
    return requests.postWithToken(
      `${organizationService}v1/admin/services/search`,
      {
        query: searchValue,
        paging: {
          itemsPerPage,
          pageNumber
        }
      },
      access_token
    );
  },
  updateDefaultServicesList: (
    services: any[],
    access_token: string
  ) => {
    return requests.putWithToken(
      `${organizationService}v1/admin/services`,
      { services },
      access_token
    );
  },
  getEnabledServiceDetails: (organisationId: string, access_token: string) => {
    return requests.getWithToken(
      `${organizationService}v1/admin/${organisationId}/restricted-services`,
      access_token
    )
  },
  updateEnabledServicesList: (organisationId: string, services: any[], access_token: string) => {
    return requests.putWithToken(
      `${organizationService}v1/admin/${organisationId}/restricted-services`,
      {
        services
      },
      access_token
    )
  },
  getFilteredOrganisationsCH: (searchValue: string, access_token: string) => {
    return requests.postWithToken(
      `${organizationService}v1/organisations/searchch`,
      {
        companyName: searchValue,
        paging: {
          itemsPerPage: 100,
          pageNumber: 1
        }
      },
      access_token
    );
  },
  getOrganisationDetails: (organisationId: string, access_token: string) => {
    return requests.getWithToken(
      `${organizationService}v2/organisations/${organisationId}`,
      access_token
    );
  },
  updateOrganisationDetails: (organisationId: string, organisationData: Object, access_token: string) => {
    return requests.putWithToken(
      `${organizationService}v1/organisations/${organisationId}/details`,
      organisationData,
      access_token
    );
  },
  createOrganisation: (organisation: any, access_token: string) => {
    const { companyNumber, organisationType, twoFactorValue, booking, address, name } = organisation;
    return requests.postWithToken(
      `${organizationService}v2/organisations/create`,
      {
        companyHouseNumber: companyNumber,
        organisationType: organisationType,
        paymentMethod: 1,
        booking: booking,
        address: address,
        name: name,
        requiresMFA: twoFactorValue
      },
      access_token
    );
  },
  deleteOrganisation: (organisationId: string, access_token: string) => {
    return requests.deleteWithToken(
      `${organizationService}v1/organisations/${organisationId}`,
      access_token
    );
  },
  updatePayoutSettings: (organisationId: string, settings: any, access_token: string) => {
    const { paymentMethod } = settings;
    return requests.putWithToken(
      `${organizationService}v2/organisations/${organisationId}/settings`,
      {
        paymentMethod
      },
      access_token
    )
  },
  updateOrganisation: (organisationId: string, settings: any, access_token: string) => {
    const { booking, tosAcceptance } = settings;
    return requests.putWithToken(
      `${organizationService}v2/organisations/${organisationId}`,
      {
        booking,
        tosAcceptance
      },
      access_token
    )
  },
  updateOrganisationMFASetting: (organisationId: string, settings: any, access_token: string) => {
    const {requiresMFA} = settings;
    return requests.putWithToken(
      `${organizationService}v2/organisations/${organisationId}/mfa`,
      {
        requiresMFA: requiresMFA.value
      },
      access_token
    )

  },
  getAvailabilityRules: (organisationId: string, access_token: string) => {
    return requests.getWithToken(
      `${organizationService}v1/organisations/${organisationId}/availability-rules`,
      access_token
    )
  },
  putAvailabilityRules: (organisationId: string, timeOff: any[], access_token: string) => {
    return requests.putWithToken(
      `${organizationService}v1/organisations/${organisationId}/availability-rules`,
      {
        timeOff
      },
      access_token
    )
  },
  putSupportedFeatures: (organisationId: string, supportedFeatures: any, IsEnabledForAllBranches: boolean, access_token: string) => {
    return requests.putWithToken(
      `${organizationService}v1/organisations/${organisationId}/supported-features`,
      {
        supportedFeatures,
        IsEnabledForAllBranches,
      },
      access_token
    )
  },
  getStorageOptions: (access_token) => {
    return requests.getWithToken(`${organizationService}v1/organisations/smartpharmacy/storageoptions`, access_token);
  },
};

const Branches = {
  getBranches: (access_token: string, organisationId?: string) => {
    return requests.postWithToken(
      `${organizationService}v1/branches/search`,
      {
        query: "",
        organisationId,
        paging: {
          itemsPerPage: 30,
          pageNumber: 1
        }
      },
      access_token
    );
  },
  getFilteredBranches: (
    pageNumber: number,
    searchValue: string,
    itemsPerPage: number,
    access_token: string,
    organisationId?: string
  ) => {
    return requests.postWithToken(
      `${organizationService}v1/branches/search`,
      {
        query: searchValue,
        organisationId,
        paging: {
          itemsPerPage,
          pageNumber
        }
      },
      access_token
    );
  },
  getFilteredServices:(
    pageNumber: number,
    searchValue: string,
    itemsPerPage: number,
    access_token: string,
    organisationId?: string,
    branchId?: string
  )=> {
    return requests.postWithToken(
      `${organizationService}v1/branches/services/search`,
      {
        query: searchValue,
        organisationId,
        branchId,
        paging: {
          itemsPerPage,
          pageNumber
        }
      },
      access_token
    );
  },
  getBranchDetails: (branchId: string, access_token: string, includeCustom: Boolean = false) => {
    return requests.getWithToken(
      `${organizationService}v1/branches/${branchId}?includeCustom=${includeCustom}`,
      access_token
    );
  },
  createBranch: (branch: any, access_token: string) => {
    return requests.postWithToken(
      `${organizationService}v2/branches`,
      {
        ...branch,
        branchName: branch.name
      },
      access_token
    );
  },
  deleteBranch: (branchId: string, access_token: string) => {
    return requests.deleteWithToken(
      `${organizationService}v1/branches/${branchId}`,
      access_token
    );
  },
  getFilteredBranchesPPL: (searchValue: string, access_token: string) => {
    return requests.postWithToken(
      `${organizationService}v1/branches/search/ppl`,
      {
        query: searchValue,
        paging: {
          itemsPerPage: 100,
          pageNumber: 1
        }
      },
      access_token
    );
  },
  updateBranch: (branchId: string, branchData: Object, access_token: string) => {
    branchData.address.country = BranchesConstants.COUNTRY;
    return requests.putWithToken(
      `${organizationService}v1/branches/${branchId}`,
      branchData,
      access_token
    );
  },
  updateBranchServicesList: (
    branchId: string,
    services: any[],
    access_token: string
  ) => {
    return requests.putWithToken(
      `${organizationService}v1/branches/${branchId}/services`,
      { services },
      access_token
    );
  },
  getAllServicesList: (access_token: string) => {
    return requests.postWithToken(
      `${organizationService}v1/branches/services`,
      {
        paging: {
          itemsPerPage: 500,
          pageNumber: 1
        }
      },
      access_token
    );
  },
  getFutureAppointmentsForService: (serviceId: string, startDate: string, access_token: string) => {
    return requests.postWithToken(`${appointmentService}v1/appointments/search`,
      {
        serviceId,
        startDate: formatDate(startDate)
      },
      access_token);
  },
  getPPLServiceDetails: (serviceId: string, access_token: string) => {
    return requests.getWithToken(
      `${organizationService}v1/branches/services/${serviceId}/ppl`,
      access_token
    );
  },
};

const Search = {
  search: (searchText: string, access_token: string) => {
    return requests.postWithToken(
      `${appointmentService}v1/appointments/search`,
      {
        startDate: formatDate(moment.utc().subtract(12, "months")),
        searchText,
        order: "desc"
      },
      access_token
    );
  }
};

const Users = {
  getAllUsers: (access_token: string) => {
    return requests.postWithToken(
      `${usersService}v1/users/search`,
      {
        paging: {
          itemsPerPage: 30,
          pageNumber: 1
        }
      },
      access_token
    );
  },
  getFilteredUsers: (filter: Filter, access_token: string) => {
    return requests.postWithToken(
      `${usersService}v1/users/search`,
      {
        ...filter,
        paging: {
          itemsPerPage: 30,
          pageNumber: 1
        }
      },
      access_token
    );
  },
  getCurrentPage: (
    pageNumber: number,
    filter: Filter,
    access_token: string
  ) => {
    return requests.postWithToken(
      `${usersService}v1/users/search`,
      {
        ...filter,
        paging: {
          itemsPerPage: 30,
          pageNumber
        }
      },
      access_token
    );
  },
  getUserInfo: (userId: string, access_token: string) => {
    return requests.getWithToken(
      `${usersService}v1/users/${userId}`,
      access_token
    );
  },
  createUser: (data: any, access_token: string) => {
    return requests.postWithToken(
      `${usersService}v1/adb2c/createuser`,
      {
        email: data.new_user_email,
        displayName: data.new_user_firstName
      },
      access_token
    );
  },
  findUser: (email: string, access_token: string) => {
    return requests.getWithToken(`${usersService}v1/users/find?email=${encodeURIComponent(email)}`, access_token);
  },
  updateUserRoles: (userId: string, data: any[], access_token: string) => {
    return requests.putWithToken(
      `${usersService}v1/users/${userId}/roles`,
      {
        roles: data
      },
      access_token
    );
  },
  updateUserName: (displayName: string, access_token: string) => {
    return requests.putWithToken(
      `${usersService}v1/my`,
      {
        displayName
      },
      access_token
    );
  },
  updateUserMFA: (userId: string, mfaStatus: boolean, access_token: string ) => {
    return requests.putWithToken(
      `${usersService}v1/adb2c/mfa/requires`,
      {
        requiresMFA: mfaStatus,
        userId: userId
      },
      access_token
    );

  },
  removeUserMFA: (userId: string, access_token: string ) => {
    return requests.postWithToken(
      `${usersService}v1/adb2c/mfa/remove`,
      {
        userId: userId
      },
      access_token
    );

  }
};

const TaskManager = {
  getTasksList: (access_token: string) => {
    return requests.getWithToken(
      `${taskManagerService}v1/Orchestrator/tasks`,
      access_token
    );
  },
  getTaskDetail: (taskId: string, access_token: string) => {
    return requests.postWithToken(
      `${taskManagerService}v1/Orchestrator/detail/${taskId}`,
      {},
      access_token
    );
  },
  cancelTask: (taskId: string, access_token: string) => {
    return requests.postWithToken(
      `${taskManagerService}v1/Orchestrator/cancel/${taskId}`,
      {},
      access_token
    );
  },
  dismissTask: (dismissTaskRequestModel: any, access_token: string) => {
    return requests.postWithToken(
      `${taskManagerService}v1/Orchestrator/dismiss`,
        dismissTaskRequestModel,
        access_token
    );
  },
  retryTask: (retryTaskRequestModel: any, access_token: string) => {
    return requests.postWithToken(
      `${taskManagerService}v1/Orchestrator/retry`,
       retryTaskRequestModel,
       access_token
    );
  },
};

export default {
  Register,
  Auth,
  Reports,
  Appointments,
  Calendars,
  Organisations,
  Branches,
  Search,
  Users,
  Rooms,
  OrganisationServices,
  TaskManager,
  ReportsSmartPharmacy,
  ReportsCustom,
};
