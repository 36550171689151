const BranchCalendarsRedesign = {
  table: {
    headers: {
      alert: 'Alert',
      name: 'Calendar name',
      startDate: 'Start date',
      endDate: 'End date',
      services: 'Services',
    },
    labels: {
      none: 'None',
      labelAlert3: 'Services and availability have not been configured for this calendar',
      labelAlert2: 'Services have not been added to this calendar',
      labelAlert1: 'Availability has not been configured for this calendar',
    },
    menuHeader: 'Selected columns',
    sortByLabel: (label) => `Sort by ${label}`,
  },
  filter: {
    placeholder: 'Search for a calendar name',
  },
  button: {
    addCalendar: 'Add a calendar',
    tryAgain: 'Try again',
  },
  error: {
    generic: 'Sorry, something went wrong on our end',
  },
  noResults: {
    noCalendars: 'This branch doesn’t have any calendars',
    noCalendarsFilter: 'Sorry, no calendars found.',
  },
}

const OrganisationCalendars = {
  table: {
    headers: {
      alert: 'Alert',
      name: 'Calendar name',
      branch: 'Branch',
      startDate: 'Start date',
      endDate: 'End date',
      services: 'Services',
    },
    labels: {
      none: 'None',
      labelAlert3: 'Services and availability have not been configured for this calendar',
      labelAlert2: 'Services have not been added to this calendar',
      labelAlert1: 'Availability has not been configured for this calendar',
    },
    menuHeader: 'Selected columns',
    sortByLabel: (label) => `Sort by ${label}`,
  },
  filter: {
    placeholder: 'Search for a calendar name',
  },
  button: {
    addCalendar: 'Add a calendar',
    tryAgain: 'Try again',
  },
  error: {
    generic: 'Sorry, something went wrong on our end',
  },
  noResults: {
    noCalendars: 'This organisation doesn’t have any calendars',
    noCalendarsFilter: 'Sorry, no calendars found.',
  },
}

const UsersListRedesign = {
  table: {
    headers: {
      name: 'User Name',
      role: 'User Role',
      organisation: 'Organisation name',
      branch: 'Associated branch',
      email: 'Email address',
      mfaStatus: '2fa status',
    },
    labels: {
      none: 'None',
    },
    menuHeader: 'Selected columns',
    sortByLabel: (label) => `Sort by ${label}`,
  },
  filter: {
    placeholderSA: 'Search by name, organisation, branch or email address',
    placeholderOrg: 'Search by name, branch or email address',
    placeholderBranch: 'Search by name or email address',
    placeholderXS: "Search...",
  },
  button: {
    addUser: 'Add user',
    tryAgain: 'Try again',
  },
  labels: {
    mfaStatus: ["Not Enabled", "Enabled"],
  },
  error: {
    generic: 'Sorry, something went wrong on our end',
  },
  noResults: {
    noUsers: 'There are no users.',
    noUsersFilter: 'Sorry, no users found.',
  },
  usersListFilters: [
    "All users",
    "Super Admins",
    "Organisation Admins",
    "Branch Admins",
    "Branch Members"
  ],
}

const OrganisationsListRedesign = {
  table: {
    headers: {
      name: 'Organisation name',
      number: 'Company number',
      branches: 'Branches',
      address: 'Address',
      town: 'Town',
    },
    labels: {
      none: 'None',
    },
    menuHeader: 'Selected columns',
    sortByLabel: (label) => `Sort by ${label}`,
  },
  filter: {
    placeholder: 'Search for an organisation by name, company number, city, or postcode',
  },
  button: {
    addOrganisation: 'Add an organisation',
    tryAgain: 'Try again',
  },
  error: {
    generic: 'Sorry, something went wrong on our end',
  },
  noResults: {
    noOrganisations: 'There are no organisations.',
    noOrganisationsFilter: 'Sorry, no organisations found.',
  },
}

const BranchesListRedesign = {
  table: {
    branchesList: {
      headers: {
        name: 'Branch name',
        organisationName: 'Organisation',
        visibility: 'Visibility',
        odsCode: 'ODS code',
        users: 'Users',
        address: 'Branch address',
        town: 'Town',
        city: 'City'
      },
      labels: {
        visible: 'Visible',
        notVisible: 'Not visible'
      },
    },
    menuHeader: 'Selected columns',
    sortByLabel: (label) => `Sort by ${label}`,
  },
  button: {
    addBranch: 'Add a branch',
    tryAgain: 'Try again',
  },
  filter: {
    branchesList: {
      placeholder: 'Search for a branch by name, ODS code, or postcode',
    },
  },
  error: {
    generic: 'Sorry, something went wrong on our end',
  },
  noResults: {
    noBranches: 'There are no branches.',
    noBranchesFilter: 'Sorry, no branches found.',
  },
}

const BranchServicesRedesign = {
  table: {
    servicesSummary: {
      headers: {
        alert: 'Alert',
        name: 'Service name',
        price: 'Service price',
        showPrice: 'Show price',
        vatIncluded: 'VAT included',
        status: 'Status',
        duration: 'Duration',
        cutOff: 'Booking cutoff',
        modes: 'Type',
        bookingUrl:'Booking URL',
      },
      labels: {
        price: '£',
        priceNull: '-',
        statusActive: 'Active',
        statusPaused: 'Paused',
        duration: 'minutes',
        cutOff: 'hours',
        cutOffZero: 'No cutoff',
        modesF2F: 'Face-to-face appointment',
        modesVideo: 'Video appointment',
        modesPhone: 'Phone appointment',
        modesHomeDelivery: 'Home delivery',
        alertWarning: 'This service is not added to any calendars',
      },
    },
    servicesList: {
      headers: {
        name: 'Service name',
      },
    },
    menuHeader: 'Selected columns',
    sortByLabel: (label) => `Sort by ${label}`,
  },
  filter: {
    servicesSummary: {
      placeholder: 'Search for a service by name',
    },
    servicesList: {
      header: 'Select a service to add to your branch',
      placeholder: 'Search for a service',
      labelHideAdded: 'Hide already added services',
      labelAlreadyAdded: ' (already added)',
    },
  },
  button: {
    close: 'Close',
    cancel: 'Cancel',
    continue: 'Continue',
    addService: 'Add a service',
    addServiceToBranch: 'Add service to branch',
    editBranchService: 'Edit service',
    tryAgain: 'Try again',
    goBack: 'Go back',
    removeService: 'Remove service',
    undoChanges: 'Undo changes'
  },
  section: {
    appointmentTypes: {
      header: 'Select available appointment types',
      labelF2F: 'Face-to-face appointment',
      labelVideo: 'Video appointment',
      labelPhone: 'Phone appointment',
      labelHomeDelivery: 'Home delivery',
      tooltipF2F: 'Face-to-face appointments are not available for this service.',
      tooltipVideo: 'Video appointments are not available for this service.',
      tooltipPhone: 'Phone appointments are not available for this service.',
      tooltipHomeDelivery: 'Home deliveries are not available for this service.',
    },
    appointmentDuration: {
      header: 'Select an appointment duration',
      placeholder: 'Select the duration',
    },
    serviceStatus: {
      header: 'Set the service status',
      description: 'The service status defines whether appointments can be booked or not by customers.',
      labelPaused: 'Paused',
      labelActive: 'Active',
    },
    servicePrice: {
      header: 'Enter the service price',
      currency: '£',
      placeholder: '00.00',
      labelVAT: 'VAT included in price',
      labelShowPrice: 'Show a price to customers booking online',
      labelDontShowPrice: 'Don`t show a price to customers booking online',
    },
    bookingCutOff: {
      header: 'Select a booking cutoff period',
      description: 'This is the minimum amount of time in advance that an appointment for this service can be booked.',
      placeholder: 'Select the cutoff period',
    },
    bookingUrl: {
      header: 'Enter the booking URL (optional)',
      descriptionOne: 'This URL defines where a customer will be directed to when they try to book an appointment for this service in Patient Access. This URL should point to your organisation`s booking page.',
      descriptionTwo: 'This service will not be shown online unless a booking URL is provided.',
      placeholder: 'Enter a booking URL',
    },
  },
  error: {
    generic: 'Sorry, something went wrong on our end',
  },
  noResults: {
    servicesSummary: 'This branch doesn’t have any services',
    servicesSummaryFilter: 'Sorry, no services found.',
  },
  sidebar: {
    addService: {
      header: 'Add a service',
    },
    editService: {
      header: 'Edit a service',
    },
    serviceDescription: 'Service description',
  },
  overlay: {
    serviceDescription: {
      error: 'Sorry, something went wrong on our end',
      readMore: (name) => `Read more about ${name}`,
    },
    removeService: {
      header: (serviceName) => `Remove ${serviceName}`,
      descriptionWithoutAppointments: "Are you sure you'd like to remove the service ?",
      descriptionWithAppointments: (appointmentsCount) => `You have ${appointmentsCount} appointments booked for this service at your branch in the future. Please cancel these appointments and try again.`
    },
  },
  validationError: {
    servicePrice: 'Enter a service price.',
    servicePriceNegative: 'Price cannot be negative',
    servicePriceShow: 'Select a price option',
    appointmentType: 'At least 1 appointment type is required.',
    appointmentDuration: 'Select an appointment duration.',
    selectService: 'Select a service to continue',
    cutOff: 'Select a booking cutoff period',
    paused: 'Select a status',
    bookingUrl: 'Please provide a valid URL.',
  },
}

const TaskManager = {
  button: {
    cancel: 'Cancel',
    dismiss: 'Dismiss',
    tryAgain: 'Try again',
    viewDetails: 'View details',
    scrollTop: 'Scroll to top',
    goBack: 'Go back',
    close: 'Close',
    cancelTask: 'Cancel task',
    dismissTasks: 'Dismiss tasks',
    dismissAllTasks: 'Dismiss all tasks',
    dismissCompleted: 'Dismiss completed',
    dismissCompletedWithErrors: 'Dismiss completed with errors',
    tryAgainPartial: (branchCount) => `Try remaining ${branchCount} branches again`
  },
  overlay: {
    branchErrorHeaderText: (taskName) => `Error when ${taskName}`,
    errorSupportContent1: `If the problem still persists, please `,
    errorSupportContent2: ` for assistance.`,
    errorContent: `An error has occurred, please try again.`,
    branchPartialErrorContentText: (string) => `An error has occurred, ${string} branches could not be updated.`,
    branchCancelledContentText: (string) => `This task was cancelled, ${string} branches were not updated.`,
  },
  header: {
    default: 'Tasks',
    errorTaskDetail: "Couldn't load task details",
  },
  progressBarLabel: {
    error: 'An error has occured',
    branchesProcessed: (string) => `${string} branches processed`,
    branchesUpdatedSuccesfully: (string) => `${string} branches processed succesfully`,
  },
  error: {
    generic: 'Sorry, something went wrong on our end.',
  },
  noResults: {
    noTasks: 'There are no active tasks.',
  },
  tooltip: {
    inProgress: (taskCount) => `${taskCount} task in progress`,
    inQueue: (taskCount) => `${taskCount} task queued`,
    errors: (taskCount) => `${taskCount} task errors`,
  },
  labels: {
    Queued: 'Queued',
    taskInfo: (userName, createdTime) => `Task started byy ${userName} at ${createdTime}`,
  },
};

const OrganisationServices = {
  table: {
    servicesSummary: {
      headers: {
        serviceName: 'Service name',
        servicePrice: 'Service price',
        branches: 'Branches',
        showPrice: 'Show price',
        status: 'Status',
        duration: 'Duration',
        cutOff: 'Booking cutoff',
        modes: 'Type',
        vatIncl: 'VAT included',
        bookingUrl: 'Booking URL',
        alert: 'Alert',
      },
      labels: {
        servicePrice: '£',
        servicePriceNull: '-',
        status: 'active',
        duration: 'mins',
        cutOff: 'hours',
        cutOffZero: 'No cutoff',
        bookingUrlEmpy: 'None set',
        bookingUrlMultiple: 'Multiple URLs',
        alertWarning: 'No branches have this service added to their calendars',
        alertCaution: (branches) => `${branches} branches do not have this service added to their calendars`,
      },
    },
    servicesList: {
      headers: {
        serviceName: 'Service name',
      },
    },
    branchesSummary: {
      headers: {
        branchName: 'Branch name',
        servicePrice: 'Service price',
        showPrice: 'Show price',
        status: 'Status',
        duration: 'Duration',
        cutOff: 'Booking cutoff',
        modes: 'Type',
        vatIncl: 'VAT included',
        bookingUrl: 'Booking URL',
        alert: 'Alert',
      },
      labels: {
        branchSummary: 'branches',
        servicePrice: '£',
        servicePriceNull: '-',
        showPriceSummary: 'shown',
        statusSummary: 'active',
        statusActive: 'Active',
        statusPaused: 'Paused',
        duration: 'mins',
        cutOff: 'hours',
        cutOffZero: 'No cutoff',
        vatIncluded: 'branches',
        bookingUrlEmpy: 'None set',
        bookingUrl: 'URL set',
        alert: 'alerts',
        alertCaution: 'The service is not added to this branch`s calendars',
      },
    },
    branchesList: {
      headers: {
        branchName: 'Branch name',
      },
    },
    menuHeader: 'Selected columns',
    sortByLabel: (label) => `Sort by ${label}`,
  },
  button: {
    close: 'Close',
    cancel: 'Cancel',
    continue: 'Continue',
    tryAgain: 'Try again',
    goBack: 'Go back',
    goToBranches: 'Go to branches',
    addService: 'Add a service',
    addServiceToBranches: `Add service to branches`,
    addServiceToAllBranches: `Add service to all branches`,
    addServiceToNoBranches: (branches) => `Add service to ${branches} branches`,
    addToBranches: 'Add to branches',
    saveChanges: 'Save changes',
    editService: 'Edit service',
    editAtAllBranches: `Edit service at all branches`,
    editAtNoBranches: (branches) => `Edit at ${branches} branches`,
    saveAtBranches: `Save changes to branches`,
    saveAtAllBranches: `Save changes to all branches`,
    saveAtNoBranches: (branches) => `Save changes to ${branches} branches`,
    removeAtNoBranches: (branches) => `Remove service from ${branches} branches`,
  },
  filter: {
    servicesSummary: {
      placeholder: 'Search for a service',
    },
    servicesList: {
      header: 'Select a service to add to your organisation',
      placeholder: 'Search for a service',
      labelHideAdded: 'Hide already added services',
      labelAlreadyAdded: ' (already added)',
    },
    branchesSummary: {
      placeholder: 'Search for a branch',
    },
    branchesList: {
      header: 'Select branches to add the service to',
      editHeader: 'Confirm the branches to edit the services at',
      placeholder: 'Search for a branch',
    },
  },
  sidebar: {
    addService: {
      header: 'Add a service',
    },
    editService: {
      header: 'Edit ',
      addServiceToBranches: `Add a service to branches`,
      branchesSelected: (branches) => `${branches} branches selected`,
    },
    serviceDescription: 'Service description',
  },
  section: {
    serviceStatus: {
      header: 'Set the service status',
      description: 'The service status defines whether appointments can be booked or not by customers.',
      labelPaused: 'Paused',
      labelActive: 'Active',
    },
    servicePrice: {
      header: 'Enter the service price',
      currency: '£',
      placeholder: 'Price',
      labelVAT: 'VAT included in price',
      labelShowPrice: 'Show a price to customers booking online',
      labelDontShowPrice: "Don't show a price to customers booking online",
    },
    appointmentTypes: {
      header: 'Select available appointment types',
      labelF2F: 'Face-to-face appointment',
      labelVideo: 'Video appointment',
      labelPhone: 'Phone appointment',
      labelHomeDelivery: 'Home delivery',
      tooltipF2F: 'Face-to-face appointments are not available for this service.',
      tooltipVideo: 'Video appointments are not available for this service.',
      tooltipPhone: 'Phone appointments are not available for this service.',
      tooltipHomeDelivery: 'Home deliveries are not available for this service.',
      tooltipOnlineF2F: 'Face-to-face appointments will not be added to an online branch.',
    },
    appointmentDuration: {
      header: 'Select an appointment duration',
      placeholder: 'Duration',
      labelMinutes: ' mins',
    },
    bookingCutOff: {
      header: 'Select a booking cutoff period',
      description: 'This is the minimum amount of time in advance that an appointment for this service can be booked.',
      placeholder: 'Cutoff',
      labelHours: ' hours',
    },
  },
  overlay: {
    serviceDescription: {
      error: 'Sorry, something went wrong on our end',
      readMore: (name) => `Read more about ${name}`,
    },
    confirmation: {
      header: (service, branches) => `Adding ${service} to ${branches} branches`,
      editHeader:  (service, branches) => `Editing ${service} at ${branches} branches`,
      deleteHeader: (service, branches) => `Remove ${service} from ${branches} branches`,
      headerAllBranches: (service) => `Adding ${service} to all branches`,
      editHeaderAllBranches:  (service) => `Editing ${service} at all branches`,
      errorHeaderAddPartialBranches:  (branches) => `Error when adding service to ${branches} branches`,
      errorHeaderEditPartialBranches:  (branches) => `Error when editing service at ${branches} branches`,
      errorHeaderDeletePartialBranches:  (branches) => `Error when deleting service at ${branches} branches`,
      content: 'Please ensure to add this service to your branch calendars in order for it to become available to be booked.',
      editContent: 'Your changes will be applied to the selected branches offering this service',
      deleteContent: 'By continuing, this service will no longer be available at any branches',
      errorAddAllContent: 'An error has occurred, please try again',
      errorEditAllContent: 'An error has occurred, please try again',
      errorDeleteAllContent: 'An error has occurred, please try again',
      errorAddPartialContent: (branches) => `Due to some reason, ${branches} branches could not be added`,
      errorEditPartialContent: (branches) => `Due to some reason, ${branches} branches could not be updated`,
      errorDeletePartialContent: (branches) => `Due to some reason, ${branches} branches could not be deleted`,
      errorSupportContent1: 'If the problem persists, please ',
      errorSupportContent2: ' for assistance',
      viewAffectedBranches: (branches) => `View ${branches} affected branches`,
      branchNotUpdated: 'Branches that were not updated',
    },
  },
  progress: {
    taskInProgress:  (completed, totalBranches) => `${completed}/${totalBranches} branches processed`,
    taskComplete:  (completed, totalBranches) => `${completed}/${totalBranches} branches processed successfully`,
  },
  error: {
    generic: 'Sorry, something went wrong on our end',
  },
  validationError: {
    generic: 'Please fill in this field',
    servicePrice: 'Enter a service price.',
    appointmentType: 'At least 1 appointment type is required.',
    appointmentDuration: 'Select an appointment duration.',
    selectOneBranch: 'Select at least 1 branch to continue',
    selectService: 'Select a service to continue',
    cutOff: 'Select a booking cutoff period',
    paused: 'Select a status',
    affiliatePriceOption: 'Select a price option',
    singleValue: 'Enter a single value.',
    makeChange: 'Make at least 1 change to continue',
    negativePrice: 'Price cannot be in negative',
  },
  noResults: {
    servicesSummary: 'This organisation doesn’t have any services',
    servicesSummaryFilter: 'Sorry, no services found.',
    servicesSummaryBranches: 'To add services to this organisation, first add a branch',
    branchesSummary: 'This service is not added to any branches',
    branchesSummaryFilter: 'Sorry, no branches found.',
  },
  notification: {
    branchesNumber: (branches) => `There are ${branches} branches with this service not added to their calendars.`,
    branchesNone: 'No branches with this service have it added to their calendars.',
  }
};

const SmartPharmacyRegister = {
  sectionHero: {
    header: "Introducing Smart Pharmacy from Patient Access",
    content: "Smart Pharmacy from Patient Access is a groundbreaking new way to help drive more nominations for your pharmacies. By becoming a Smart Pharmacy you’ll be able to engage more with Patient Access users and make their lives easier.",
    button: "Get started",
  },
  sectionNomination : {
    header: "Make your customers’ lives easier",
    featureOneHeader: "Repeat medication tracking",
    featureOneContent: "Your customers will be able to track their repeat medication orders in the Patient Access app. They’ll be notified when their order status is updated by you in ProScript Connect, and will no longer need to call or turn up to your pharmacy before their order is ready.",
    featureTwoHeader: "Drive more nominations",
    featureTwoContent: "By offering repeat medication tracking, you’ll be giving patients a unique reason to nominate your pharmacy for their repeat medication, which they can easily do in Patient Access.",
  },
  sectionProfile : {
    header: "Grow your brand with Patient Access",
    featureOneHeader: "Promote your pharmacies and their services",
    featureOneContent: "Smart Pharmacies get their own branded profile page on Patient Access where they can list their branch information. This includes the range of services which users can book and pay for directly within the app.",
    featureTwoHeader: "Fully managed marketing campaigns",
    featureTwoContent: "Once you become a Smart Pharmacy, our marketing team will focus its efforts on generating more customers and bookings for you. We’ll contact Patient Access users in your local area and inform them that they can nominate your pharmacies to track their repeat medication orders.",
  },
  sectionRegister: {
    header: "Get started today",
    content: "If you’re interested in becoming a Smart Pharmacy, register your interest below and a member of our team will contact you to discuss it further.",
    button: "Register your interest",
    successMessage: "Thanks for your interest, a member of the sales team will be in touch soon.",
    errorMessage: "There was a problem registering your interest, please refresh the page and try again.",
  },
};

const SmartPharmacy = {
  title: "Smart Pharmacy",
  body: "Connect with customers and allow those who nominate your branches to track their prescription requests from within Patient Access.",
  lastEnabled: "Last enabled on",
  lastDisabled: "Last disabled on",
  neverEnabled: "Never enabled",
  radio: {
    disabled: "Disabled at all branches",
    enabled: "Enabled at all branches",
  },
  overlayEnable: {
    title: "Enabling Smart Pharmacy at this organisation",
    body: "Please ensure that the necessary steps have been taken before continuing.",
    buttonBack: "Go back",
    buttonEnable: "Enable Smart Pharmacy",
  },
  overlayRadio: {
    disable: "Don't turn on smart pharmacy features for any branch",
    enable: "Turn on smart pharmacy features for all branches",
  },
  overlayDisable: {
    title: "Disabling Smart Pharmacy at this organisation",
    body: "By continuing, this organisation and any Patient Access users with their branches nominated will lose access to Smart Pharmacy features.",
    buttonBack: "Go back",
    buttonDisable: "Disable Smart Pharmacy",
  },
  overlayEnableBranches: {
    title: (branches, selectedBillingModel) => `Enabling Smart Pharmacy features with ${selectedBillingModel} for ${branches} branches`,
    body: "These branches will be available in Patient Access to be nominated as a Smart Pharmacy, and their profiles can be enabled to be viewed by users who have them nominated.",
    buttonBack: "Go back",
    button: (branches) => `Enable for ${branches} branches`,
  },
  overlayDisableBranches: {
    title: (branches, selectedBillingModel) => `Disabling Smart Pharmacy features with ${selectedBillingModel} for ${branches} branches`,
    body: "These branches and any Patient Access users with them nominated will lose access to Smart Pharmacy features.",
    buttonBack: "Go back",
    button: (branches) => `Disable for ${branches} branches`,
  },
  billingModel: {
    title: "Billing model",
    subscriptionRadio: "Subscription Model",
    transactionRadio: "Transaction Model",
    subscriptionRadioDesc: "Standard payable fee per month",
    transactionRadioDesc: "Fee payable per prescription item dispensed for new patients each month"
  }
};

const AvailabilityRules = {
  timeOffHeading: "Time off between appointments",
  timeOffDescription: "You can set a minimum amount of time after a booked appointment that cannot be booked either by customers or staff.",
  timeOffSwitchLabel: "Add time off between appointments",
  timeOffCheckboxLabel: "Allow branches to control their own time off between appointments",
  timeOffSelectPlaceholder: "Select a value",
  timeOffSelectError: "Select a value",
  timeOffOverlay: {
    headingBeforeValue: "Updating time off between appointments at",
    headingAfterValue: "branches",
    description: "Please note: updating time off between appointments will not affect appointments which have already been booked.",
    ckeckboxLabelBeforeValue: "Also update the time off between appointments for the",
    ckeckboxLabelAfterValue: "branches that have configured their own.",
    buttonBack: "Go back",
    buttonSave: "Save changes",
  },
};

const Recaptcha = {
  error: {
    signIn: "Sorry, we have not been able to sign you in at the moment. Please refresh the page and try again. If you are still having trouble signing in, please try again at another time.",
    expiredToken: "We have been unable to connect you to the reCAPTCHA service. Please check your internet connection and then reload the page. If you are still having trouble signing in, please try again at another time."
  }
};

const Telemetry = {
  naOrganisationId: 'All organisations',
  naBranchId: 'All branches',
  naCareProviderId: 'All branches',
};

const Reports = {
  sidebar: {
    services: 'Services',
    smartPharmacy: 'Smart Pharmacy',
    lastUpdatedAt: 'Data last updated at',
  },
  loading: 'Loading…',
  errorHeader: 'Sorry, something went wrong on our end.',
  errorButton: 'Try again',
  revenueTitle: 'Revenue:',
  revenueTotalEstimated: 'Total revenue (estimated)',
  revenueNetOnline: 'Online net revenue',
  revenueInStoreEstimated: 'In-store revenue (estimated)',
  revenueServiceCommision: 'Service commission',
  revenueStripeFees: 'Stripe fees',
  revenueTotalFees: 'Total fees',
  revenueOnlineGross: 'Online gross revenue',
  revenueTooltipHeaderPartOne: 'Revenue on',
  revenueGraphHeader: 'Revenue per day (£)',
  revenueGraphNoResults: 'There is no revenue to display.',
  appointmentsTitle: 'Appointments:',
  appointmentsTotal: 'Total appointments',
  appointmentsGraphStatusHeader: 'All appointments by status',
  appointmentsGraphStatusNoResults: 'There are no appointments to display.',
  appointmentsGraphOrganisationHeader: 'Appointments per organisation',
  appointmentsGraphOrganisationNoResults: 'There are no appointments to display.',
  appointmentsStatus: {
    all: "All appointments",
    statusRequired: 'Service status update required',
    cancelled: 'Cancelled',
    missed: 'Customer did not attend',
    serviceNotProvided: 'Customer attended, service not provided',
    serviceProvided: 'Service provided',
    upcoming: 'Upcoming'
  },
  downloadOverlay: {
    heading: 'Download current view as CSV',
    body: 'You will download the current reports view with the following filters applied:',
    bodyCustom: 'You will download the current organisations reports',
    smartPharmacyHeading: "Download Smart Pharmacy data as CSV",
    smartPharmacyBody: 'You will download Smart Pharmacy data with the following filters applied:',
    organisationLabel: 'Organisation:',
    organisationAll: 'All organisations',
    branchLabel: 'Branch:',
    branchAll: 'All branches',
    serviceLabel: 'Service:',
    serviceAll: 'All services',
    statusLabel: 'Service status:',
    statusAll: 'All statuses',
    dateLabel: 'Date:'
  },
  buttonResetFilter: 'Reset filter',
  buttonDownloadSCV: 'Download as CSV',
  buttonBack: 'Go back',
  buttonDownload: 'Download',
  smartPharmacyEnabledOrganisations: 'Smart Pharmacy-enabled organisations',
  smartPharmacyEnabledBranches: 'Smart Pharmacy-enabled branches',
  smartPharmacyNominations: 'Smart Pharmacy nominations',
  smartPharmacyNominationIncrease: ' vs previous period',
  smartPharmacyAboutFees: 'About Fees',
  smartPharmacyFeePeriod: 'Fees this period',
  smartPharmacyFeePerItem: 'Fee per item',
  smartPharmacyFeeCurrency: '£',
  smartPharmacyNominationGraphTitle: 'Smart Pharmacy nominations per month',
  smartPharmacyNominationGraphLegend1: 'Total nominations',
  smartPharmacyNominationGraphLegend2: 'New nominations',
  smartPharmacyMedicationGraphTitle: 'Smart Pharmacy Prescriptions and items breakdown per month (New patient nominations) ',
  smartPharmacyMedicationGraphLegend1: 'Number of prescriptions downloaded',
  smartPharmacyMedicationGraphLegend2: 'Number of items downloaded',
  smartPharmacyMedicationGraphLegend3: 'Number of items processed (chargeable items)',
  smartPharmacyMedicationGraphLegend32: 'Number of items processed',
  smartPharmacyMedicationGraphLegend4: 'Number of items for which notification sent without estimated time',
  smartPharmacyMedicationGraphLegend5: 'Number of items for which notification sent with estimated collection time',
  smartPharmacyMedicationGraphLegend6: 'Number of items for which notification sent with estimated delivery time',
  smartPharmacyMedicationGraphTooltip1: 'Prescriptions downloaded',
  smartPharmacyMedicationGraphTooltip2: 'Items downloaded',
  smartPharmacyMedicationGraphTooltip3: 'Items processed',
  smartPharmacyMedicationGraphTooltip4: 'Items for which notification sent without estimated time',
  smartPharmacyMedicationGraphTooltip5: 'Items for which notification sent with estimated collection time',
  smartPharmacyMedicationGraphTooltip6: 'Items for which notification sent with estimated delivery time',
  smartPharmacyDeliveryTrendGraphTitle: `Number of prescriptions downloaded vs number of notifications sent to patients. (New nominations and existing patients)`,
  smartPharmacyDeliveryTrendGraphLegend1: "Number of 'Smart Pharmacy' prescriptions downloaded",
  smartPharmacyDeliveryTrendGraphLegend2: "Number of 'Smart Pharmacy' prescriptions for which notification sent to patient",
  smartPharmacyPrescriptionRequestGraphTitle: `Average duration between pharmacy downloading prescription and notification sent to patient. (New nominations and existing patients)`,
  smartPharmacyPrescriptionRequestGraphLegend1: 'Time taken between prescription downloaded and notifications sent',
  selectServiceHeader: 'Select a service:',
};

const Breadcrumbs = {
  loading: 'Loading…',
  homeSuperAdmin: 'Admin Panel',
  homeOrganizationAdmin: 'Organisation panel',
};

const CurrentPage = {
  branchServices: "BranchServices",
  branchCalendars: "BranchCalendars",
  branchRooms: "BranchRooms"
};

const Agenda = {
  agendaTitle: "Agenda",
  agendaEmpty: "Looks like you don't have any scheduled appointments yet."
};

const Share = {
  scrollUpButton: "Scroll to top",
  scrollUpSearchButton: "Scroll up to first result",
  pageNotFound: "Page not found",
  myProfileDropdown: "My Profile",
  logOut: "Sign Out",
  addAppointmentSelect: "New appointment",
  searchFormPlaceholder: "Search branch appointments",
  searchNoItems: "We couldn't find any result for your search.",
  defaultOrganisationSelect: "All organisations",
  defaultBranchSelect: "All branches",
  defaultServiceSelect: "All services",
  targetBlank: "_blank",
  internalEventButton: "Internal event"
};

const Header = {
  navigation: {
    calendar: "Calendar",
    reports: "Reports",
    admin: "Admin"
  },
  labelOrganisation: "Organisation",
  labelBranch: "Branch",
  calendarDropdownIcon: "Calendar dropdown",
  support: "Help",
  supportLinkOne: "Contact support",
  supportLinkTwo: "Support centre",
  supportLinkThree: "Give feedback",
  tasks: "Tasks",
  agenda: "Agenda",
};

const Login = {
  backToSign: ["Don't need to reset your password?", "Go back to sign in"],
  errorCode: [
    "Sorry, we could not sign you in. Please check your details and try again.",
    "This account has been locked due to too many unsuccessful login attempts. An email has been sent with instructions on how to reset your password.",
    "Your account has not been activated. Please follow the link in an invitation email you've received from Patient Access for Professionals. Check your junk email box in case it’s in been placed in there.",
    "You do not have permission to access any branches or organisations in Patient Access for Professionals. Please contact your organisation admin or branch admin and ask them to give you access."
  ],
  header: "Sign in to your account",
  emailError: "Please provide a valid email address.",
  emailAddress: "Email address",
  registeredEmail: "Registered email address",
  passwordErrorShort: "Password is empty or invalid.",
  passwordError:[
    "Your password must:",
    "be atleast 12 characters long",
    "contain atleast 1 lower-case letter",
    "contain atleast 1 uppercase letter",
    "contain atleast 1 number",
    "contain atleast one of the following supported symbols: ! # $ % £ * - ? @ _ | ^ ~ : .",
    "not contain your email address"
  ],
  noPassword: "Please enter your password.",
  noEmail: "Please enter your email.",
  register: "Register your interest for Patient Access for Professionals",
  policy: "Privacy Policy",
  policyLink: "https://supportpro.patientaccess.com/privacy-policy",
  terms: "Terms of Use",
  termsLink: "https://supportpro.patientaccess.com/terms-of-use",
  linkNameSupportCentre: "Support centre",
  linkSupportCentre: "https://supportpro.patientaccess.com",
  support: "Support Centre",
  cancellation: "Cancellation Policy",
  cancellationLink: "https://supportpro.patientaccess.com/cancellation-policy",
  attempt: "You have one attempt left before your account will be locked",
  attemptError1:
    "Sorry, we are unable to sign you in. Please check your details and try again.",
  attemptError2: "You have one attempt left before your account will be locked",
  try: "Try again",
  termsAndUseLink: [
    "By logging in, I agree to the ",
    "Terms of Use",
    " and I confirm that I have read and understood the ",
    "Privacy Notice"
  ],
  forgotLink: ["Forgotten your", "password"],
  forgot: "I forgot my password",
  reset: "Reset your password",
  noUser: "Sorry, we cannot find an account with that email address.",
  registeredEmail1: "Please enter the email address that you use for Patient Access for Professionals below.",
  registeredEmail2:
    "An email will be sent to you with instructions on how to reset your password.",
  resetPassword: "Reset password",
  new: "Please, enter a new password",
  updatePassword:
    "To update your password, please enter a new password in the fields below.",
  signIn: "Sign in",
  emailSent: "An email has been sent with instructions on how to reset your password. If you do not received an email after 5 minutes, please try again",
  passwordNotEqual: "The passwords you have entered do not match. Please try again.",
  password: "Password",
  confirmPassword: "Confirm password",
  linkExpired: "Sorry the link has expired. Please go to the Forgot Password page and try again.",
  welcome: "Welcome to Patient Access for Professionals",
  complete:
    "To complete your account set up, please create a password below.",
  labelPassword: "Password",
  underMaintenanceError: "Unfortunately the site is down for a bit of maintenance right now. We’ll be back up and running soon.",
  recaptchaDisclaimer: [
    "This site is protected by reCAPTCHA and the",
    "Google ",
    "Privacy Policy ",
    "and ",
    "Terms of Service ",
    "apply.",
  ],
  recaptchaPolicyLink: "https://policies.google.com/privacy",
  recaptchaTermsLink: "https://policies.google.com/terms",
  noRolesErrorHeader: "You have no roles assigned to your account",
  noRolesErrorDescription: ["You could not be signed in as there are no roles assigned to your account.",
  "If you believe this to be a mistake, please contact your organisation administrator."
  ],
};

const Buttons = {
  buttonDone: "Done",
  buttonEdit: "Edit",
  buttonCancel: "Cancel",
  buttonClose: "Close",
  buttonDelete: "Delete",
  buttonChangePassword: "Change password",
  undoChanges: "Undo changes",
};

const Appointment = {
  appointmentDetailsTitle: "Appointment Details",
  appointmentDetailsClose: "Close Appointment Details",
  appointmentStatusTitle: "Appointment status",
  clinicianInfoCalendar: "Calendar",
  clinicianInfoRoom: "Room",
  pastAppointmentStatusWarning: [
    "This appointment already took place, please select a new status.",
    "Setting the past appointments status is required in order to receive your payment for online appointments."
  ],
  videoAppointmentMessage: "Please send details of how to join the video appointment directly to the patient using your preferred communication method.",
  pastStatus: "past",
  removedServiceText: "service was removed",
  cancellationText: ["Appointment status: Cancelled by", "on", "Reason: "],
  cancellationStatus: {
    none: {
      value: 0,
      label: "None"
    },
    patient: {
      value: 1,
      label: "customer "
    },
    pharmacy: {
      value: 2,
      label: "clinician"
    },
    system: {
      value: 3,
      label: "system"
    }
  },
  customerDetails: {
    title: "Customer details",
    buttonDone: "Done",
    buttonEdit: "Edit",
    inputName: "First Name",
    inputDOB: "Date of birth (Optional)",
    inputDOBError: "Please provide customer's date of birth",
    inputNameError: "First Name is required",
    inputFamilyName: "Last name",
    inputFamilyNameError: "Last Name is required",
    postcode: "Postcode (Optional)",
    inputPostcodeError: "Please provide customer's postcode",
    phoneNumber: "Phone number (optional)",
    phoneNumberMandatory: "Phone number",
    phoneNumberError: "Please enter a valid phone number",
    phoneNumberEmptyError: "Phone number is required",
    email: "E-mail address (optional)",
    emailMandatory: "E-mail address",
    emailError: "Please enter a valid email",
    emailEmptyError: "Email address is required",
    notProvided: "Not provided"
  },
  buttons: {
    delete: "Delete",
    discardChanges: "Discard changes",
    saveChanges: "Save changes",
    cancel: "Cancel",
    close: "Close",
    refund: "Refund customer",
    join: "Join video call"
  },
  joinTooltip: {
    before: 'You can join the call',
    after: 'minutes before it is due to take place.',
  },
  type: {
    none: { title: "None", value: 0 },
    online: { title: "Online", value: 1 },
    walkIn: { title: "Walk-in", value: 2 }
  },
  status: {
    booked: {
      label: "Booked",
      value: 1,
      color: "grey-light",
      status: 1,
      outcomeStatus: 0
    },
    provided: {
      label: "Service provided",
      value: 2,
      color: "green",
      status: 2,
      outcomeStatus: 1
    },
    notProvided: {
      label: "Customer attended, service was not provided",
      value: 3,
      color: "orange",
      status: 2,
      outcomeStatus: 2
    },
    cancelled: {
      label: "Cancelled",
      value: 4,
      color: "red",
      status: 3,
      outcomeStatus: 0
    },
    missed: {
      label: "Customer did not attend",
      value: 5,
      color: "orange",
      status: 2,
      outcomeStatus: 3
    }
  },
  outcomeStatus: [
    { value: 0, label: "none" },
    { value: 2, label: "serviceProvided" },
    { value: 3, label: "serviceNotProvided" },
    { value: 4, label: "cancelled" },
    { value: 5, label: "missed" }
  ],
  paymentStatus: {
    price: "Service price",
    status: "Payment status",
    none: { title: "None", value: 0 },
    notPaid: { title: "Not Paid", value: 1 },
    paid: { title: "Paid", value: 2 },
    refunded: { title: "Refunded", value: 3 },
    paidOnline: "Paid online",
    refundedbySupportTeam: { title: "Refunded by support team", value: 4 }
  },
  CustomerServiceRefundStatus: [
    {value:0, label: "none"},
    {value:1, label: "Full"},
    {value:2, label: "Partial"}
  ],
  refundAppointment: {
    refundSelect: "Select an option",
    refundLabel: "Refund Type",
    reasonForRefundLabel: "Reason for Refund",
    refundReasonLabel: "Refund Reason",
    whichTypeOfRefund: "Select which type of refund you are giving the customer.",
    refundReasonMessage:
    [
      "The customer will be refunded ",
      "to their original payment method in 5-7 working days."
    ],
    refundReasonPlaceHolder:"Please provide the reason here...",
    refundReasonError: "Enter a reason for refund"
  },
  bookingMethodLabel: {
    before: "Booked",
    after: "on"
  },
  bookingMethod: [
    "n/a",
    "online",
    "in-store"
  ],
  bookingType: [
    "Face-to-face appointment",
    "Video appointment",
    "Telephone appointment"
  ]
};

const Search = {
  searchFilterOptions: [
    {
      value: 0,
      label: "All Appointments",
      status: "all",
      outcomeStatus: "all"
    },
    {
      value: 1,
      label: "Upcoming",
      status: "Upcoming",
      outcomeStatus: "Upcoming"
    },
    {
      value: 2,
      label: Appointment.status.cancelled.label,
      status: Appointment.status.cancelled.status,
      outcomeStatus: Appointment.status.cancelled.outcomeStatus
    },
    {
      value: 3,
      label: "Status update required",
      status: Appointment.status.booked.status,
      outcomeStatus: Appointment.status.booked.outcomeStatus
    },
    {
      value: 4,
      label: Appointment.status.provided.label,
      status: Appointment.status.provided.status,
      outcomeStatus: Appointment.status.provided.outcomeStatus
    },
    {
      value: 5,
      label: Appointment.status.notProvided.label,
      status: Appointment.status.notProvided.status,
      outcomeStatus: Appointment.status.notProvided.outcomeStatus
    },
    {
      value: 6,
      label: Appointment.status.missed.label,
      status: Appointment.status.missed.status,
      outcomeStatus: Appointment.status.missed.outcomeStatus
    }
  ]
};

const Modals = {
  deleteOrganisationConflictOverlay:{
    header: "The organization can't be removed.",
    body: (branches) => `The organization can't be removed. The following organization branches have existing appointments for future dates: ${branches}. Please cancel all the appointments at organization branches and try again.`,
    buttonOk: "Back"
  },
  deleteBranchConflictOverlay:{
    header: "The branch can't be removed.",
    content: "The branch you are trying to remove has existing appointments for future dates. Please cancel all the appointments and try again.",
    buttonOk: "Back"
  },
  deleteBranchWithUncompletedConflictOverlay:{
    header: "The branch can't be removed.",
    content: "The branch you are trying to remove has booked appointments left uncompleted. Please update the status of those appointments first and try again.",
    buttonOk: "Back"
  },
  duplicateConflictOverlay:{
    header: 'This user already has a role at this branch/organisation',
    content: 'The user you are adding already has a role at this branch/organisation. Would you like to edit their roles?',
    buttonCancel: 'Cancel',
    buttonConfirm: 'Edit roles'
  },
  cancelOnlineOverlay: {
    header: "Cancel online appointment",
    notPaidPatientMessage:
      "When you cancel this appointment for the customer, an email will be sent to the customer informing them that you have cancelled the appointment on their behalf.",
    notPaidClinitianMessage:
      "Are you sure you want to cancel this booking? When you cancel, the customer will receive a cancellation email including your cancellation reason",
    paidUpfrontPatientMessage: "When you cancel this appointment an email will be sent to the customer informing them that you have cancelled the appointment on their behalf. If a refund is due as part of the cancellation policy, the refund will automatically be issued to the customer. The customer will receive the refund to their payment card in 5-7 working days.",
    paidfrontPatientMessage:
    [
      "Are you sure you want to cancel this appointment?",
      "When you cancel this appointment an email will be sent to the customer informing them that you have cancelled the appointment on their behalf. If a refund is due as part of the cancellation policy, the refund will automatically be issued to the customer. The customer will receive the refund to their payment card in 5-7 working days."
    ],
    paidUpfrontClinitianMessage:
      "Are you sure you want to cancel this appointment?" +
      "When you cancel an appointment a cancellation email and a full refund will automatically be issued to the customer. The cancellation and refund cannot be altered once it’s been issued. The customer will receive the refund to their payment card in 5-7 working days.",
    paidfrontClinitianMessage:
    [
      "Are you sure you want to cancel this appointment?",
      "When you cancel an appointment a cancellation email and a full refund will automatically be issued to the customer. The cancellation and refund cannot be altered once it’s been issued. The customer will receive the refund to their payment card in 5-7 working days."
    ],
    freeAppointmentWarningMessage:
      "Are you sure you want to cancel this appointment?" +
      "When you cancel, the customer will receive a cancellation email including your cancellation reason.",
    textareaLabel: "Reason",
    textareaPlaceholder: "Reason for cancellation",
    buttonOk: "Cancel appointment",
    buttonCancel: "Don't cancel",
    whoIsCancelling: "Select who is cancelling the appointment.",
  },
  cancelOverlay: {
    header: "Cancel appointment",
    warningTitle: "Warning message",
    notPaidClinitianMessage:
      "Only cancel this appointment once you have contacted the customer directly to inform them that you are cancelling. Please call/message them on their phone, or if you don’t have a phone number logged, send them an email.",
    notPaidPatientMessage:
      "Only cancel this appointment once you have contacted the customer directly to inform them that you are cancelling. Please call/message them on their phone, or if you don’t have a phone number logged, send them an email.",
    paidUpfrontPatientMessage:
      "Only cancel this appointment once you have contacted the customer directly to inform them that you are cancelling. Please call/message them on their phone, or if you don’t have a phone number logged, send them an email.",
    paidUpfrontClinitianMessage:
      "Only cancel this appointment once you have contacted the customer directly to inform them that you are cancelling. Please call/message them on their phone, or if you don’t have a phone number logged, send them an email.",
    freeAppointmentWarningMessage:
      "Are you sure you want to cancel this appointment?" +
      "When you cancel, the customer will receive a cancellation email including your cancellation reason.",
    textareaLabel: "Reason",
    textareaPlaceholder: "For internal use, please record the reason for cancellation",
    buttonOk: "Cancel appointment",
    buttonCancel: "Don't cancel",
    whoIsCancelling: "Select who is cancelling the appointment.",
    whoIsCancellingLabel: "Cancelled by",
    whoIsCancellingPlaceholder: "Select an option"
  },
  serviceNotProvidedOverlay: {
    header: "Service not provided",
    content: "You are logging that this service was not provided to the customer.",
    buttonOk: "Ok",
    buttonCancel: "Back"
  },
  serviceNotProvidedOverlayOnline: {
    header: "Service not provided",
    content:
      "You are marking the service as not provided to the customer." +
      "A full refund will automatically be sent to the customer." +
      "The customer will receive the refund to their payment card in 5-7 working days.",
    buttonOk: "Ok",
    buttonCancel: "Back"
  },
  serviceProvidedOverlay: {
    header: "Have you taken payment for this appointment?",
    content:
      "You have marked the service as provided to the customer." +
      "You need to charge the customer for this appointment. Have you taken payment for this appointment?",
    price: "Service price",
    buttonOk: "Payment taken",
    buttonCancel: "Payment not taken"
  },
  discardChangesOverlay: {
    header: "You are leaving without saving changes",
    content:
      "You have unsaved changes, are you sure you want to discard your recent changes?",
    buttonDiscard: "Discard changes",
    buttonStay: "Stay"
  },
  missedOverlay: {
    header: "Customer did not attend",
    content:
      "We’re sorry to hear that the customer didn’t attend. Please note, once you have changed this status you cannot amend it",
    buttonCancel: "Back",
    buttonOk: "Ok"
  },
  completeOverlay: {
    header: "Service provided",
    content:
      "That’s great! Please note, once you’ve saved this status you cannot amend it.",
    buttonOk: "Ok",
    buttonCancel: "Back"
  },
  notUpdatedOverlay: {
    header: "Set the status appointment before leaving",
    buttonOk: "Set status",
    buttonCancel: "Set later"
  },
  confirmByPasswordOverlay: {
    header: "Remove",
    label: "Your password",
    passwordError: "Incorrect password. Please check password and try again.",
    placeholder: "Enter the ",
    placeholder2: " name",
    content: [
      "In order to remove ",
      " please enter the exact name of the ",
      " to continue"
    ],
    buttonDoNotRemove: "Go back",
    buttonRemove: "Remove",
    lastAttemptWarning:
      "You have one attempt left before your account will be locked.",
    wrongPasswordWarning:
      "Sorry, you entered the wrong password. Please check your details and try again.",
    lockedWarning: "This account has been locked due to too many failed password attempts. An email has been sent with instructions on how to reset your password.",
    validationError: "Please enter the exact name of the"
  },
  changeYourPasswordOverlay: {
    header: "Change your password",
    content:
      "To update your password, please enter your current password and a then create a new one in the fields below.",
    labelPassword: "Current password",
    passwordEmpty: "Please enter your current password.",
    labelNewPassword: "New password",
    newPasswordEmpty: "Please enter a new password.",
    newPasswordError:[
      "Your password must:",
      "be atleast 12 characters long",
      "contain atleast 1 lower-case letter",
      "contain atleast 1 uppercase letter",
      "contain atleast 1 number",
      "contain atleast one of the following supported symbols: ! # $ % £ * - ? @ _ | ^ ~ : .",
      "not contain your email address"
    ],
    labelConfirmNewPassword: "Confirm new password",
    confirmNewPasswordError:
      "The passwords you have entered do not match. Please try again.",
    toggleTextShow: "Show",
    toggleTextHide: "Hide",
    passwordMismatch: "Incorrect current password."
  },
  confirmationOverlayCommon: {
    header: "Confirmation",
    content: "Are you sure?",
    buttonConfirm: "Confirm",
    buttonCancel: "Cancel"
  },
  confirmationDeleteUser: {
    header: "Remove user",
    content: {
      admin: "Are you sure you want to remove this user from Patient Access for Professionals? Please note, if you have any calendars set up for this user at any branches, you must go to each Branch > Calendars and remove these calendars.",
      orgAdmin: "Are you sure you want to remove this user from this organisation? Please note, if you have calendars set up for this user at any branches, you must go to each Branch > Calendars and remove these calendars.",
      other: "Are you sure you want to remove this user from this branch? Note: If you have a calendar set up for this user, you must go to 'Calendars' and remove their calendar."
    },
    buttonConfirm: "Remove User",
    buttonCancel: "Cancel"
  },
  odsDuplicateModal: {
    header: "Branch ods code duplicate error",
    button: "Back"
  },
  roomDuplicateModal: {
    header: "Room name duplicate error",
    content: "Please provide unique room name.",
    button: "Back",
  },
  roomEmptyNameModal: {
    header: "Room empty name error",
    content: "Please provide name for the room.",
    button: "Back",
  },
  updateAvailabilityConflictOverlay: {
    header: "Branch availability can't be saved.",
    body: [
      "You have",
      "appointment",
      "appointments",
      "booked at times which your branch is no longer available for. Please cancel these appointments and try again.",
    ],
    buttonOk: "Back"
  },
  addTodayAsBlockedPeriod: {
    header: "Calendar can't be saved.",
    content: "Our system won’t allow you to set today as a closed day. To do this, set your minimum booking cutoff at 24 hours (or any other value which covers today). Remember to cancel any appointments for today that you cannot make. Later today/tomorrow, don’t forget to set your minimum booking cutoff back to your normal settings.",
    buttonOk: "Ok"
  },
  updateCalendarAvailabilityConflictOverlay: {
    header: "Calendar details can't be saved.",
    body: [
      "You have",
      " appointment",
      " appointments",
      " booked at times which your calendar is no longer able to support. Please reassign these appointments to another calendar, and cancel any which cannot be reassigned and try again."
    ],
    buttonOk: "Back"
  },
  confirmationDeleteBlockedOverlay: {
    header: "Remove blocked period",
    content: "Are you sure you'd like to remove this blocked time?",
    buttonConfirm: "Continue",
    buttonCancel: "Cancel"
  },
  ViewServiceDescriptionOverlay: {
    buttonCancel: "Cancel"
  },
  RefundCustomerOverlay:{
    header: "Refund Customer",
    buttonConfirm: "Confirm Refund",
    buttonCancel:"Go back",
    inputRefundType: "Refund Type",
    inputReasonForRefund: "Reason For Refund",
    refundSelect: "Select an option"
  }
};

const AppointmentSlots = {
  header: "Available time slots for:",
  accessibilitySpan: "Back",
  duration: "min. available time-slots",
  typePlaceholder: "Choose type",
  calendarPlaceholder: "Choose calendar",
  morning: "Morning",
  afternoon: "Afternoon",
  noSlotsWarning: "No available time slots for the selected service",
  noSlotsForServiceNotMappedWithCalendarWarning: "No available time slots for the selected service , please make sure the selected service is mapped to a calendar",
  noSlotsForFutureCalendarWarning: "No available time slots for the selected service, calendar availability is set to a future date",
  manageAvailabilityBtn: "Manage Availability",
  customAppointmentFilter:
  {
    calendar: 'Calendar',
    placeholderCalendar: 'Select a calendar',
    duration: 'Appointment duration',
    placeholderDuration: 'Select a duration',
    noCalendarSelectionWarning: "Select a calendar to see available time slots"
  },
  internalEventFilter:
  {
    duration: 'Event duration'
  }
};

const BookAppointment = {
  header: "New Appointment",
  closeIcon: "Close",
  appointmentTypeHeader: "Appointment type",
  appointmentTypePlaceholder: "Select an appointment type",
  appointmentTypeError: "Appointment type is required",
  customerDetailsHeader: "Customer Details",
  nameInput: "First Name",
  postcodeInput: "Postcode (Optional)",
  DOBInput: "Date of birth (Optional)",
  nameError: "First name is required",
  validNameError: "Enter a valid first name",
  familyNameInput: "Last Name",
  familyNameError:
    "Last name is required",
  validFamilyNameError: "Enter a valid last name",
  phoneNumber: "Mobile/Phone number (recommended)",
  phoneNumberMandatory: "Phone number",
  DOBError: "Please enter correct date of birth",
  phoneNumberError: "Please enter a valid phone number",
  phoneNumberEmptyError: "Phone number is required",
  postcodeError: "Please enter a valid postcode",
  email: "E-mail address (recommended)",
  emailMandatory: "E-mail address",
  emailError: "Please enter a valid email address",
  emailEmptyError: "Email address is required",
  servicePrice: "Service price:",
  bookButtonText: "Book Appointment",
  cancelButtonText: "Cancel",
  emailAndPasswordError:
    "Please provide the customer's mobile number and/or email address",
  firstNameAndLastNameError:
    "Please provide customer's first and last name",
  alreadyBookedSlotModalContent: "The time-slot you have chosen has just been booked by someone else. Please choose another time-slot and try again",
  expiredSlotModalContent: "As you have not completed this booking after 10 minutes, your held time-slot was released. Please reselect a time slot.",
  takenSlotModalContent: "The time-slot you have chosen has just been booked by someone else. Please choose another time-slot and try again.",
  expiredSlotModalHeader: "Reselect a time slot",
  expiredSlotModalButton: "Close",
  customAppointmentHeader: "Custom appointment details",
  dateTimeLabel: "Date and time",
  title: "Appointment title*",
  servicePriceWithSymbol: "Service price: £*",
  servicePriceError: "Please enter a valid service price",
  titleRequired: "Appointment title is required",
  appointmentReason: "Appointment reason",
  appointmentReasonNotProvided: "Not Provided",
  reasonError: "Appointment reason in required"
};

const AddInternalEvent = {
  header: "Internal event details",
  dateTimeLabel: "Date and time",
  title: "Event title",
  createButtonText: "Create Event",
  cancelButtonText: "Cancel",
  closeIcon: "Close",
  titleRequired: "Event title is required"
}

const InternalEventDetails = {
  header: "Internal event details",
  dateTimeLabel: "Date and time",
  title: "Event title",
  createButtonText: "Create Event",
  cancelButtonText: "Cancel",
  closeIcon: "Close",
  titleRequired: "Event title is required",
  calendarLabel: "Calendar",
  warningModalHeader: "Warning",
  warningModalBack: "Back",
  warningModalContinue: "Remove event",
  warningContent: "Are you sure you want to remove the internal event?",
}

const UsersList = {
  usersListColsHeaders: [
    "Name",
    "Role",
    "Organisation",
    "Branch",
    "Email",
    "2fa status"
  ],
  viewDetailsButtonText: "View details",
  emptyList: "Sorry, no users found.",
  usersListFilters: [
    "All users",
    "Super Admins",
    "Organisation Admins",
    "Branch Admins",
    "Branch Members"
  ],
  addUserButton: "Add user",
  searchMessage: "Search by name, organisation, branch or email address",
  searchMessageXS: "Search...",
  mfaStatus: ["Not Enabled", "Enabled"]
};

const OrganisationsList = {
  organisationsListColsHeaders: [
    "Organisation name",
    "Company Number",
    "Registered address",
    "Branches",
    "City",
    ""
  ],
  viewDetailsButtonText: "View Details",
  emptyList: "Start by creating an organisation",
  emptySearchList: "Sorry, no organisations found.",
  addOrganisationButton: "Add organisation",
  addOrganisationButtonGrid: "Add an organisation",
  searchMessage:
    "Search by organisation name, company number, city or postcode",
  searchMessageXS: "Search..."
};

const BranchesList = {
  branchesListColsHeaders: [
    "Branch name",
    "ODS Code",
    "Users",
    "Branch address",
    "City",
    ""
  ],
  viewDetailsButtonText: "View Details",
  emptyList: "This organisation doesn’t have any branches",
  emptySearchList: "Sorry, no branches found.",
  addBranchButton: "Add branch",
  addBranchButtonGrid: "Add branch",
  searchMessage: "Search by branch name, ODS code or postcode",
  searchMessageXS: "Search..."
};

const DefaultServices = {
  emptyList: "Sorry, no default services available",
  emptySearchList: "Sorry, no default services found.",
  servicesTableHeaders: {
    alert: { label: "Alert", width: "100px" },
    name: { label: "Service name", width: "500px" },
    defaultService: { label: "Default service", width: "180px" },
    modes: { label: "Available delivery mode", width: "250px" },
    edit: { edit: "Edit", done: "Done", width: "90px" },
  },
  searchFilter: {
    message: "Search for a service",
    messageXS: "Search..."
  },
  checkboxValue: "Select all default services"

};

const EnabledServices = {
emptyList: "Sorry, no enabled services available",
enabledServicesTableHeaders: {
    name: { label: "Service name ", width: '48%' },
    enabledService: { label: "Enabled service", width: '20%' },
    modes: { label: "Enabled appointment types", width: "25%" },
    edit: { edit: "Edit", done: "Done", width: 'auto' },
  },
  checkboxValue: "Select All Services"
};

const CalendarsList = {
  calendarsListColsHeaders: [
    "Calendar name",
    "Branch",
    "Start Date",
    "End Date",
    "Nº of services",
    ""
  ],
  viewDetailsButtonText: "View Details",
  addCalendarButton: "Add new calendar",
  searchMessage: "Search for a calendar name",
  emptyList: "This organisation doesn’t have any calendars",
  filtersEmpty: "We couldn't find any result for your search.",
  calendarNotification: "Services are currently being edited in this organisation, while this action is taking place those branch calendars will not be editable.",
};

const UserDetails = {
  changePasswordTitle: "Change password",
  changePasswordContent:
    "In order to change a password you will need to provide your current password.",
  changePasswordButton: "Change password",
  resetPasswordTitle: "Reset password",
  resetPasswordContent:
    "By selecting the reset password button, an email will be sent to this user with password reset instructions.",
  resetPasswordButton: "Reset password",
  userDetailsButtonDelete: "Delete",
  userDetailsButtonClose: "Close",
  userDetailsButtonDiscard: "Discard changes",
  userDetailsButtonSave: "Save changes",
  resetPasswordSnackbar: "A password reset email has been sent to the user.",
  resetPasswordSnackbarError:
    "An internal error occured while trying to send a reset password email to the user",
  userDetailsCloseIcon: "Close",
  editRolesButtons: ["Done", "Edit"],
  userRoleDescription: "",
  addRoleButton: "+ Add new role",
  userDetailsTitle: "User details",
  userDetailsDescription: "",
  nameInput: "Name",
  nameInputError: "Please enter your name.",
  email: "Associated e-mail address",
  roleLabel: "Role",
  roleSelect: "Select role",
  organisationLabel: "Organisation",
  organisationSelect: "Select organisation",
  branchLabel: "Branch",
  branchNoName: "n/a",
  branchSelect: "Select Branch",
  deleteRoleIcon: "Remove User Role",
  roleConflictButton: "Back",
  roleConflictBody: "This user has an existing role within your organisation or branch that has conflicting permissions. \n" +
    "\n" +
    "Please remove their existing role with the 'X' button and then click 'Done' followed by 'Save'",
  roleConflictTitle: "Something's not quite right",
  notAvailableDescription: "n/a",
  multipleDescription: "Multiple",
  allUserDescription: "All",
  deletedDescription: "None",
  multipleRolesText: " roles",
  securityTitle: "Security",
  twoFactorAuthTitle: "Two-factor authentication",
  twoFactorEnabled: "Two-factor authentication enabled",
  twoFactorNotEnabled: "Two-factor authentication not enabled",
  twoFactorRemoveButton: "Remove",
  twoFactorRemoveOverlay: {
    add_title: "Removing two-factor authentication from this user",
    add_desc: "After removing two-factor authentication, this user will be asked to add it again when signing in if their organisation requires it.",
    save: "Remove two-factor authentication",
    back: "Go back"

  }
};

const AddUser = {
  addUserTitle: "Add user",
  addUserCloseIcon: "Close",
  userRoleTitle: "User role",
  userRoleDescription:
    "Select a role below to view the access rights it provides. You can add multiple roles for each user.",
  userDetailsDescription: "",
  roleDescriptionTitle: "This role can",
  nameLabel: "Name",
  nameInput: "Name",
  nameError: "Please enter a name for the new user.",
  emailLabel: "Email address",
  emailInput: "Email address",
  emailError: "Please enter correct email",
  addUserButtonCancel: "Cancel",
  addUserButtonDiscard: "Discard changes",
  addUserButtonSave: "Create User",
  roleLabel: "Role",
  roleSelect: "Select role",
  roleError: "Please select a role",
  organisationLabel: "Organisation",
  organisationSelect: "Select organisation",
  branchLabel: "Branch",
  branchSelect: "Select Branch",
  roles: [
    {
      name: "SuperAdmin",
      actions: [
        { title: "Manage all organisations, branches and users" },
        { title: "Manage organisations verification (coming soon) and payout method." },
        { title: "Manage branch calendars, rooms and appointments" },
        { title: "Manage branch services, prices & appointment durations" }
      ],
      description: "This is the most senior role in Patient Access for Professionals. It is intended for PPL’s internal support team and account managers."
    },
    {
      name: "OrganizationAdmin",
      actions: [
        { title: "Manage your organisation, branches and users" },
        { title: "Manage your organisation payout settings" },
        { title: "Manage your branches calendars, rooms and appointments" },
        { title: "Manage your branches services and prices" }
      ],
      description: ""
    },
    {
      name: "BranchAdmin",
      actions: [
        { title: "Manage your branches opening times, calendars & rooms" },
        { title: "Manage your branches services and prices" },
        { title: "Manage appointments at your branch" },
        { title: "Manage your branch admins and branch users" },
      ],
      description: ""
    },
    {
      name: "BranchMember",
      actions: [
        { title: "Manage appointments at your branch" },
        { title: "View your branch settings; rooms, services, prices, availability" },
      ],
      description: ""
    }
  ]
};

const AddOrganisation = {
  addOrganisationTitle: "Add organisation",
  addOrganisationSearchHint:
    "Search Companies House registered companies to populate the organisations details",
  addSoldeTranderHint: "Enter the sole trader's details below",
  addRegisteredCompanyLink: "search for a registered company from companies house",
  addSoleTraderLink: "enter the details of a sole trader manually",
  addOrganisationSearchPlaceholder: "Search by registered company number or name",
  addOrganisationSearchEmpty: "No results found",
  addOrganisationCloseIcon: "Close modal",
  addOrganisationButtonCancel: "Cancel",
  addOrganisationButtonCreate: "Create Organisation",
  companyInfo: {
    nameTitle: "Company name",
    numberTitle: "Company number",
    addressTitle: "Registered office address",
    addressText: "This address will be used for all communication with Patient Access for Professionals. If you would like to use a different address, please update the organisation address in your organisation settings once this organisation has been set up.",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    addressCountry: "Country",
    addressPostcode: "Postcode",
    addressTown: "City",
    nameError: 'Please provide the name of the organisation.',
    addressError: 'Please provide the organisation address.',
    townError: 'Please provide city.',
    postcodeError: 'Please provide a valid postcode.',
    countryError: 'Please provide country.'
  },
  duplicateErrorTitle: "Duplicate organisation found",
  duplicateErrorMessageLine1: "The company you have selected has already been registered in Patient Access for Professionals.",
  duplicateErrorMessageLine2: "Please contact our support team at professionals@patientaccess.com",
  duplicateErrorMessageLink: "Patient Access for Professionals support",
  duplicateErrorButton: "Go back",
  type:
  {
    title: "Organisation configuration type",
    placeHolder: "Organisation type",
    hint: "The organisation type defines what features are available to this organisation. This setting may be changed later.",
    selectError: "Please select organisation type"
  },
  twoFactorAuth: {
    title: "Two-factor authentication",
    placeHolder: "Select a 2fa requirement",
    hint: "Select whether two-factor authentication is required for this organisation or not.",
    selectError: "Please select a 2fa requirement."
  },
  twoFactorConfirmOverlay: {
    add_title: "Adding two-factor authentication to this organisation",
    add_desc: "Users in this organisation will be asked to add two-factor authentication to their account when they next sign in.",
    remove_title: "Removing two-factor authentication from this organisation",
    remove_desc: "Users in this organisation will not be asked to add two-factor authentication to their account.",
    save: "Save changes",
    back: "Go back"
  },
  bookingUrl:
  {
    title: "Organisation booking URL (optional)",
    placeHolder: "www.example.com",
    hint: "This URL defines where a customer will be directed to when they try to book an appointment in Patient Access. This URL should point to the organisation's booking page."
  }
};

const AddBranch = {
  title: "Add branch",
  searchPlaceholder: "Search for your pharmacy by ODS code, branch name, or branch postcode",
  searchEmpty: "No results found.",
  searchODSHint: "Don’t know your ODS Code? Find it out here: https://odsportal.hscic.gov.uk/Organisation/Search",
  closeIcon: "Close",
  buttonCancel: "Cancel",
  buttonCreateLater: "Create & setup later",
  buttonCreate: "Create & setup",
  informationTitle: "Branch information",
  informationText: "This information will be shown to customers when they book online. If your branch is a pharmacy, you can search below and select it to prefill some of your branch information from NHS Choices.",
  infoNameLabel: "Branch name",
  infoDescriptionLabel: "Branch description (optional)",
  infoOdsLabel: "ODS code (if applicable)",
  infoTelephoneLabel: "Telephone (optional)",
  infoEmailLabel: "Email (optional)",
  nameError: "Please provide the name of the branch.",
  duplicateNameError: "A branch with this name already exists. Please use a different name.",
  descriptionError: "Please remove the following characters to continue:",
  odsError: "Please provide a valid ODS Code.",
  addressError: "Please provide the branch address.",
  townError: "Please provide City.",
  postcodeError: "Please provide a valid postcode.",
  phoneError: "Please check your entry and try again.",
  emailError: "Invalid email address. Please check your email and try again.",
  addressTitle: "Branch address",
  addressText: "The branch address will be displayed to online customers when they book. Please ensure that this information is up to date, so that your customers come to the right location for their service.",
  addressLine1Label: "Address line 1",
  addressLine2Label: "Address line 2 (optional)",
  addressLine2VirtualLabel: "Address line 2",
  townLabel: "City",
  postcodeLabel: "Postcode",
  duplicateErrorTitle: "Duplicate branch found",
  duplicateOdsErrorTitle: "Duplicate ODS code found",
  duplicateOdsErrorMessage: "The branch with ODS code you have selected has already been registered in Patient Care. Please contact our support team",
  duplicateErrorMessageLine1: "This branch already exists in Patient Access for Professionals, please check that you have entered the correct details and try again.",
  duplicateErrorMessageLine2: "If you believe there has been a mistake, and this branch belongs to your organisation and isn’t yet registered with your organisation, please contact support to report this at professionals@patientaccess.com",
  duplicateErrorMessageLink: "Patient Access for Professionals support",
  duplicateErrorButton: "Go back",
  selectOrganisationTitle: "Select an organisation",
  selectOrganisationText: "Select the organisation that this branch will be part of.",
  selectOrganisationPlaceholder: "Select organisation",
  selectOrganisationError: "Please select an organisation",
  typeTitle: "Select a branch type",
  typeText: "The branch type will determine what service can be made available and what additional branch configuration is required.",
  type: {
    physical: { label: "Physical branch", value: "physical" },
    online: { label: "Online branch", value: "online" }
  },
  infoNamePlaceholder: "Enter a branch name",
  infoDescriptionPlaceholder: "Enter a branch description",
  infoOdsPlaceholder: "Enter the branch ODS code",
  infoTelephonePlaceholder: "Enter the branch phone number",
  infoEmailPlaceholder: "Enter the branch email address",
  tooltip: {
    physical: "A branch which can offer all appointment types from a single location.",
    online: 'A branch which can only offer remote appointments nationwide and it is not at a specific location.',
    onlineDisabled: "You have reached the maximum of 1 online branch which can be created."
  },
  onlineBranchOverlay: {
    heading: "Adding an online branch",
    bodyLine1: "Your online branch will only offer video and telephone services to Patient Access users.",
    bodyLine2: "These services will be made available nationally. The address, postcode, and contact details of your online branch will not be displayed to Patient Access users.",
    buttonBack: "Go back",
    buttonConfirm: "Confirm and continue",
  }
};

const Snackbar = {
  bookAppointmetSuccess: "Appointment successfully booked",
  changesUpdated: "Changes updated successfully",
  userCreated: "User successfully created",
  userCreatedError: "Error occurred when trying to create new user.",
  cancelAppointmentSuccess: "Appointment cancelled",
  cancelAppointmentError: "Error occurred when trying to cancel appointment",
  missAppointmentSuccess: "Appointment status set as missed",
  missAppointmentError: "Error occurred when trying to set the appointment status as missed",
  updateAppointmentSuccess: "Patient data updated",
  updateAppointmentError: "Error occurred when trying to update patient data",
  completeAppointmentSuccess: "Appointment completed",
  completeAppointmentError:
    "Error occurred when trying to complete appointment",
  createOrganisation: "Organisation has been successfully created",
  createBranch: "Branch has been successfully created",
  organisationDeleted: "Organisation deleted successfully",
  organisationNotDeleted: "Error occurred when trying to delete organisation",
  branchUpdated: "Branch updated successfully",
  branchNotUpdated: "Error occurred when trying to update branch",
  branchServiceHasAppointments: "Error occurred while deleting service. Appointments are booked for this service.",
  branchDeleted: "Branch deleted successfully",
  branchNotDeleted: "Error occurred when trying to delete branch",
  calendarDeleted: "Calendar deleted successfully",
  calendarNotDeleted: "Error occurred when trying to delete calendar",
  calendarUpdated: "Calendar updated successfully",
  calendarNotUpdated: "Error occurred when trying to update calendar",
  branchServiceListUpdated: "Branch service list updated successfully",
  defaultServiceListUpdated: "Default service list updated successfully",
  defaultServiceListNotUpdated: "Error occurred when trying to update default service list",
  enabledServiceListUpdated: "Enabled service list updated successfully",
  enabledServiceListNotUpdated: "Error occurred when trying to update enabled service list",
  roomUpdated: "Rooms updated successfully",
  roomNotUpdated: "Error occurred when trying to update rooms",
  roomsLoadingError: "Error occurred when trying to load rooms",
  calendarCreated: "New calendar successfully created",
  calendarNotCreated: "Error occurred when trying to create calendar",
  passwordUpdated: "Password updated",
  passwordNotUpdated: "Password not updated",
  payoutSettingsUpdated: "Payout settings updated successfully",
  organisationSettingsUpdated: "Organisation settings has been successfully updated.",
  internalEventCreated: "New internal event successfully created",
  internalEventNotCreated: "Error occurred when trying to create an internal event",
  internalEventDeleted: "Internal event deleted successfully",
  internalEventNotDeleted: "Error occurred when trying to delete an internal event",
  refundSuccess: "Refunded Successfully",
  refundError: "Error occurred when trying to refund for the appointment",
  availabilitySettingsUpdated: "Availability settings updated successfully",
  availabilitySettingsError: "Error occurred when trying to update availability settings",
  organisationDetailsUpdated: "Organisation details updated successfully",
  organisationDetailsUpdateError: "Error occured when updating Organisation details",
  availabilityRulesUpdated: "Availability rules updated successfully",
  availabilityRulesError: "Error occurred when trying to update the Availability rules",
  organisationSupportedFeaturesUpdated: "Supported Features updated successfully",
  organisationSupportedFeaturesError: "Error occurred when trying to update the Supported Features",
  organisationLogoRemoved: "Organisation logo removed",
  serviceAdded: (branches) => `Service added to ${branches} branches successfully`,
  serviceAddError: "Error occurred when trying to add the service",
  serviceDeleted: "Service deleted successfully", 
  serviceDeleteError: "Error occurred when trying to delete the service",
  serviceUpdated: (branches) => `Service edited at ${branches} branches successfully`, 
  serviceUpdateError: "Error occurred when trying to update the service",
  itemsAddedToTasksSuccess: "Item added to Tasks successfully",
  itemsAddedToTasksError: "An error occurred when adding to Tasks, please try again.",
  branchAddService: (serviceName) => `${serviceName} added successfully`,
  branchUpdateService: (serviceName) => `${serviceName} updated successfully`,
  branchRemoveService: (serviceName) => `${serviceName} removed successfully`,
};

const Sidebars = {
  users: "Users",
  rooms: "Rooms",
  services: "Services",
  calendars: "Calendars",
  availability: "Availability settings",
  branchSettings: "Branch settings",
  branchDetails: "Branch details",
  organisationDetails: "Organisation details",
  organisationSettings: "Organisation settings",
  organisationPanel: "Admin Panel",
  superAdminPanel: "Admin Panel",
  branches: "Branches",
  organisations: "Organisations",
  clinicianCalendars: "Clinician calendars",
  noCalendars: "You haven’t created any calendars for your clinicians.",
  createCalendars: "Create calendars",
  branchServices: "Branch services",
  noBranches: "You haven't added any services to your branch.",
  addServices: "Add services",
  addNewTo: "#add",
  addNewAccessibilitySpan: "Add new",
  settingsTo: "#settings",
  settingsAccessibilitySpan: "Settings",
  payoutDetails: "Payout Details",
  defaultServices: "Default services",
  enabledServices: "Enabled services",
  availabilityRules: "Availability rules",
  smartPharmacy: "Smart Pharmacy",
  smartPharmacyRegister: "Smart Pharmacy",
  smartPharmacyRegisterBefore: "NEW",
  scrollTop: 'Scroll to top',
};

const OrganisationsSettings = {
  details: "Company name & number",
  detailsDescription: "",
  companyName: "Company name",
  companyNumber: "Company number",
  registeredAddress: "Registered office address",
  addressDescription: "Patient Access for Professionals will use this address for all official correspondence with your organisation.",
  removeOrganisation: "Remove organisation",
  removeDescription1: "You may remove this organisation from the system. Removing this organisation will remove all associated branches. After removal it will not be possible to recover this organisation, so please proceed with caution.",
  removeDescription2: "In order to remove this organisation you will be required to enter your password.",
  addressLine1: "Address line 1",
  addressLine2: "Address line 2",
  city: "City",
  postcode: "Postcode",
  country: "Country",
  type:
  {
    title: "Organisation type",
    placeHolder: "Organisation type",
    hint: "The configuration type defines what features are available to this organisation."
  },
  twoFactorAuthentication:
  {
    title: "Two-factor authentication",
    hint: "Determine whether two-factor authentication is required or not for users in this organisation.",
  },
  bookingUrl:
  {
    title: "Organisation booking URL (optional)",
    title1: "Enter the booking URL (optional)",
    placeHolder: "www.example.com",
    hint1: "This URL defines where a customer will be directed to when they try to book an appointment at this branch in Patient Access. This URL should point to your organisation's booking page.",
    hint2: "Your branches will not be shown online unless a booking URL is provided.",
    hint4: "This URL defines where a customer will be directed to when they try to book an appointment for this service in Patient Access. This URL should point to your organisation's booking page.",
    invalidError: "Please provide a valid URL.",
    notProvided: "You haven't entered a booking URL yet",
    hint3: "This service will not be shown online unless a booking URL is provided.",
    placeHolder1: "Enter a booking URL",
    invalidError1: "Enter a valid booking URL",
  },
  confirmationWithoutBookingURLHeader: "Removing your organisation's booking URL",
  confirmationWithoutBookingURL: "By removing your booking URL, your branches will cease to be visible in Patient Access.",
  confirmationWithoutBookingURLCancelbtn: "Go back",
  confirmationWithoutBookingURLConfirm: "Confirm booking URL removal",
  organisationDetails:
  {
    buttonDone: "Done",
    buttonEdit: "Edit"
  },
  organisationNameError: "Please provide your organisation name.",
  organisationAddressError: "Please provide your organisation address.",
  organisationCityError: "Please provide city.",
  organisationPostcodeError: "Please provide a valid postcode.",
  organisationCountryError: "Please provide country.",
  organisationLogoTitle: "Company logo",
  organistionLogoDescription: {
    line1: "The company logo will be displayed as part of this organisation’s branch profiles in Patient Access.",
    line2: "The logo should be a square png or jpg between 400 and 800px."
  },
  RemoveLogoOverlay: {
    header: "Removing this organisation's logo",
    content: "After removing this organisation's logo, it will no longer be visible in their branch profiles in Patient Access.",
    confirm: "Remove logo",
    discard: "Go back",
  },
  OrganisationFeaturedServicesOverlay: {
    title: "Updating Smart Pharmacy details",
    header: (branches) => `Updating Smart Pharmacy details for ${branches} branches`,
    contentFeaturedServices: 'By continuing, any featured services already configured at your branches will be overwritten.',
    contentSmartProfile: 'The Smart Profile will be visible in Patient Access to every customer who nominated your smart pharmacy branch. It contains your branch details, opening times and available services in Patient Access for Professionals. Please make sure your information is up to date.',
    contentBoth: 'By continuing , both Smart Profile visibility and Featured services for your branches will be updated.',
    confirm: "Save changes",
    discard: "Go back",
  },
  organisationFeaturedServices: {
    title: "Featured services",
    description: "You can select available services at your pharmacies to be featured on their Smart Profile pages. These will be displayed to customers at the top of the service list in the order you set here.",
    noServices: "No services have been added to your organisation",
    noFeaturedServices: "No services featured",
    allowBranchControlText: "Allow branch admins to control their featured services",
  },
  smartPharmacySection: {
    header: "Smart Profile visibility",
    description: "Configure whether Patient Access users can view the Smart Profiles for all branches within your organisation.",
    labelHidden: "All Smart Profiles are hidden in Patient Access",
    labelVisible: "All Smart Profiles are visible in Patient Access",
  },
  smartPharmacyOverlay: {
    headerHidden: (number) => `Hiding Smart Profiles for ${number} branches`,
    headerVisible: (number) => `Making Smart Profiles visible for ${number} branches`,
    contentHidden: "Patient Access users will no longer be able to see your organisation's Smart Profiles.",
    contentVisible: "Please ensure that your branches Smart Profile information is up to date.",
    buttonCancel: "Go back",
    buttonConfirm: "Save changes"
  }
};

const AvailabilitySettings = {
  openingHours: "Opening hours",
  hoursDescription: "Opening times will be displayed to online customers. Your customers will only be able to book appointments in your opening times. Please note, if you edit these opening times, these changes will not be reflected on other systems such as NHS choices.",
  daysOfWeek: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  open: "Open",
  closed: "Closed",
  closedDays: "Closed days",
  closedDaysDescription:
    "If there are days which your branch will be which fall outside the branches normal opening hours e.g. Bank Holidays you can block them here. Customers will not be able to book online for days that are closed.",
  addClosedDay: "+ Add closed day",
  newClosedDayPlaceholder: "Select a day",
  bookingCutoff: "Booking Cutoff",
  bookingCutoffDescription: "The time frame the customers can book in advance.",
  labelMinimum: "Minimum",
  labelMaximum: "Maximum",
  placeholderTime: "hh:mm",
  timePeriodValidationError: "Intervals have to be in multiples of 5 minutes",
  equalTimeValidationError: "Start time should not be equal to end time",
  startTimeValidationError: "The end time has to be after the start time",
  addNewTimeInterval: "+ New interval",
  intervalValidationError: "Intervals must not overlap",
  calendarIntervalValidationError: "Intervals must be within branch opening hours",
  closedDaysValidationError: "Past date provided for a closed day.",
  timeOff: "Time off between appointments",
  timeOffDescription: "This is the window after every booked appointment that cannot be booked either by customers or staff. ",
  timeOffDescription2: "This is controlled by your organisation.",
  timeOffAdd: "Add time off between appointments",
  timeOffReadOnly: {
    "opening": "There",
    "is" : "is",
    "are" : "are",
    "minute" : "minute",
    "minutes" : "minutes",
    "closing" : "off between all appointments."
  },
  timeOffSelectDefault: "Select a value"
};

const BranchSettings = {
  details: "Branch contact details",
  detailsDescription: "These are the contact details that customers will see when they view your branch and book online.",
  companyName: "Branch name",
  companyDescription: "Branch description (optional)",
  branchDescription: "The branch description is shown to customers when they view this branch online. Here you can talk about the branch and services offered in more detail.",
  descriptionNotProvided : "You haven't entered a description yet",
  descriptionPlaceHolder: "Enter a branch description",
  branchODSCode: "Branch ODS code",
  ODSCode: "ODS code (if applicable)",
  phone: "Telephone (optional)",
  phonePlaceHolder: "Telephone",
  email: "Email address (optional)",
  emailPlaceHolder: "Email address",
  registeredAddress: "Branch address",
  addressDescription: "The branch address is displayed to customers when they view this branch online. Please ensure that this information is correct so that customers can easily locate the branch.",
  removeBranch: "Remove branch",
  removeDescription1: "You may remove this branch from the system. After removal it will not be possible to recover this branch, please proceed with caution.",
  removeDescription2: "In order to remove this branch you will be required to enter your password.",
  addressLine1: "Address line 1",
  addressLine2: "Address line 2",
  city: "City",
  postcode: "Postcode",
  country: "Country",
  emptyBranchAddress: "Please provide your Branch Address.",
  emptyBranchName: "Please provide your Branch Name.",
  emptyBranchCode: "Please provide your ODS Code.",
  postcodeError: "Please provide a valid Postcode.",
  townError: "Please enter a City.",
  incorrectBranchEmail: "Invalid email address. Please check your email and try again.",
  branchVisibility: "Branch visibility",
  visibilityDescription: "You may set this branch as visible or invisible to customers browsing online. Invisible branches cannot be seen and no appointments may be booked by customers.",
  publishButton: "Visible",
  publishDescription: "can be seen by customers online",
  unPublishDescription: "cannot be seen by customers online",
  unpublishButton: "Invisible",
  notProvided: "Not provided",
  typeTitle: "Branch Type",
  typeHint: "The branch type determines what service can be made available and what additional branch configuration is required",
  type: {
    physical: { label: "Physical branch", value: 0 },
    online: { label: "Online branch", value: 1 }
  },
  companyLogoHeader: "Company Logo",
  companyLogoDescription: "The company logo will be displayed as part of this organisation’s branch profiles in Patient Access.",
  smartProfileHeader: "Smart Profile visibility",
  smartProfileCopy: "The Smart Profile is visible in Patient Access to every customer who nominated your pharmacy branch. It contains your branch details, opening times and available services in Patient Access for Professionals. Please make sure your information is up to date.",
  smartProfileOverlay: {
    header: "Make visible without branch description?",
    copy: "You haven’t added any branch description to your branch. We recommend you to fill the branch description section before making your Smart Profile visible to help your profile look better at your customer’s eyes.",
    buttonContinue: "Make visible now",
    buttonEditDescription: "Add description first"
  },
  smartPharmacy: {
    header: 'Smart Pharmacy',
    description: 'Configure whether this branch is a Smart Pharmacy.',
    labelDisabled: 'Disabled',
    labelEnabled: 'Enabled',
    lastDisabled: 'Last disabled on',
    lastEnabled: 'Last enabled on',
    neverEnabled: 'Never enabled',
  },
  smartPharmacyOverlay: {
    headerEnabled: 'Enabling Smart Pharmacy at this branch',
    headerDisabled: 'Disabling Smart Pharmacy at this branch',
    descriptionEnabled: 'By continuing, this branch and any Patient Access users it nominated will gain access to Smart Pharmacy features.',
    descriptionDisabled: 'By continuing, this branch and any Patient Access users with it nominated will lose access to Smart Pharmacy features.',
    buttonBack: 'Go back',
    buttonEnabled: 'Enable Smart Pharmacy',
    buttonDisabled: 'Disable Smart Pharmacy',
  },
  featuredServices : {
    header: "Featured services",
    description: "You can select services available at your pharmacy to be featured in your Smart Profile page. These will be displayed to customers at the top of the service list in the order you set here.",
    noServices: "No services have been added to your branch",
    noFeaturedServices: "No services featured",
    addFeaturedServices: "Add a featured service",
    addFeaturedServicesPlaceholder: "Select a service to feature",
  },
};

const BranchServices = {
  colsHeaders: [
    { text: "Service", style: { width: '30%', flex: 1 } },
    { text: "Price (£)", style: { width: '156px' } },
    { text: "VAT included", style: { width: '156px' } },
    { text: "Duration (mins)", style: { width: '156px'} },
    { text: "Min. booking cutoff", style: { width: '156px' } },
    { text: "Service availability", style: { width: '156px' } },
    { text: "Edit", style: { width: '80px', textAlign: "right" } }
  ],
  colsHeadersForAffiliate: [
    { text: "Service", style: { width: '30%', flex: 1 } },
    { text: "Show Price", style: { width: '156px' } },
    { text: "Price (£)", style: { width: '156px' } },
    { text: "Duration (mins)", style: { width: '156px'} },
    { text: "Service availability", style: { width: '156px' } },
    { text: "Edit", style: { width: '80px', textAlign: "right" } }
  ],
  servicesTableHeaders: {
    name: { label: "Service", width: 'auto' },
    modes: { label: "Delivery mode", width: '200px' },
    showPrice: { label: "Show Price", width: '70px' },
    price: { label: "Price (£)", width: '120px' },
    vatIncl: { label: "VAT included", width: '70px' },
    duration: { label: "Duration (mins)", width: '130px' },
    cutOff: { label: "Booking cutoff", width: '140px' },
    availability: { label: "Availability", width: '130px' },
    edit: { edit: "Edit", done: "Done", width: '80px' },
  },
  pausedTrue: "Paused",
  pausedFalse: "Active",
  emptyList: "This branch has no services",
  nothingToAdd: "There are no services to add for this branch",
  emptyListTitle:
    "If you cannot find the services that you offer, please contact " +
    "professionals@patientaccess.com with your branch name, and the services that you wish to provide on Patient Access for Professionals.",
  emptyListDescription:
    "To promote the services that your branch offers, add your services here.",
  addServiceButton: "Add new services",
  addFirstServiceButton: "Add your branch services",
  confirmationWithoutAppointmentsHeader: "Warning",
  confirmationWithoutAppointments: [
    "Are you sure you'd like to remove the service",
    "?"
  ],
  confirmationWithAppointments: [
    "You have",
    "appointments booked for this service at your branch in the future. Please cancel these appointments and try again."
  ],
  incompletedText: " (incomplete)",
  modes: "modes",
  modesNone: "None",
};

const BranchRooms = {
  colsHeaders: [
    { text: "Room name" },
    { text: "Edit" }
  ],
  emptyList: "This branch has no rooms",
  nothingToAdd: "There are no rooms to add for this branch",
  emptyListTitle: "You haven't added any rooms to the branch",
  emptyListDescription: "You must have at least one room/consultation space in order for customers to book an appointment online.",
  addRoomButton: "Add new room",
  addFirstRoomButton: "Add your first room",
  incompletedText: " (incomplete)",
  newRoomPlaceholder: "Enter the room name",
  removeRoomBtn: "Remove room",
  cancelBtn: "Back",
  confirmationWithoutAppointments: [
    "Are you sure you'd like to remove this room",
    "?"
  ],
  confirmationWithAppointments: [
    "You have",
    "appointment",
    "appointments",
    "assigned to this room. Please cancel or reassign appointments to another room and try again."
  ]
};

const PayoutDetails = {
  payoutMethod: "Payout method",
  payoutDetails: "Payout details",
  payoutMethodDescription1: "’s Stripe ID is ",
  payoutMethodDescription2: ". To view the status of the organisation’s account, or to add/update this organisation’s account details, simply log in to Stripe and search for this Stripe ID.",
  payoutMethodDescription3: "Your appointments can only be booked online when the following steps are completed:",
  payoutMethodDescription4: "This box is ticked by default and in most instances, this will be correct. This means that payments are taken within Patient Access when the appointment is booked.",
  payoutMethodDescription5: "If the commercial team has instructed you that payment should not be taken in Patient Access for this organisation, uncheck this box.",
  payoutMethodLabel: "Take payment online for bookings at ",
  onlinePayment: "onlinePayment",
  payoutStep1: "Bank account details",
  payoutStep2: "Bank account Verification Status",
  payoutStep3: "Stripe’s Service Agreement",
  payoutStep2OrgAdmin: "To add/update payout details and bank account verification information, please contact Jodie Grainger at ",
  paymentVerificationAdmin: {
    paymentVerified: "Stripe has successfully verified this account.",
    paymentUnverified: "Stripe is not able to verify this account right now, either because verification has failed or Stripe does not have enough information to attempt verification.",
    paymentPending: "Stripe is currently trying to verify this account.",
  },
  paymentVerificationOrganisationAdmin: {
    paymentVerified: "Your details have been verified and your organisation is able to receive online payments.",
    paymentUnverified: "We do not have enough information to verify your account details, please contact customer support.",
    paymentPending: "We are currently trying to verify this account.",
  },
  verificationEmail: "jodie.grainger@emisgroupplc.com",
  checkboxAgreement: "agreement",
  stripeServiceLink: "Stripe Service Agreement",
  stripeServiceUrl: "https://stripe.com/gb/connect-account/legal",
  stripeServiceLabel1: " was accepted on behalf of ",
  stripeServiceLabel2: " on ",
  stripeServiceLabel3: " representative has read the ",
  stripeServiceLabel4: " and given me permission to agree to these on behalf of ",
  stripeServiceLabel5: " I have stored a copy of this agreement.",
  bankDetailsProvided: "Provided",
  bankDetailsNotProvided: "Not provided",
  verificationStatusUnverified: "Unverified",
  verificationStatusVerified: "Verified",
  verificationStatusPending: "Pending",
};

const AddNewServices = {
  searchMessage: "Search for services by name",
  cancelButtonText: "Cancel",
  addButtonText: "Add service",
  notAvailableText: "n/a",
  pricePlaceholder: "Price",
  timePlaceholder: "Time",
  pausedPlaceholder: "Availability",
  modesPlaceholder: "Delivery mode",
  bookingCutoffPlaceholder: "Hours",
  addNewServicesTitle: "Add services to your branch",
  addNewServicesCloseIcon: "Close modal",
  addNewServicesDescription: "Please note: To make these services available to be booked online, you should: Step 1: Add the services to your branch. Step 2: Add these services to your calendars.",
  colHeader: "Service name",
  noCutoffText: "No cutoff"
};

const BranchCalendars = {
  colsHeaders: [
    { text: "Calendar name", style: { width: '35%', flex: 1 } },
    { text: "Start date", style: { width: '164px', maxWidth: '164px' } },
    { text: "End date", style: { width: '164px', maxWidth: '164px' } },
    { text: "Nº of services", style: { width: '164px', maxWidth: '164px' } },
    { text: "", style: { width: '112px', maxWidth: '164px', textAlign: "right" } }
  ],
  emptyListTitle: "You haven’t added any calendars to this branch",
  emptyListDescription:
    "Set up a calendar to make services available to be booked online.",
  addCalendarButton: "Add new calendar",
  addFirstCalendarButton: "Add your first calendar",
  incompletedText: " (incomplete)",
  emptyDateText: "None",
  detailsText: "View Details",
  calendarDetails: "Calendar Details",
  warningModalHeader: "Warning",
  warningModalBack: "Back",
  warningModalDismiss: "Back",
  warningModalContinue: "Remove calendar",
  warningBookedAppointments: "The calendar you are trying to remove has appointments associated. Hence, cannot be removed. Alternatively you can set an end date.",
  warningNoBookingAppointments: "Are you sure you want to remove the calendar?",
  calendarDetailsNotification: "This calendar contains a service which is being edited at this branch, while this action is taking place the calendar is not editable.",
  calendarNotification: "Services are currently being edited at this branch, while this action is taking place any calendars with these services added are not editable.",
};

const CalendarPage = {
  previousButtonTo: "#previous",
  nextButtonTo: "#next",
  previousButton: "previous",
  nextButton: "next",
  todayButton: "Today",
};

const AddNewCalendar = {
  availabilityConflictTitle: "Wrong calendar availbility",
  availabilityConflictMessage: "Calendar available periods should be inside open hours of branch",
  backButtonText: "Back",
  cancelButtonText: "Cancel",
  branchTitle: "Associated branch",
  placeholderBranch: "Select a branch",
  addButtonText: "Add a calendar",
  name: "Calendar name",
  title: "Add a new calendar",
  closeIcon: "Close",
  description: "",
  servicesTitle: "Services",
  addBlockedPeriodText: "+ Add blocked time",
  servicesDescription:
    "Select the services you want to make available in this calendar",
  emptyListTitle: "You haven't added any services to the branch.",
  emptyListDescription:
    "You can still create this calendar and add services to it later. Please note that you must add services to an active calendar in order to take online bookings",
  colHeader: "Service name",
  checkboxValue: "serviceName",
  addNewCalendarAvailabilityTitle: "Calendar availability",
  addNewCalendarAvailabilityDescription:
    "Configure when this calendar is available to be booked. Please note, online customers can only book appointments that are available in your calendar and are within the branches opening times.",
  addNewCalendarDurationLabel: "Duration",
  duplicateNameText: "A branch calendar with this name already exists. Please use a different name.",
  emptyNameText: "Please provide a name for this calendar.",
  longNameText: "The name of the calendar can't be longer than 50 characters.",
  maxCalendarNameLength: 50,
  labelStartDate: "Start date",
  labelEndDate: "End date",
  labelTime: "Time",
  labelAllDay: "All day",
  placeholderTime: "hh:mm",
  durationOptions: [
    { value: "continuous", label: "Continuous" },
    { value: "range", label: "Range" }
  ],
  daysOfWeek: [
    { label: "Monday", dayOfWeek: 1, value: { name: "monday", id: 1 } },
    { label: "Tuesday", dayOfWeek: 2, value: { name: "tuesday", id: 2 } },
    { label: "Wednesday", dayOfWeek: 3, value: { name: "wednesday", id: 3 } },
    { label: "Thursday", dayOfWeek: 4, value: { name: "thursday", id: 4 } },
    { label: "Friday", dayOfWeek: 5, value: { name: "friday", id: 5 } },
    { label: "Saturday", dayOfWeek: 6, value: { name: "saturday", id: 6 } },
    { label: "Sunday", dayOfWeek: 0, value: { name: "sunday", id: 7 } }
  ],
  dayAvailableOptions: [
    { value: "available", label: "Available" },
    { value: "unavailable", label: "Unavailable" }
  ],
  blockedTimes: {
    title: "Calendar blocked times",
    description:
      "You may block times in your calendar. This will prevent the calendar from being booked by customers."
  },
  branchClosedDays: {
    title: "Branch closed days",
    description: "Days the branch is marked as closed. There're no available times to be booked for those days.",
    link: "Manage branch closed days"
  }
};

const NotSupported = {
  NotSupportedTitle: "Patient Access for Professionals does not support Internet Explorer 11 or lower.",
  NotSupportedMessage: "Please use one of the browsers options below:",
  chromeText: "Google Chrome",
  chromeLink: "https://www.google.com/intl/en_uk/chrome/",
  firefoxText: "Firefox",
  firefoxLink: "https://www.mozilla.org/en-GB/firefox/new/"
};

export default {
  TaskManager,
  OrganisationServices,
  SmartPharmacyRegister,
  SmartPharmacy,
  AvailabilityRules,
  Telemetry,
  Recaptcha,
  Reports,
  Agenda,
  Appointment,
  Modals,
  Share,
  Login,
  Header,
  AppointmentSlots,
  BookAppointment,
  UsersList,
  CalendarsList,
  OrganisationsList,
  OrganisationsSettings,
  BranchesList,
  BranchSettings,
  UserDetails,
  AddUser,
  AddOrganisation,
  AddBranch,
  Snackbar,
  Sidebars,
  BranchServices,
  DefaultServices,
  EnabledServices,
  BranchRooms,
  AddNewServices,
  BranchCalendars,
  AddNewCalendar,
  AvailabilitySettings,
  Buttons,
  CalendarPage,
  PayoutDetails,
  Search,
  CurrentPage,
  NotSupported,
  AddInternalEvent,
  InternalEventDetails,
  Breadcrumbs,
  BranchServicesRedesign,
  BranchesListRedesign,
  OrganisationsListRedesign,
  UsersListRedesign,
  OrganisationCalendars,
  BranchCalendarsRedesign,
};
