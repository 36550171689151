import React, { Component } from "react";
import { connect } from "react-redux";

import AppointmentInfo from "./AppointmentInfo/AppointmentInfo";
import ClinicianInfo from "./ClinicianInfo/ClinicianInfo";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import AppointmentStatus from "./AppointmentStatus/AppointmentStatus";
import PaymentStatus from "./PaymentStatus/PaymentStatus";
import RefundReason from "./RefundReason/RefundReason";
import AppointmentButtons from "./AppointmentButtons/AppointmentButtons";
import AppointmentReason from "./AppointmentReason/AppointmentReason";
import { LinkIcon, IconClose } from "@patient-access/ui-kit";
import type { Action } from "types/actions";
import type { AppointmentDetailsType } from "constants/AppointmentDetailsConstants";
import { updateForm } from "actions/form";
import { drawerScrollHandler } from "helpers/common";
import moment from "moment";
import locale from "service/locale";
import { setAppointmentChanges } from "actions/appointmentDetails";

type Props = {
  handleCloseAppointmentDetails: () => any,
  handleSaveAppointmentDetails: () => any,
  handleDiscardChanges: () => any,
  handleRefundDialog: () => any,
  handleSetAppointmentPaymentStatus: (status: number, type: AppointmentDetailsType) => Action,
  handleCancelAppointment: (reason?: string) => any,
  handleQuickCancelAppointment: () => any,
  handleQuickAppointmentRefund:() => any,
  updateForm: (data: any) => any,
  setAppointmentChanges:(isAppointmentDataChanged: boolean) => Action,
  appointment: any,
  handleSetAppointmentStatus: (
    status: number,
    outcomeStatus: number,
    behalfOfPatient: boolean
  ) => Action,
  isActiveAgenda: boolean,
  type: AppointmentDetailsType,
  startStatus: number,
  isStatusNotUpdatedForPastAppointment: boolean
};

type State = {
  isDataChanged: boolean,
  isScrolledStart: boolean,
  isScrolledEnd: boolean,
};

const mapStateToProps = state => ({
  isActiveAgenda: state.panel.isActiveAgenda,
});

const mapDispatchToProps = (dispatch: Function): any => ({
  updateForm: data => dispatch(updateForm(data)),
  setAppointmentChanges: isAppointmentDataChanged => dispatch(setAppointmentChanges(isAppointmentDataChanged))
});

class AppointmentDetailsContent extends Component<Props, State> {

  state = {
    isDataChanged: false,
    isScrolledStart: false,
    isScrolledEnd: false
  };

  componentDidMount = () => {
    drawerScrollHandler.bind(this)();
  };

  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.isStatusNotUpdatedForPastAppointment && 
      nextProps.isStatusNotUpdatedForPastAppointment !== this.props.isStatusNotUpdatedForPastAppointment)
    {
      this.setState({ isDataChanged: true },
      () => {
      const { setAppointmentChanges } = this.props;
      setAppointmentChanges(true);
      });
    }
  };

  setUpdateStatus = () => {
    const { isDataChanged } = this.state;
    if (!isDataChanged) {
      this.setState({ isDataChanged: true },
      () => {
      const { setAppointmentChanges } = this.props;
      setAppointmentChanges(true);
      });
    }
  };

  handleFormChange = ({ target: { name, value }}) => {
    const { updateForm } = this.props;
    updateForm({ [name]: value });
    this.setUpdateStatus();
  };

  handleCalendarChange = (slotId: string) => {
    const { updateForm } = this.props;
    updateForm({ slotId });
    this.setUpdateStatus();
  };

  handleAppointmentStatusChange = (newAppointmentStatus: any) => {
    const { handleSetAppointmentStatus } = this.props;
    const { status, outcomeStatus, behalfOfPatient } = newAppointmentStatus;
    this.setUpdateStatus();
    handleSetAppointmentStatus(status, outcomeStatus, behalfOfPatient);
  };

  handlePaymentStatusChange = (newPaymentStatus: any) => {
    const { handleSetAppointmentPaymentStatus, type } = this.props;
    handleSetAppointmentPaymentStatus(newPaymentStatus, type);
    this.setUpdateStatus();
  };

  handleQuickCancelAppointment = () => {
    const { handleQuickCancelAppointment } = this.props;
    handleQuickCancelAppointment();
    this.setUpdateStatus();
  };

 handleQuickAppointmentRefund =() => {    
    const { handleQuickAppointmentRefund } = this.props;
    handleQuickAppointmentRefund();
    this.setUpdateStatus();
  }

  render() {
    const {
      handleCloseAppointmentDetails,
      handleSaveAppointmentDetails,
      handleDiscardChanges,
      handleRefundDialog,
      isActiveAgenda,
      type,
      startStatus,
      refundReason
    } = this.props;
    const { isDataChanged, isScrolledStart, isScrolledEnd } = this.state;
    const { appointment } = this.props;
    const currentTime = moment.utc().toISOString().split('.')[0];
    const today = currentTime.split("T")[0];
    const isPastAndStartTimeAppointment = appointment && moment().isSameOrAfter(appointment.startTime.split('+')[0], "second");
    const isPastAndEndTimeAppointment = appointment && moment().isSameOrAfter(appointment.endTime.split('+')[0], "second");
    const appointmentDate = appointment && appointment.endTime.split("T")[0];
    const isTodayPastAppointment = moment().isSameOrAfter(appointment.startTime.split('+')[0], "second");
    const isPastAppointment = (appointmentDate <= today || isTodayPastAppointment) && isPastAndEndTimeAppointment;
    const isAppointmentStarted = appointmentDate <= today && isPastAndStartTimeAppointment;
    const isTodayAppointment = appointmentDate === today && !isPastAndEndTimeAppointment;
    const isCompletedAppointment = startStatus === locale.Appointment.status.provided.status ||
        startStatus === locale.Appointment.status.cancelled.status;
    const booking = appointment && appointment.booking;
    const isCancelled = startStatus === locale.Appointment.status.cancelled.status;
    const isRefundedbySupportTeam = appointment.csRefund || refundReason;
    return (
      <div
        className={`patient-care-modal-holder ${
          isActiveAgenda ? "agenda-active" : ""
        }`}
      >
        <div
          className="patient-care-modal"
          onScroll={drawerScrollHandler.bind(this)}
        >
          <div
            className={`patient-care-modal-header ${
              isScrolledStart ? "has-shadow" : ""
            }`}
            ref="modalHeader"
          >
            <h2>{locale.Appointment.appointmentDetailsTitle}</h2>
            <div className="patient-care-btn-close">
              <LinkIcon
                to="#"
                size="small"
                icon={<IconClose outline />}
                accessibilitySpan={
                  locale.Appointment.appointmentDetailsClose
                }
                onClick={
                  isDataChanged
                    ? handleDiscardChanges
                    : handleCloseAppointmentDetails
                }
              />
            </div>
          </div>

          <div className="patient-care-modal-content" ref="modalContent">
            <div
              id="patient-care-scrolling-content"
              ref="modalContentScroll"
            >
              {isCancelled && (
                <div className="patient-care-cancelled-status">
                  <b>
                    {locale.Appointment.cancellationText[0]}{" "}
                    {appointment.cancellation.source ===
                    locale.Appointment.cancellationStatus.pharmacy.value
                      ? `${
                          locale.Appointment.cancellationStatus.pharmacy
                            .label
                        } ${appointment.cancellation.actorFirstName} `
                      : locale.Appointment.cancellationStatus.patient
                          .label}
                    {locale.Appointment.cancellationText[1]}{" "}
                    {moment(appointment.cancellation.actionTime).format("DD/MM/YYYY [at] HH:mm")}
                  </b>
                  {appointment.cancellation.reason && (
                    <b>
                      {locale.Appointment.cancellationText[2]}
                      {appointment.cancellation.reason}
                    </b>
                  )}
                </div>
              )}
              <AppointmentInfo
                appointmentDetails={{
                  endTime: appointment.endTime,
                  startTime: appointment.startTime,
                  serviceName: appointment.service.name,
                  branchName: appointment.branch.name,
                  bookTime: booking && booking.actionTime,
                  bookingMethod: booking && booking.bookingMethod
                }}
              />
              <ClinicianInfo
                appointmentCalendar={{
                  name: appointment.calendar.name,
                  id: appointment.calendar.id
                }}
                room={appointment.room}
                isDisabled={appointment.status !== locale.Appointment.status.booked.status}
                handleCalendarChange={this.handleCalendarChange}
                type={type}
              />

              <CustomerDetails
                patient={appointment.patient}
                handleFormChange={this.handleFormChange}
                status={appointment.status}
                startStatus={startStatus}
                isBookedOnline={booking && booking.bookingMethod === locale.Appointment.type.online.value}
                isPastAndEndTimeAppointment={isPastAndEndTimeAppointment}
                appointmentType = {appointment.type}
              />

              {!isCancelled && (
                <AppointmentStatus
                  status={appointment.status}
                  isPastAppointment={isPastAppointment}
                  isTodayAppointment={isTodayAppointment}
                  outcomeStatus={appointment.outcomeStatus}
                  isDisabled={isCompletedAppointment}
                  handleAppointmentStatusChange={this.handleAppointmentStatusChange}
                  isPastAndEndTimeAppointment={isPastAndEndTimeAppointment}
                  bookingMethod={booking && booking.bookingMethod}
                />
              )}

              <AppointmentReason 
                  reason={booking.reason}
                  validType= {(appointment.type === 1 || appointment.type === 2)? true : false }/>

              <PaymentStatus
                status={appointment.status}
                outcomeStatus={appointment.outcomeStatus}
                bookingMethod={booking && booking.bookingMethod}
                price={appointment.payment.totalAmount}
                paymentStatus={appointment.payment.paymentStatus}
                paymentMethod={appointment.payment.paymentMethod}
                isPastAppointment={isPastAppointment}
                isTodayAppointment={isTodayAppointment}
                handleRefundDialog={handleRefundDialog}
                handlePaymentStatusChange={this.handlePaymentStatusChange}
                startStatus={startStatus}
                isRefundedbySupportTeam={isRefundedbySupportTeam}
              />
              {isRefundedbySupportTeam && (
                <RefundReason
                  refundReason={ refundReason || appointment.csRefund.reason}
              />
              )}
            </div>
          </div>

          <AppointmentButtons
            isDataChanged={isDataChanged}
            appointmentDetails = {appointment}
            isCompletedAppointment={isCompletedAppointment}
            isAppointmentStarted={isAppointmentStarted}
            handleDiscardChanges={handleDiscardChanges}
            handleSaveAppointmentDetails={handleSaveAppointmentDetails}
            handleCloseAppointmentDetails={handleCloseAppointmentDetails}
            handleQuickCancelAppointment={this.handleQuickCancelAppointment}
            ref="modalAddOrDiscardButtons"
            handleQuickAppointmentRefund= {this.handleQuickAppointmentRefund}            
            status={appointment.status}
            outcomeStatus={appointment.outcomeStatus}
            customClassName={isScrolledEnd ? "has-shadow" : ""}
            isRefundedbySupportTeam={isRefundedbySupportTeam}
            price={appointment.payment.totalAmount}
            bookingMethod={appointment.booking.bookingMethod}
            paymentMethod={appointment.payment.paymentMethod}
          />
        </div>

        <span className="patient-care-modal-overlay" />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentDetailsContent);
