import agent from 'service/agent';

import { openAgendaDetails, openSearchDetails, closeAgendaDetails, closeSearchDetails } from "actions/panel";
import { setEvents } from "actions/calendarView";
import { getAgendaAppointments } from "actions/agenda";
import { getBranchCalendarsAppointments } from "actions/branches";
import type { Action } from 'types/actions';
import * as AppointmentDetailsConstants from 'constants/AppointmentDetailsConstants';
import * as SearchConstants from 'constants/SearchConstants';
import { showSnackbarStatus } from 'actions/snackbar';
import { clearForm } from "./form";
import { getToken } from "helpers/common";
import type { Appointment } from "types/appointments";
import type { AppointmentDetailsType } from 'constants/AppointmentDetailsConstants';
import * as ServiceConstants from "constants/ServicesConstants";
import moment from 'moment'
import locale from 'service/locale';
import {
  checkPhoneNumber,
  checkEmail,
  checkDOB,
  checkFirstName,
  checkLastName,
  checkPostcode
} from "helpers/checkValues";
import {
  bookAppointmentPending,
  bookAppointmentSuccess,
  bookAppointmentError,
  bookAppointmentPendingStop
} from "./bookAppointment";
import { setBranchHeader } from "./router";
import { getAppointmentDuration } from '../helpers/formatData';
import {
  openInternalEventDetails,
  closeInternalEventDetails
} from "actions/panel";

const setSearchDetails = (appointment ? : any): Action => ({
  type: SearchConstants.SET_SEARCH_DETAILS,
  payload: appointment
});

const setAgendaDetails = (appointment ? : Appointment | null): Action => ({
  type: AppointmentDetailsConstants.SET_APPOINTMENT_DETAILS,
  payload: appointment
});

const setAgendaStatus = (status: number, outcomeStatus: number, behalfOfPatient: boolean): Action => ({
  type: AppointmentDetailsConstants.CHANGE_APPOINTMENT_STATUS,
  payload: {
    status,
    outcomeStatus,
    behalfOfPatient
  }
});

const setAgendaCancellationReason = (reason: string): Action => ({
  type: AppointmentDetailsConstants.CHANGE_AGENDA_CANCELLATION_REASON,
  payload: reason
});

const setSearchCancellationReason = (reason: string): Action => ({
  type: SearchConstants.CHANGE_SEARCH_CANCELLATION_REASON,
  payload: reason
});

const setAgendaPaymentStatus = (status: number): Action => ({
  type: AppointmentDetailsConstants.CHANGE_PAYMENT_STATUS,
  payload: status
});

const setSearchStatus = (status: number, outcomeStatus: number, behalfOfPatient: boolean): Action => ({
  type: SearchConstants.CHANGE_SEARCH_DETAILS_STATUS,
  payload: {
    status,
    outcomeStatus,
    behalfOfPatient
  }
});

const setSearchPaymentStatus = (status: number): Action => ({
  type: SearchConstants.CHANGE_SEARCH_DETAILS_PAYMENT_STATUS,
  payload: status
});

const setRefundDetails = (refundDetails ? : any | null): Action => ({
  type: AppointmentDetailsConstants.SET_APPOINTMENT_REFUND_DETAILS,
  payload: refundDetails
});

const refundCustomerPending = () : Action => ({
  type: AppointmentDetailsConstants.REFUND_CUSTOMER_PENDING
});

const refundCustomerSuccess = () : Action => ({
  type: AppointmentDetailsConstants.REFUND_CUSTOMER_SUCCESS
});

const refundCustomerError = () : Action => ({
  type: AppointmentDetailsConstants.REFUND_CUSTOMER_ERROR
});

const getRefundAmountPending = () : Action => ({
  type: AppointmentDetailsConstants.GET_REFUND_AMOUNT_PENDING
});

const getRefundAmountSuccess = () : Action => ({
  type: AppointmentDetailsConstants.GET_REFUND_AMOUNT_SUCCESS
});

const getRefundAmountError = () : Action => ({
  type: AppointmentDetailsConstants.GET_REFUND_AMOUNT_ERROR
});

const setInternalEvent = (internalEvent: InternalEvent): Action => ({
  type: AppointmentDetailsConstants.SET_INTERNAL_EVENT,
  payload: internalEvent
});

export const deleteInternalEventPending = (): Action => ({
  type: AppointmentDetailsConstants.DELETE_INTERNAL_EVENT_PENDING
});

export const deleteInternalEventSuccess = (): Action => ({
  type: AppointmentDetailsConstants.DELETE_INTERNAL_EVENT_SUCCESS
});

export const deleteInternalEventError = (): Action => ({
  type: AppointmentDetailsConstants.DELETE_INTERNAL_EVENT_ERROR
});

export const setAppointmentDetails = (appointment ? : Appointment | null, type? : AppointmentDetailsType | null) => (dispatch: Function, getState: Function) => {
  const serviceId = appointment &&  appointment.service.id;
  if(serviceId === ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID)
  {
    dispatch(setInternalEvent(appointment));
    appointment && dispatch(openInternalEventDetails());
  } else if (type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA) {
    dispatch(closeAgendaDetails());
    dispatch(setAgendaDetails(appointment));
    appointment && dispatch(openAgendaDetails());
  } else {
    dispatch(closeSearchDetails());
    dispatch(setSearchDetails(appointment));
    appointment && dispatch(openSearchDetails());
  }
};

export const setAppointmentPaymentStatus = (status: number, type: AppointmentDetailsType) => (dispatch: (action: any) => Action) => {
  if (type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA) {
    dispatch(setAgendaPaymentStatus(status));
  } else {
    dispatch(setSearchPaymentStatus(status));
  }
};

export const setAppointmentStatus = (status: number, outcomeStatus: number, type: AppointmentDetailsType, behalfOfPatient: boolean) => (dispatch: (action: any) => Action) => {
  if (type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA) {
    dispatch(setAgendaStatus(status, outcomeStatus, behalfOfPatient));
  } else {
    dispatch(setSearchStatus(status, outcomeStatus, behalfOfPatient));
  }
};

export const setAppointmentCancellationReason = (reason: string, type: AppointmentDetailsType) => (dispatch: (action: any) => Action) => {
  if (type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA) {
    dispatch(setAgendaCancellationReason(reason));
  } else {
    dispatch(setSearchCancellationReason(reason));
  }
};

export const setAppointmentChanges = (isAppointmentDataChanged: boolean): PanelActions => ({
  type: AppointmentDetailsConstants.SET_APPOINTMENT_CHANGES,
  payload: isAppointmentDataChanged
});

export const getAppointmentDetails = (appointmentId: string, type ? : AppointmentDetailsType | null) => (dispatch: (action: any) => Action, getState: Function) => {
  const currentState = getState();
  const branchId = currentState.calendarView.branchId;
  const isActiveAdmin = currentState.profile.isActiveAdmin;
  isActiveAdmin && dispatch(setBranchHeader(branchId));
  getToken(dispatch)
    .then(accessToken => {
      agent.Appointments.getAppointmentDetails(appointmentId, accessToken)
        .then(appointment => {
          dispatch(bookAppointmentPendingStop());
          dispatch(setAppointmentDetails(appointment, type));
        })
        .catch(err => {
          console.log('getAppointmentDetails server error', err);
        });
    });

};

export const getInternalEventDetails = (calendarId: string, eventId: string, type ? : AppointmentDetailsType | null) => (
  dispatch: Function) => {
  getToken(dispatch).then(accessToken => {
    agent.Appointments.getInternalEvent(calendarId, eventId, accessToken)
      .then(appointment => {
          dispatch(bookAppointmentPendingStop());
          dispatch(setAppointmentDetails(appointment, type));
      })
      .catch(err => {
        console.log("get internal event server error", err);
      });
  });
};

export const cancelAppointment = (reason: string, appId: string, type ? : AppointmentDetailsType | null, behalfOfPatient: boolean) => (dispatch: (action: any) => Action, getState: Function) => {
  const currentState = getState();
  const internalEvent = currentState.appointmentDetails.internalEvent;
  internalEvent && dispatch(deleteInternalEventPending())
  const serviceId = internalEvent && internalEvent.service.id;
  getToken(dispatch)
    .then(accessToken => {
      agent.Appointments.cancelAppointment(reason, appId, behalfOfPatient, accessToken)
        .then(() => {
          if(serviceId === ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID)
          {
            dispatch(setEvents([]));
            dispatch(deleteInternalEventSuccess());
            dispatch(closeInternalEventDetails());
            dispatch(showSnackbarStatus(locale.Snackbar.internalEventDeleted));            
            dispatch(getBranchCalendarsAppointments());
          }
          else
          {
            type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
              ? dispatch(closeAgendaDetails())
              : dispatch(closeSearchDetails());
            dispatch(bookAppointmentSuccess());
            dispatch(showSnackbarStatus(locale.Snackbar.cancelAppointmentSuccess));
            dispatch(getAgendaAppointments());
          }
        })
        .catch(err => {
           if(serviceId === ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID){
            dispatch(deleteInternalEventError())
            dispatch(showSnackbarStatus(locale.Snackbar.internalEventNotDeleted));
            console.log("get internal event server error", err);
          }
          else{
          dispatch(bookAppointmentError());
          dispatch(showSnackbarStatus(locale.Snackbar.cancelAppointmentError));
          console.log('cancelAppointment server error', err);
          }
        });
    });
};

export const removeInternalEvent = (calendarId: string, eventId: string) => (
  dispatch: Function) => {
  dispatch(deleteInternalEventPending())
  getToken(dispatch).then(accessToken => {
    agent.Appointments.deleteInternalEvent(calendarId, eventId, accessToken)
      .then(() => {
        dispatch(setEvents([]));
        dispatch(deleteInternalEventSuccess());
        dispatch(closeInternalEventDetails());
        dispatch(showSnackbarStatus(locale.Snackbar.internalEventDeleted));        
        dispatch(getBranchCalendarsAppointments());
      })
      .catch(err => {
        dispatch(deleteInternalEventError())
        dispatch(showSnackbarStatus(locale.Snackbar.internalEventNotDeleted));
        console.log("get internal event server error", err);
      });
  });
}

export const updateAppointment = (
  appointment: Appointment,
  isPaid: boolean,
  type: AppointmentDetailsType,
  startStatus: number
) => (dispatch: (action: any) => Action, getState: Function) => {
  dispatch(bookAppointmentPending());
  const currentState = getState();
  const newPatient = currentState.form;
  const { status, outcomeStatus, appointmentId, booking } = appointment;
  const isTelephoneAppointment = appointment.type === 2;
  const isVideoAppointment = appointment.type === 1;
  let outcome;

  if (
    (startStatus === locale.Appointment.status.provided.status && outcomeStatus === locale.Appointment.status.provided.outcomeStatus) ||
    startStatus === locale.Appointment.status.booked.status
  ) {
    const _keys = Object.keys(newPatient);

    const isNewPhoneNumberEdited = _keys.includes("details_phoneNumber");
    const isNewPhoneNumberValid = (isTelephoneAppointment || isVideoAppointment) ?
      (isNewPhoneNumberEdited && checkPhoneNumber(newPatient.details_phoneNumber)) : 
      (isNewPhoneNumberEdited && checkPhoneNumber(newPatient.details_phoneNumber)) ||
      (newPatient.details_phoneNumber === "");

    const isNewEmailEdited = _keys.includes("details_email");
    const isNewEmailValid = (isTelephoneAppointment || isVideoAppointment) ?
      (isNewEmailEdited && checkEmail(newPatient.details_email)) :
      (isNewEmailEdited && checkEmail(newPatient.details_email)) ||
      newPatient.details_email === "";

    const isNewDOBEdited = _keys.includes("details_DOB");
    const isNewDOBValid =
      (isNewDOBEdited && checkDOB(newPatient.details_DOB)) ||
      newPatient.details_DOB === "";

    const isNewPostcodeEdited = _keys.includes("details_postcode");
    const isNewPostcodeValid =
      (isNewPostcodeEdited && checkPostcode(newPatient.details_postcode)) ||
      newPatient.details_postcode === "";

    const isNewLastNameEdited = _keys.includes("details_lastName");
    const isNewLastNameValid =
      isNewLastNameEdited && checkLastName(newPatient.details_lastName);

    const isNewFirstNameExists = _keys.includes("details_firstName");
    const isNewFirstNameValid =
        isNewFirstNameExists && checkFirstName(newPatient.details_firstName);

    if (
      (!isNewPhoneNumberValid && isNewPhoneNumberEdited) ||
      (!isNewEmailValid       && isNewEmailEdited) ||
      (!isNewDOBValid         && isNewDOBEdited) ||
      (!isNewPostcodeValid    && isNewPostcodeEdited) ||
      (!isNewLastNameValid    && isNewLastNameEdited) ||
      (!isNewFirstNameValid   && isNewFirstNameExists)
    ) return;

    const patient = {
      firstName:
        isNewFirstNameExists
          ? newPatient.details_firstName
          : appointment.patient.firstName,
      lastName:
        isNewLastNameEdited
          ? newPatient.details_lastName
          : appointment.patient.lastName,

      dateOfBirth:
        isNewDOBEdited
          ? moment.utc(newPatient.details_DOB).toISOString()
          : appointment.patient.dateOfBirth,
      postcode:
        isNewPostcodeEdited
          ? newPatient.details_postcode
          : appointment.patient.postcode,
      email:
        isNewEmailEdited
          ? newPatient.details_email
          : appointment.patient.email,
      phone:
        isNewPhoneNumberEdited
          ? newPatient.details_phoneNumber
          : appointment.patient.phone
    };

    // BE expects null, not empty string
    for (let k in patient) {
      const v = patient[k];
      if (v === "") patient[k] = null;
    }

    getToken(dispatch)
      .then(accessToken => {
        if (booking.bookingMethod === locale.Appointment.type.online.value) return null;
        return agent.Appointments.updateAppointment(appointmentId, { patient, isPaid }, accessToken);
      })
      .then(() => {
        if (status === locale.Appointment.status.provided.status) {
          switch (outcomeStatus) {
            case locale.Appointment.status.notProvided.outcomeStatus:
              outcome = locale.Appointment.outcomeStatus[2].label;
              break;
            case locale.Appointment.status.missed.outcomeStatus:
              outcome = locale.Appointment.outcomeStatus[4].label;
              break;
            default:
              // by default proceed appointment as serviceProvided
              outcome = locale.Appointment.outcomeStatus[1].label;
          }
          dispatch(completeAppointment(outcome, isPaid, appointmentId, type));
        } else if (status === locale.Appointment.status.cancelled.status) {
          const { cancellationReason, behalfOfPatient } = appointment;
          const updatedEvents = [...currentState.calendarView.events];
          const cancelledEventIndex = updatedEvents.findIndex(event => event.appointmentId === appointmentId);
          updatedEvents[cancelledEventIndex].status = locale.Appointment.status.cancelled.status;
          updatedEvents[cancelledEventIndex].outcomeStatus = locale.Appointment.status.cancelled.outcomeStatus;
          dispatch(setEvents(updatedEvents));
          dispatch(cancelAppointment(cancellationReason, appointmentId, type, behalfOfPatient));
        } else {
          dispatch(bookAppointmentSuccess());
          dispatch(showSnackbarStatus(locale.Snackbar.updateAppointmentSuccess));
          type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
            ? dispatch(closeAgendaDetails())
            : dispatch(closeSearchDetails());
        }
        dispatch(setAppointmentChanges(false));
        dispatch(clearForm());
      })
      .catch(err => {
        dispatch(showSnackbarStatus(locale.Snackbar.updateAppointmentError));
        dispatch(bookAppointmentPendingStop());
        console.log('Error occurred while updating patient data for appointment: ', err);
      });
  }
};

const completeAppointment = (outcome: string, isPaid: boolean, appointmentId: string, type: AppointmentDetailsType) => (dispatch: (action: any) => Action) => {
  getToken(dispatch)
    .then(accessToken => {
      agent.Appointments.completeAppointment({ outcome, isPaid }, appointmentId, accessToken)
        .then(() => {
          type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
            ? dispatch(closeAgendaDetails())
            : dispatch(closeSearchDetails());
          dispatch(bookAppointmentSuccess());
          dispatch(showSnackbarStatus(outcome === locale.Appointment.outcomeStatus[4].label ?
            locale.Snackbar.missAppointmentSuccess :
            locale.Snackbar.completeAppointmentSuccess));
          dispatch(getAgendaAppointments());
        })
        .catch(err => {
          dispatch(bookAppointmentError());
          dispatch(showSnackbarStatus(outcome === locale.Appointment.outcomeStatus[4].label ?
            locale.Snackbar.missAppointmentError :
            locale.Snackbar.completeAppointmentError));
          if (err && err.response) {
            //TODO: process error depending on error status
          } else
            console.log('completeAppointment server error', err);
        });
    });
};

export const refundCustomer = (full: boolean, reason: boolean, appointmentId: string, type: AppointmentDetailsType) => (dispatch: (action: any) => Action) => {
  dispatch(refundCustomerPending());
  getToken(dispatch)
    .then(accessToken => {   
      agent.Appointments.refundCustomer({ full, reason }, appointmentId, accessToken)
        .then(() => {
          type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
            ? dispatch(closeAgendaDetails())
            : dispatch(closeSearchDetails());
          dispatch(refundCustomerSuccess());
          dispatch(showSnackbarStatus(locale.Snackbar.refundSuccess));
          dispatch(getAgendaAppointments());
        })
        .catch(err => {
          dispatch(refundCustomerError());
          dispatch(showSnackbarStatus(locale.Snackbar.refundError));          
          console.log('refundCustomer server error', err);
        });
    });
};

export const updateAppointmentCalendar = (
  appointment: Appointment,
  isPaid: boolean,
  slotId: string,
  type: AppointmentDetailsType,
  startStatus: number
) => (dispatch: (action: any) => any, getState: Function) => {
  dispatch(bookAppointmentPending());
  const currentState = getState();
  const agendaAppointment = currentState.appointmentDetails;
  const searchAppointment = currentState.search.details;
  const viewedAppointment =
    type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? agendaAppointment
      : searchAppointment;

  const { appointmentId } = viewedAppointment;
  const duration = getAppointmentDuration(viewedAppointment.startTime, viewedAppointment.endTime);
  const appointmentData = { slotId, duration };

  getToken(dispatch).then(accessToken => {
    agent.Appointments.updateAppointment(appointmentId, appointmentData, accessToken)
      .then(({appointmentId}) => {
        if (appointment.booking.bookingMethod !== locale.Appointment.type.online.value) {
          appointment.appointmentId = appointmentId;
          dispatch(updateAppointment(appointment, isPaid, type, startStatus));
        } else {
          dispatch(bookAppointmentSuccess());
          dispatch(clearForm());
          dispatch(setAppointmentChanges(false));
          dispatch(showSnackbarStatus(locale.Snackbar.updateAppointmentSuccess));
          type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
            ? dispatch(closeAgendaDetails())
            : dispatch(closeSearchDetails());
        }
      })
      .catch(err => {
        dispatch(bookAppointmentError());
        console.log('Error occurred while changing calendar for appointment: ', err);
      })
  });
};

export const calculateAppointmentRefund = (appointmentId: string, isFullRefund: boolean) =>(dispatch: (action:any) => Action, getState: Function) => {   
  dispatch(getRefundAmountPending()); 
  getToken(dispatch)
    .then(accessToken => {      
      agent.Appointments.calculateAppointmentRefund(appointmentId, isFullRefund, accessToken)
        .then(refundDetails => {          
          dispatch(setRefundDetails(refundDetails))
          dispatch(getRefundAmountSuccess());
        })
        .catch(err => {
          dispatch(getRefundAmountError());
          console.log('calculateAppointmentRefund server error', err);
        });
    });
}; 