import * as ColorConstants from 'constants/ColorConstants';
import locale from 'service/locale';
import { getPreviousMonthDate } from "helpers/common"

export const GET_REPORTS_PENDING = "GET_REPORTS_PENDING";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_ERROR = "GET_REPORTS_ERROR";
export const SET_REPORTS_ITEMS = "SET_REPORTS_ITEMS";
export const SET_REPORT_DROPDOWN_ORGANISATION = "SET_REPORT_DROPDOWN_ORGANISATION";
export const SET_REPORT_DROPDOWN_BRANCH = "SET_REPORT_DROPDOWN_BRANCH";
export const SET_REPORT_DROPDOWN_SERVICE = "SET_REPORT_DROPDOWN_SERVICE";
export const SET_REPORT_DROPDOWN_STATUS = "SET_REPORT_DROPDOWN_STATUS";
export const CLEAR_REPORT_DROPDOWN_BRANCH = "CLEAR_REPORT_DROPDOWN_BRANCH";
export const CLEAR_REPORT_DROPDOWN_SERVICE = "CLEAR_REPORT_DROPDOWN_SERVICE";
export const SET_REPORT_FILTER = "SET_REPORT_FILTER";
export const RESET_REPORT_FILTER_ORGANISATION = "RESET_REPORT_FILTER_ORGANISATION";
export const RESET_REPORT_FILTER_BRANCH = "RESET_REPORT_FILTER_BRANCH";
export const RESET_REPORT_FILTER_REST = "RESET_REPORT_FILTER_REST";
export const SET_REPORTS_DOWNLOAD_OVERLAY = "SET_REPORTS_DOWNLOAD_OVERLAY";
export const GET_REPORTS_DOWNLOAD_PENDING = "GET_REPORTS_DOWNLOAD_PENDING";
export const GET_REPORTS_DOWNLOAD_SUCCESS = "GET_REPORTS_DOWNLOAD_SUCCESS";
export const GET_REPORTS_DOWNLOAD_ERROR = "GET_REPORTS_DOWNLOAD_ERROR";
export const SET_REPORTS_DOWNLOAD_ITEMS = "SET_REPORTS_DOWNLOAD_ITEMS";

export const STATUS_FORMATTER = [
  {
    status: 1,
    name: locale.Reports.appointmentsStatus.upcoming,
    color: ColorConstants.COLOR_GREY_LIGHT
  },
  {
    status: 2,
    name: locale.Reports.appointmentsStatus.cancelled,
    color: ColorConstants.COLOR_STATUS_ERROR
  },
  {
    status: 3,
    name: locale.Reports.appointmentsStatus.statusRequired,
    color: ColorConstants.COLOR_ACCENT
  },
  {
    status: 4,
    name: locale.Reports.appointmentsStatus.serviceProvided,
    color: ColorConstants.COLOR_STATUS_SUCCESS
  },
  {
    status: 5,
    name: locale.Reports.appointmentsStatus.serviceNotProvided,
    color: ColorConstants.COLOR_NOTIFICATIONS_WARNING
  },
  {
    status: 6,
    name: locale.Reports.appointmentsStatus.missed,
    color: ColorConstants.COLOR_SUPPORT_TWO
  }
];

export const DOWNLOAD_HEADERS = [];

export const appointmentStatusOptions = [
  {
    value: 0,
    label: locale.Reports.appointmentsStatus.all,
    status: "All",
  },
  {
    value: 1,
    label: locale.Reports.appointmentsStatus.upcoming,
    status: "Upcoming",
  },
  {
    value: 2,
    label: locale.Reports.appointmentsStatus.cancelled,
    status: "Cancelled"
  },
  {
    value: 3,
    label: "Status update required",
    status: "StatusRequired"
  },
  {
    value: 4,
    label: locale.Reports.appointmentsStatus.serviceProvided,
    status: "ServiceProvided"
  },
  {
    value: 5,
    label: locale.Reports.appointmentsStatus.serviceNotProvided,
    status: "ServiceNotProvided"
  },
  {
    value: 6,
    label: locale.Reports.appointmentsStatus.missed,
    status: "Missed"
  }
];

export const DEFAULT_REPORT_STARTDATE = getPreviousMonthDate();
export const DEFAULT_REPORT_ENDDATE = new Date();
export const DEFAULT_REPORT_MAXDATE = new Date();
