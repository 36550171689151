export const COLOR_PRIMARY = "#32325d";
export const COLOR_SECONDARY = "#d3368a";
export const COLOR_ACCENT = "#4768fd";
export const COLOR_GREY_LIGHT = "#dedee3";
export const COLOR_GREY_DARK = "#6f6f8c";
export const COLOR_BLACK = "#000000";
export const COLOR_WHITE = "#ffffff";
export const COLOR_NOTIFICATIONS_INFO = "#c9e4fd";
export const COLOR_NOTIFICATIONS_WARNING = "#ffd9cb";
export const COLOR_STATUS_ERROR = "#e01937";
export const COLOR_STATUS_SUCCESS = "#008a27";
export const COLOR_SUPPORT_ONE = "#7ca7ed";
export const COLOR_SUPPORT_TWO = "#f4ac4e";
export const COLOR_SUPPORT_THREE = "#9671bc";
export const COLOR_SUPPORT_FIVE = "#5cb0b9";
export const COLOR_SUPPORT_SIX = "#e37fb4";

// COLOR_GRAPH
export const COLOR_GRAPH_ONE = "#4768FD";
export const COLOR_GRAPH_TWO = "#5CB0B9";
export const COLOR_GRAPH_THREE = "#9671BC";
export const COLOR_GRAPH_FOUR = "#f4ac4e";
export const COLOR_GRAPH_FIVE = "#F56060";
export const COLOR_GRAPH_SIX = "#B1C14D";
