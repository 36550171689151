import React, { Component } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Icon, IconAppointment, Button } from '@patient-access/ui-kit';
import AsyncSelect from "react-select/lib/Async";

import * as RolesConstants from "constants/RolesConstants";
import locale from 'service/locale';
import './styles.scss';
import { getOrganisationsForAsyncSelect } from "actions/organisations";
import { setDropdownOrganisation, setDropdownBranch, setDropdownService, setDropDownStatus, setReportFilter, setReportsDownloadOverlay, getReportsDownload, resetReportFiltersOrganisation, resetReportFiltersBranch, resetReportFiltersRest } from 'actions/reports';
import { formatListAsOptions, formatOrganisationListAsOptions } from "helpers/formatData";
import { getBranchesForAsyncSelect } from 'actions/branches';
import { getServicesForAsyncSelect } from 'actions/services';
import type { Organisation, Branch } from "types/organisation";
import type { ReportFilter } from "types/reports";
import * as OrganisationsConstants from 'constants/OrganisationsConstants';
import * as BranchesConstants from 'constants/BranchesConstants';
import * as ServicesConstants from 'constants/ServicesConstants';
import * as ReportsConstants from 'constants/ReportsConstants';

type Props = {
  currentRole: any,
  isLoading: boolean,
  organisations: Organisation[],
  branches: Branch[],
  profileOrganisation: Organisation,
  userOrganisationId: string,
  userBranchId: string,
  getOrganisationsForAsyncSelect: (searchValue?: string, callback?: Function) => Action,
  getBranchesForAsyncSelect: (searchValue: string, organizationId?: string, callback?: Function) => any,
  getServicesForAsyncSelect: (searchValue: string, organizationId?: string, branchId?: string, callback?: Function) => any,
  setDropdownOrganisation: (organisation: Organisation) => Action,
  setDropdownBranch: (branch: Branch) => Action,
  setDropdownService: (service: any) => Action,
  setDropDownStatus: (status: any) => Action,
  setReportFilter: (filters: ReportFilter) => Action,
  handleReportFilterChange: () => Action,
  setReportsDownloadOverlay: (isOverlayVisible: boolean) => Action,
  getReportsDownload: () => Action,
  resetReportFiltersOrganisation: () => Action,
  resetReportFiltersBranch: () => Action,
  resetReportFiltersRest: () => Action,
};

const mapStateToProps = (state) => ({
  currentRole: state.roles.profileCurrentRole,
  organisations: state.organisations.organisationsListAsync,
  branches: state.branches.branchesListAsync,
  services: state.services.servicesListAsync,
  selectedOrganisation: state.reportsBasic.selectedOrganisation,
  selectedBranch: state.reportsBasic.selectedBranch,
  selectedService: state.reportsBasic.selectedService,
  selectedStatus: state.reportsBasic.selectedStatus,
  profileOrganisation: state.profile.organisation
});

const mapDispatchToProps = (dispatch: Function) => ({
  getOrganisationsForAsyncSelect: (searchValue, callback) => dispatch(getOrganisationsForAsyncSelect(searchValue, callback)),
  getBranchesForAsyncSelect: (searchValue, organizationId, callback) => dispatch(getBranchesForAsyncSelect(searchValue, organizationId, callback)),
  getServicesForAsyncSelect: (searchValue, organizationId, branchId, callback) => dispatch(getServicesForAsyncSelect(searchValue, organizationId, branchId, callback)),
  setDropdownOrganisation: (organisation) => dispatch(setDropdownOrganisation(organisation)),
  setDropdownBranch: (branch) => dispatch(setDropdownBranch(branch)),
  setDropdownService: (service) => dispatch(setDropdownService(service)),
  setDropDownStatus: (status) => dispatch(setDropDownStatus(status)),
  setReportFilter: (filters) => dispatch(setReportFilter(filters)),
  setReportsDownloadOverlay: (isOverlayVisible) => dispatch(setReportsDownloadOverlay(isOverlayVisible)),
  getReportsDownload: () => dispatch(getReportsDownload()),
  resetReportFiltersOrganisation: () => dispatch(resetReportFiltersOrganisation()),
  resetReportFiltersBranch: () => dispatch(resetReportFiltersBranch()),
  resetReportFiltersRest: () => dispatch(resetReportFiltersRest()),
});

class ReportsFilters extends Component<Props, State> {

  state = {
    searchOrgValue: "",
    searchBranchValue: "",
    searchServiceValue: "",
    selectedStartDate: ReportsConstants.DEFAULT_REPORT_STARTDATE,
    selectedEndDate: ReportsConstants.DEFAULT_REPORT_ENDDATE,
    maxDate: ReportsConstants.DEFAULT_REPORT_MAXDATE
  };

  handleBranchServiceDefaultValues = () => {
    const {
      getOrganisationsForAsyncSelect,
      getBranchesForAsyncSelect,
      getServicesForAsyncSelect,
      currentRole,
      userOrganisationId,
      userBranchId,
      profileOrganisation,
      handleReportFilterChange
    } = this.props;

    if (currentRole.role === RolesConstants.ADMIN) {
      getOrganisationsForAsyncSelect();
      getBranchesForAsyncSelect();
      getServicesForAsyncSelect();
      handleReportFilterChange();
    }

    if (userOrganisationId) {
      profileOrganisation.id = profileOrganisation.organisationId;
      this.handleOrganisationChange(profileOrganisation);
    }

    if (userBranchId) {
      const branch =
        (profileOrganisation.branches &&
          profileOrganisation.branches.find(
            branch => branch.id === userBranchId
          )) ||
        {};
      this.handleBranchChange(branch);
    }
  };

  componentDidMount = () => {
    this.handleBranchServiceDefaultValues();
  };

  componentWillReceiveProps = (nextProps: Props) => {
    const {selectedBranch} = this.props;
    if (selectedBranch.id && !selectedBranch.label) {
      selectedBranch.label = selectedBranch.name;
    }
    if (nextProps.profileOrganisation !== this.props.profileOrganisation) {
      Object.assign(
        this.props.profileOrganisation,
        nextProps.profileOrganisation
      );

      this.handleBranchServiceDefaultValues();
    }
  };

  // componentWillUnmount = () => {
  //   const { resetReportFilters } = this.props;
  //   resetReportFilters();
  // };

  handleOrganisationSearchChange = (searchOrgValue: string, action: any) => {
    const { getOrganisationsForAsyncSelect } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        searchOrgValue
      }, () => {
        if (searchOrgValue && searchOrgValue.length < 2) {
          getOrganisationsForAsyncSelect();
        }
      });
    }
  };

  handleBranchSearchChange = (searchBranchValue: string, action: any) => {
    const { getBranchesForAsyncSelect, selectedOrganisation } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        searchBranchValue
      }, () => {
        if (searchBranchValue && searchBranchValue.length < 2) {
          getBranchesForAsyncSelect("", selectedOrganisation.id);
        }
      });
    }
  };

  handleServiceSearchChange = (searchServiceValue: string, action: any) => {
    const { getServicesForAsyncSelect, selectedOrganisation, selectedBranch } = this.props;

    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({
        searchServiceValue
      }, () => {
        if (searchServiceValue && searchServiceValue.length < 2) {
          getServicesForAsyncSelect("", selectedOrganisation.id, selectedBranch.id);
        }
      });
    }
  };

  handleOrganisationChange = organisation => {
    const { searchBranchValue, searchServiceValue } = this.state;
    const { getBranchesForAsyncSelect, getServicesForAsyncSelect, setDropdownOrganisation, setDropdownBranch, setDropdownService, selectedBranch, setReportFilter, handleReportFilterChange, currentRole } = this.props;

    if (organisation.id) {
      setDropdownOrganisation(organisation);
      setReportFilter({ organisationIds: [organisation.id], branchIds: [], serviceIds: [] });
    } else {
      setDropdownOrganisation({});
      setReportFilter({ organisationIds: [], branchIds: [], serviceIds: [] });
      getBranchesForAsyncSelect();
      getServicesForAsyncSelect();
    }

    setDropdownBranch({});
    setDropdownService({});

    if (
      organisation.id &&
      (currentRole.role === RolesConstants.ORGANIZATION_ADMIN ||
        currentRole.role === RolesConstants.ADMIN)
    ) {
      getBranchesForAsyncSelect(searchBranchValue, organisation.id);
      getServicesForAsyncSelect(
        searchServiceValue,
        organisation.id,
        selectedBranch.id
      );
      handleReportFilterChange();
    }

    if (currentRole.role === RolesConstants.ADMIN) {
      handleReportFilterChange();
    }
  };

  handleBranchChange = branch => {
    const { searchServiceValue } = this.state;
    const { getServicesForAsyncSelect, setDropdownBranch, selectedOrganisation, setReportFilter, handleReportFilterChange, currentRole, userOrganisationId } = this.props;

    if (branch.id) {
      setDropdownBranch(branch);
      setReportFilter({ branchIds: [branch.id] });
    } else {
      setDropdownBranch({});
      setReportFilter({ branchIds: []});
    }

    getServicesForAsyncSelect(
      searchServiceValue,
      selectedOrganisation.id,
      branch.id
    );

    if (currentRole.role === RolesConstants.ADMIN) {
      handleReportFilterChange();
    }

    if (currentRole.role === RolesConstants.ORGANIZATION_ADMIN && userOrganisationId) {
      handleReportFilterChange();
    }

    if (currentRole.role === RolesConstants.BRANCH_ADMIN && userOrganisationId && branch.id) {
      handleReportFilterChange();
    }
  };

  handleServiceChange = service => {
    const { setDropdownService, setReportFilter, handleReportFilterChange } = this.props;

    if (service.value) {
      setDropdownService(service);
      setReportFilter({ serviceIds: [service.value] });
    } else {
      setDropdownService({});
      setReportFilter({ serviceIds: [] });
    }

    handleReportFilterChange();
  };

  handleStatusChange = value => {
    const { handleReportFilterChange, setReportFilter, setDropDownStatus } = this.props;

    if (value.value === 0) {
      setDropDownStatus({});
    } else {
      setDropDownStatus(value);
    }
    if (value.status) {
      setReportFilter({ statuses: [value.status] });
    } else {
      setReportFilter({ statuses: [] });
    }

    handleReportFilterChange();
  };

  handleStartDateChange = date => {
    const { handleReportFilterChange, setReportFilter } = this.props;
    const { selectedEndDate} = this.state;

    if(date > selectedEndDate) {
      this.setState({
        selectedStartDate: date,
        selectedEndDate: date
      });
      setReportFilter({ startDate: date, endDate: date });
    } else {
      this.setState({
        selectedStartDate: date
      });
      setReportFilter({ startDate: date });
    }
    handleReportFilterChange();
  };

  handleEndDateChange = date => {
    const { handleReportFilterChange, setReportFilter } = this.props;
    const { selectedStartDate} = this.state;

    if(date < selectedStartDate) {
      this.setState({
        selectedStartDate: date,
        selectedEndDate: date
      });
      setReportFilter({ startDate: date, endDate: date });
    } else {
      this.setState({
        selectedEndDate: date
      });
      setReportFilter({ endDate: date });
    }
    handleReportFilterChange();
  };

  handleResetFilter = () => {
    const { handleReportFilterChange, resetReportFiltersOrganisation, resetReportFiltersBranch, resetReportFiltersRest, currentRole } = this.props;

    if (currentRole.role === RolesConstants.ADMIN) {
      resetReportFiltersOrganisation();
      resetReportFiltersBranch();
    }
    if (currentRole.role === RolesConstants.ORGANIZATION_ADMIN) {
      resetReportFiltersBranch();
    }

    resetReportFiltersRest();

    this.setState({
      selectedStartDate: ReportsConstants.DEFAULT_REPORT_STARTDATE,
      selectedEndDate: ReportsConstants.DEFAULT_REPORT_ENDDATE,
      maxDate: ReportsConstants.DEFAULT_REPORT_MAXDATE
    });
    handleReportFilterChange();
  };

  handleLoadOrganisationOptions = (inputValue: string, callback: Function) => {
    const { getOrganisationsForAsyncSelect } = this.props;
    return getOrganisationsForAsyncSelect(inputValue.length > 2 ? inputValue : "", callback);
  };

  handleLoadBranchOptions = (inputValue: string, callback: Function) => {
    const { getBranchesForAsyncSelect, selectedOrganisation } = this.props;
    return getBranchesForAsyncSelect(inputValue.length > 2 ? inputValue : "", selectedOrganisation.id, callback);
  };

  handleLoadServiceOptions = (inputValue: string, callback: Function) => {
    const { getServicesForAsyncSelect, selectedOrganisation, selectedBranch } = this.props;
    return getServicesForAsyncSelect(inputValue.length > 2 ? inputValue : "", selectedOrganisation.id, selectedBranch.id, callback);
  };

  render() {
    const { searchOrgValue, searchBranchValue, searchServiceValue, selectedStartDate, selectedEndDate, maxDate } = this.state;
    const { isLoading, organisations, currentRole, branches, services, setReportsDownloadOverlay, getReportsDownload, selectedOrganisation, selectedBranch, selectedService, selectedStatus } = this.props;
    const canShowOrganisationSelect = currentRole.role === RolesConstants.ADMIN;
    const canShowBranchSelect = currentRole.role === RolesConstants.ADMIN || currentRole.role === RolesConstants.ORGANIZATION_ADMIN;

    let organisationsListOpts = formatOrganisationListAsOptions(organisations.slice(0).filter(organisation => organisation.value));
    organisationsListOpts.unshift(OrganisationsConstants.DROPDOWN_OPTION_DEFAULT);

    let branchesListOpts = formatListAsOptions(branches.slice(0).filter(branch => branch.value));
    branchesListOpts.unshift(BranchesConstants.DROPDOWN_OPTION_DEFAULT);

    let servicesListOpts = formatListAsOptions(services.slice(0).filter(service => service.value));
    servicesListOpts.unshift(ServicesConstants.DROPDOWN_OPTION_DEFAULT);

    const renderResetFiltersButton =() => {
      if (
        currentRole.role === RolesConstants.ADMIN &&
        Object.keys(selectedOrganisation).length === 0 &&
        Object.keys(selectedBranch).length === 0 &&
        Object.keys(selectedService).length === 0 &&
        Object.keys(selectedStatus).length === 0 &&
        moment(selectedStartDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_STARTDATE).format('DD/MM/YYYY') &&
        moment(selectedEndDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_ENDDATE).format('DD/MM/YYYY')
        ) return null;
      if (
        currentRole.role === RolesConstants.ORGANIZATION_ADMIN &&
        Object.keys(selectedBranch).length === 0 &&
        Object.keys(selectedService).length === 0 &&
        Object.keys(selectedStatus).length === 0 &&
        moment(selectedStartDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_STARTDATE).format('DD/MM/YYYY') &&
        moment(selectedEndDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_ENDDATE).format('DD/MM/YYYY')
        ) return null;
      if (
        currentRole.role === RolesConstants.BRANCH_ADMIN &&
        Object.keys(selectedService).length === 0 &&
        Object.keys(selectedStatus).length === 0 &&
        moment(selectedStartDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_STARTDATE).format('DD/MM/YYYY') &&
        moment(selectedEndDate).format('DD/MM/YYYY') === moment(ReportsConstants.DEFAULT_REPORT_ENDDATE).format('DD/MM/YYYY')
        ) return null;
      return (
        <Button
          buttonType="blueline"
          messageKey="buttonResetFilter"
          defaultMessage={locale.Reports.buttonResetFilter}
          onClick={(e) => { e.preventDefault(); this.handleResetFilter(); }}
          isLoading={isLoading}
        />
      );
    }

    return (
      <div className="reports-basic-filters">
        <div className="reports-basic-filters-wrapper">
          {
            canShowOrganisationSelect
              ? <div className="reports-basic-filter-organisation">
                <AsyncSelect
                  defaultOptions={organisationsListOpts}
                  defaultValue={selectedOrganisation.id ? selectedOrganisation : organisationsListOpts[0]}
                  value={selectedOrganisation.id ? selectedOrganisation : organisationsListOpts[0]}
                  id="reports_organisation_select"
                  inputId="reports_organisation_input"
                  name="reports_organisation_select"
                  placeholder="Select Organisation"
                  loadOptions={this.handleLoadOrganisationOptions}
                  classNamePrefix="patient-care"
                  onInputChange={this.handleOrganisationSearchChange}
                  onChange={this.handleOrganisationChange}
                  className="patient-care-select"
                  inputValue={searchOrgValue}
                  openMenuOnFocus={true}
                />
              </div>
              : null}
          {
            canShowBranchSelect ?
              <div className="reports-basic-filter-branch">
                <AsyncSelect
                  defaultOptions={branchesListOpts}
                  defaultValue={selectedBranch.id ? selectedBranch : branchesListOpts[0]}
                  value={selectedBranch.id ? selectedBranch : branchesListOpts[0]}
                  id="reports_branch_select"
                  inputId="reports_branch_input"
                  name="reports_branch_select"
                  placeholder="Select branch"
                  loadOptions={this.handleLoadBranchOptions}
                  classNamePrefix="patient-care"
                  onInputChange={this.handleBranchSearchChange}
                  onChange={this.handleBranchChange}
                  inputValue={searchBranchValue}
                  className="patient-care-select"
                  openMenuOnFocus={true}
                />
              </div>
              : null}
          <div className="reports-basic-filter-service">
            <AsyncSelect
              defaultOptions={servicesListOpts}
              defaultValue={selectedService.id ? selectedService : servicesListOpts[0]}
              value={selectedService.id ? selectedService : servicesListOpts[0]}
              id="reports_service_select"
              inputId="reports_service_input"
              name="reports_service_select"
              placeholder="Select service"
              loadOptions={this.handleLoadServiceOptions}
              classNamePrefix="patient-care"
              onInputChange={this.handleServiceSearchChange}
              onChange={this.handleServiceChange}
              inputValue={searchServiceValue}
              className="patient-care-select"
              openMenuOnFocus={true}
            />
          </div>
          <div className="reports-basic-filter-status">
            <Select
              id="reports_status_select"
              name="reports_status_select"
              placeholder="Select status"
              options={ReportsConstants.appointmentStatusOptions}
              defaultValue={selectedStatus.value ? selectedStatus : ReportsConstants.appointmentStatusOptions[0]}
              value={selectedStatus.value ? selectedStatus : ReportsConstants.appointmentStatusOptions[0]}
              classNamePrefix="patient-care"
              onChange={status => this.handleStatusChange(status)}
              className="patient-care-select"
            />
          </div>
        </div>
        <div className="reports-basic-filters-wrapper">
          <div className="reports-basic-filters-date">
            <div className="patient-care-datepicker">
              <Icon type="block" size="large" icon={<IconAppointment outline={false} />} />
              <DatePicker
                selected={selectedStartDate}
                maxDate={maxDate}
                onChange={date => this.handleStartDateChange(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                disabled={isLoading}
              />
            </div>
            <p>to</p>
            <div className="patient-care-datepicker">
              <Icon type="block" size="large" icon={<IconAppointment outline={false} />} />
              <DatePicker
                selected={selectedEndDate}
                maxDate={maxDate}
                onChange={date => this.handleEndDateChange(date)}
                selectsEnd
                dateFormat="dd/MM/yyyy"
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="reports-basic-filter-button">
            {renderResetFiltersButton()}
            <Button
              buttonType="secondary"
              messageKey="buttonDownloadSCV"
              defaultMessage={locale.Reports.buttonDownloadSCV}
              onClick={(e) => { e.preventDefault(); setReportsDownloadOverlay(true); getReportsDownload(); }}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilters);
