import moment from "moment";
import values from "lodash.values";
import find from "lodash.find";
import filter from "lodash.filter";
import { findDOMNode } from "react-dom";

import * as CalendarViewConstants from "constants/CalendarViewConstants";
import * as RolesConstants from "constants/RolesConstants";
import { DEFAULT_BRANCHID } from "constants/BranchesConstants";
import * as AppointmentConstants from "constants/AppointmentConstants";
import type { Organisation } from "types/organisation";
import type { OpenHours } from "types/calendars";
import type {
  CalendarView,
  ChangeDateAction
} from "constants/AppointmentConstants";
import agent from "service/agent";
import locale from "service/locale";
import { logoutUser } from 'actions/login';
import * as PanelConstants from "constants/PanelConstants";
import { CALENDAR_VIEWS }  from "constants/AppointmentConstants";
import { formatCalendarAvailablePeriods } from "./formatData";
import * as CalendarsConstants from 'constants/CalendarsConstants';
import * as ServiceConstants from "constants/ServicesConstants";
import { setRefreshTokenCookie, getRefreshTokenCookie } from "service/cookie";

import * as RoutesConstants from 'constants/RoutesConstants';
import { getADDToken, isAzureA2bAuthencationEnabled } from 'components/Pages/Login/AAD/aadAuthHelper';

export const getAppointmentsListFromAllCalendars = (
  arrayOfCalendarsWithAppointments: any[]
): any[] => {
  let appointmentsListFromAllCalendars = [];
  arrayOfCalendarsWithAppointments.forEach(list => {
    appointmentsListFromAllCalendars = appointmentsListFromAllCalendars.concat(
      list
    );
  });
  const globalAppointmentsListByDate = [];
  appointmentsListFromAllCalendars.forEach(date => {
    const existingDateIndex = globalAppointmentsListByDate.findIndex(
      el => el.date === date.date
    );
    if (existingDateIndex === -1) globalAppointmentsListByDate.push(date);
    else {
      globalAppointmentsListByDate[existingDateIndex].appointments = [
        ...globalAppointmentsListByDate[existingDateIndex].appointments,
        ...date.appointments
      ];
    }
  });
  return globalAppointmentsListByDate;
};

export const getAllItemsListFromAllCalendars = (
  arrayOfCalendarsWithAllItems: any[]
): any[] => {
  let appointmentsListFromAllCalendars = [];
  arrayOfCalendarsWithAllItems.forEach(list => {
    appointmentsListFromAllCalendars = appointmentsListFromAllCalendars.concat(
      list
    );
  });
  const globalAllItemsListByDate = [];
  appointmentsListFromAllCalendars.forEach(date => {
    const existingDateIndex = globalAllItemsListByDate.findIndex(
      el => el.date === date.date
    );
    if (existingDateIndex === -1) globalAllItemsListByDate.push(date);
    else {
      globalAllItemsListByDate[existingDateIndex].items = [
        ...globalAllItemsListByDate[existingDateIndex].items,
        ...date.items
      ];
    }
  });
  return globalAllItemsListByDate;
};

export const getAppointmentsListForAgenda = (
  arrayOfCalendarsWithAppointments: any[]
): any[] => {
  let appointmentsListFromAllCalendars = [];
  arrayOfCalendarsWithAppointments.forEach((list, index) => {
    if (index === 0 && list.date === moment().format("YYYY-MM-DD")) {
      const appointments = [];
      list.appointments.forEach(appointment => {
        if (moment().isSameOrBefore(appointment.endTime.split('+')[0], "second")) {
          appointments.push(appointment);
        }
      });
      list.appointments = appointments;
    }

    appointmentsListFromAllCalendars = appointmentsListFromAllCalendars.concat(
      list
    );
  });
  const globalAppointmentsListByDate = [];
  appointmentsListFromAllCalendars.forEach(date => {
    const existingDateIndex = globalAppointmentsListByDate.findIndex(
      el => el.date === date.date
    );
    if (existingDateIndex === -1) globalAppointmentsListByDate.push(date);
    else {
      globalAppointmentsListByDate[existingDateIndex].appointments = [
        ...globalAppointmentsListByDate[existingDateIndex].appointments,
        ...date.appointments
      ];
    }
  });
  return globalAppointmentsListByDate;
};

export const getEventsListFromSlots = (arrayOfSlotsByDate: any[]): any[] => {
  let slotsListForCalendarView = [];
  arrayOfSlotsByDate.forEach(date => {
    slotsListForCalendarView = slotsListForCalendarView.concat(date.slots);
  });
  return slotsListForCalendarView;
};

export const getCalendarResources = (
  branchCalendarsList: any[],
  calendarsList: any[]
) => {
  return branchCalendarsList
    .map(calendar => {
      const resourceCalendar = calendarsList.find(item => item.id === calendar.id);
      return {
        resourceId: calendar.id,
        resourceTitle: calendar.name[0].toUpperCase(),
        resourceColor: calendar.order,
        resourceOpenTimes: formatCalendarAvailablePeriods(resourceCalendar.availablePeriods),
        resourceBlockedPeriods: resourceCalendar.blockedPeriods
      }
      }
    );

};

export const getBlockedDays = (
  startTime: string,
  endTime: string,
  calendarsList: any[],
  currentViewPeriod: string,
  openingHours: any[],
  closedDays: any[]
): string[] => {
  const start = moment(startTime).format().slice(0, 10);
  const end = moment(endTime).format().slice(0, 10);
  const blockedPeriodsList = calendarsList.map(calendar =>
    calendar.blockedPeriods.filter(period => period.endDate >= start && period.startDate <= end)
  );
  const blockedDays = [];
  if (blockedPeriodsList.length > 0) {
    let range = moment(endTime).diff(moment(startTime), "days");
    if (currentViewPeriod === CALENDAR_VIEWS.MONTH) {
      range = range + 1;
    }
    for (let i = 0; i < range; i++) {
      const currentDate = moment(startTime).add(i, "days").format().slice(0, 10);
      const dayOfWeek = moment(currentDate).day();
      const availabilityDay = openingHours.find(day => day.day === dayOfWeek);
      const intersectionOfBlockedPeriods = [];
      blockedPeriodsList.forEach(period => {
        const isContainsInPeriod =
          period.find(el =>
            currentDate >= el.startDate &&
            currentDate <= el.endDate &&
            el.startTime === null &&
            el.endTime === null
          );
        if (isContainsInPeriod) {
          intersectionOfBlockedPeriods.push(currentDate);
        }
      });
      if (intersectionOfBlockedPeriods.length === blockedPeriodsList.length) {
        blockedDays.push(intersectionOfBlockedPeriods[0]);
      }
      if (availabilityDay.timesSessions && availabilityDay.timesSessions.length === 0) {
        blockedDays.push(currentDate);
      }
    }
  }

  closedDays.forEach(closedDay => blockedDays.push(closedDay.date));
  return blockedDays;
};

export const getAppointmentsListForCalendarView = (
  arrayOfAppointmentsByDate: any[],
  isShowCancellation?: boolean = true,
  calendarFilters: string[],
  serviceFilters: string[],
  statusFilters: Object,
  branchCalendarsList: any[]
): any[] => {
  let appointmentsListForCalendarView = [];
  arrayOfAppointmentsByDate.forEach(date => {
    appointmentsListForCalendarView = appointmentsListForCalendarView.concat(
      date.appointments
    );
  });

  appointmentsListForCalendarView.forEach(appointment => {
    // in order to avoid timezone issue
    const startDateStringWithoutTimezone = appointment.startTime.substring(0, 19);
    const endDateStringWithoutTimezone = appointment.endTime.substring(0, 19);

    const start = moment(startDateStringWithoutTimezone).toDate();
    const end = moment(endDateStringWithoutTimezone).toDate();
    const appointmentCalendar = branchCalendarsList.find(calendar => calendar.id === appointment.calendar.id);

    appointment.tooltip =
      `${appointment.startTime.split('T')[1].slice(0,5)} - ${appointment.endTime.split('T')[1].slice(0,5)}: ${
      appointment.service.name
        ? appointment.service.name
        : locale.Appointment.removedServiceText
    }`;
    appointment.title = appointment.service.name ? appointment.service.name : locale.Appointment.removedServiceText;
    appointment.id = appointment.appointmentId;
    appointment.start = start;
    appointment.end = end;
    appointment.resourceId = appointment.calendar.id;
    appointment.calendar.color = appointmentCalendar ? getCalendarColor(appointmentCalendar.order) : 1;
    appointment.endRoundToNearest15Min = roundToNearest15Min(end);
    if (appointment.status === locale.Appointment.status.cancelled.status) {
      appointment.isHiddenCancellation = !isShowCancellation;
    }
    if (calendarFilters.some(filter => filter === appointment.calendar.id)) {
      appointment.isHiddenCalendar = true;
    }
    if (serviceFilters.some(filter => filter === appointment.service.id)) {
      appointment.isHiddenService = true;
    }
    if (statusFilters && statusFilters.selectedAppointments && statusFilters.selectedAppointments.includes(appointment.appointmentId)) {
      appointment.isHiddenStatus = true;
    }
  });

  return appointmentsListForCalendarView;
};

export const getAllItemsListForCalendarView = (
  arrayOfAppointmentsByDate: any[],
  isShowCancellation?: boolean = true,
  calendarFilters: string[],
  serviceFilters: string[],
  statusFilters: Object,
  branchCalendarsList: any[]
): any[] => {
  let appointmentsListForCalendarView = [];
  arrayOfAppointmentsByDate.forEach(date => {
    appointmentsListForCalendarView = appointmentsListForCalendarView.concat(
      date.items
    );
  });

  appointmentsListForCalendarView.forEach(appointment => {
    // in order to avoid timezone issue
    const startDateStringWithoutTimezone = appointment.startTime.substring(0, 19);
    const endDateStringWithoutTimezone = appointment.endTime.substring(0, 19);

    const start = moment(startDateStringWithoutTimezone).toDate();
    const end = moment(endDateStringWithoutTimezone).toDate();
    const appointmentCalendar = branchCalendarsList.find(calendar => calendar.id === appointment.calendar.id);

    appointment.tooltip =
      `${appointment.startTime.split('T')[1].slice(0,5)} - ${appointment.endTime.split('T')[1].slice(0,5)}: ${
      appointment.service.name
        ? appointment.service.name
        : locale.Appointment.removedServiceText
    }`;
    appointment.title = appointment.service.name ? appointment.service.name : locale.Appointment.removedServiceText;
    appointment.id = appointment.appointmentId;
    appointment.start = start;
    appointment.end = end;
    appointment.resourceId = appointment.calendar.id;
    appointment.calendar.color = appointmentCalendar ? getCalendarColor(appointmentCalendar.order) : 1;
    appointment.endRoundToNearest15Min = roundToNearest15Min(end);
    if (appointment.status === locale.Appointment.status.cancelled.status) {
      appointment.isHiddenCancellation = !isShowCancellation;
    }
    if (calendarFilters.some(filter => filter === appointment.calendar.id)) {
      appointment.isHiddenCalendar = true;
    }
    if (serviceFilters.some(filter => filter === appointment.service.id)) {
      appointment.isHiddenService = true;
    }
    if (statusFilters && statusFilters.selectedAppointments && statusFilters.selectedAppointments.includes(appointment.appointmentId)) {
      appointment.isHiddenStatus = true;
    }
  });

  return appointmentsListForCalendarView;
};

const roundToNearest15Min = (time) => {
  let timeToReturn = new Date(time);
  timeToReturn.setMilliseconds(Math.round(time.getMilliseconds() / 1000) * 1000);
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
  return timeToReturn;
};

export const getCalendarColor = (order: number): number => {
  if (order > CalendarViewConstants.COLOR_MAX_NUMBER) {
    getCalendarColor(order - CalendarViewConstants.COLOR_MAX_NUMBER);
  } else {
    return order;
  }
};

export const getNextDate = (
  type: ChangeDateAction,
  view: CalendarView,
  date: Date
): moment$Moment => {
  return type === AppointmentConstants.CHANGE_DATE_ACTIONS.NEXT
    ? moment
        .utc(date)
        .startOf(view)
        .add(1, view + "s")
    : moment
        .utc(date)
        .startOf(view)
        .subtract(1, view + "s");
};

export const formatToolbarDate = (currentView: CalendarView, date: Date): string => {
  switch (currentView) {
    case AppointmentConstants.CALENDAR_VIEWS.DAY:
      return moment(date).format("DD MMMM YYYY");
    case AppointmentConstants.CALENDAR_VIEWS.WEEK:
      return `${moment(date)
        .startOf(AppointmentConstants.CALENDAR_VIEWS.WEEK)
        .format("DD")} - ${moment(date)
        .endOf(AppointmentConstants.CALENDAR_VIEWS.WEEK)
        .format("DD MMMM YYYY")}`;
    default:
      return moment(date).format("MMMM YYYY");
  }
};

export const getParamsString = (params: any): string => {
  const paramsArray = [];
  for (const key in params) {
    if (Array.isArray(params[key])) {
      paramsArray.push(`${key}=${params[key].join(",")}`);
    } else {
      paramsArray.push(`${key}=${params[key]}`);
    }
  }
  return `?${paramsArray.join("&")}`;
};

export const removeDuplicates = (accounts: any, newAccounts: any): any[] => {
  return values(newAccounts)
    .map(newRole => {
      const role = newRole.role && newRole.role.name;
      const organizationId = newRole.organisation
        ? newRole.organisation.id
        : DEFAULT_BRANCHID;
      const branchId = newRole.branch ? newRole.branch.id : DEFAULT_BRANCHID;

      if (
        find(accounts, {
          role: role,
          organizationId: organizationId,
          branchId: branchId
        })
      ) {
        return null;
      }

      return {
        role: newRole.role && newRole.role.name,
        organizationId: newRole.organisation ? newRole.organisation.id : null,
        branchId: newRole.branch ? newRole.branch.id : null
      };
    })
    .filter(role => role !== null);
};

export const getAdminHomepage = (currentRole: Object): string => {
  let redirectUrl;
  switch (currentRole.role) {
    case RolesConstants.ADMIN:
      redirectUrl = RoutesConstants.ADMIN_ORGANISATIONS;
      break;
    case RolesConstants.ORGANIZATION_ADMIN:
      redirectUrl = `${RoutesConstants.ADMIN_ORGANISATIONS}/${currentRole.organizationId}/${RoutesConstants.BRANCHES}`;
      break;
    default:
      redirectUrl = `${RoutesConstants.ADMIN_ORGANISATIONS}/${currentRole.organizationId}/${currentRole.branchId}/${RoutesConstants.USERS}`;
  }
  return redirectUrl;
};

export const getRelevantOrganisationsList = (
  accounts: any[],
  organisations: Organisation[]
) => {
  return filter(organisations, organisation => {
    let match = find(accounts, { organizationId: organisation.id });
    return match && organisation.id === match.organizationId;
  });
};

export const formatAvailablePeriodsForNewCalendar = (
  formData: Object
): any[] => {
  const availablePeriods = [];
  locale.AddNewCalendar.daysOfWeek.forEach(day => {
    const dayName = day.value.name;
    const formDay = formData[dayName];
    const openHours = formData.openHours[day.value.id-1];
    if (
      formDay &&
      formDay.status === locale.AddNewCalendar.dayAvailableOptions[0].value
    )
    {
    const { timesSessions } = openHours;
    const timePeriods = []
    if (timesSessions.length) {
          timesSessions.map(session => {
          return timePeriods.push({
                start: session.from,
                end: session.to
              });
          });
          availablePeriods.push({
            dayOfWeek: dayName,
            periods: timePeriods
          });
      }
    }
  });
  return availablePeriods;
};

export const formatAvailablePeriodsForEditCalendar = (
  formData: Object
): any[] => {
  const availablePeriods = [];

  locale.AddNewCalendar.daysOfWeek.forEach(day => {

    var t = formData.find(
        option => option.dayOfWeek === day.dayOfWeek);
  if(!t)
  {
    const timePeriods = [];
    timePeriods.push({
                from: CalendarsConstants.EMPTY_VALUE,
                to: CalendarsConstants.EMPTY_VALUE
              });

    availablePeriods.push({
            day: day.dayOfWeek,
            timesSessions: timePeriods
          });
  }
  else{
    const { dayOfWeek, periods } = t;

    if (periods.length) {
      const timePeriods = [];
       periods.map(session => {
          return timePeriods.push({
                from: session.start,
                to: session.end
              });
          });
          availablePeriods.push({
            day: dayOfWeek,
            timesSessions: timePeriods
          });
  }
};
})
return availablePeriods;
};

export const formatBlockedPeriodsForNewCalendar = (formData: Object) => {
  const blockedPeriods = [];
  for (let period in formData) {
    let start = "";
    const currentPeriod = formData[period];
    const { startDate, endDate, allDay, startTime, endTime } = currentPeriod;

    start = startDate ? startDate : new Date().toISOString().slice(0, 10);
    blockedPeriods.push({
      startDate: start,
      endDate: endDate ? endDate : start,
      startTime: allDay ? null : startTime,
      endTime: allDay ? null : endTime
    });
  }
  return blockedPeriods;
};

export const modifyOrganisationsList = (organisationsList: any[]) => {
  const { items } = organisationsList;
  items.forEach(organisation => {
    const { companyName, id } = organisation;

    organisation.label = companyName;
    organisation.value = id;
    organisation.branches.forEach(branch => {
      const { name, id } = branch;

      branch.label = name;
      branch.value = id;
      return branch;
    });

    return organisation;
  });
  return items;
};

export const modifyBranchesList = (branchesList: any[]) => {
  const { items } = branchesList;
  items.forEach(branch => {
    const { name, id } = branch;

    branch.label = name;
    branch.value = id;

    return branch;
  });
  return items;
};

export const modifyServicesList = (servicesList: any[]) => {
  const { items } = servicesList;
  items.forEach(service => {
    const { name, id } = service;

    service.label = name;
    service.value = id;

    return service;
  });
  return items;
};

export const getToken = (dispatch?: Function): any => {
  if (isAzureA2bAuthencationEnabled()) {
    return getADDToken();
  }
  return getTokenOld();
};

const getTokenOld = (dispatch?: Function): any => {
  const access_token = sessionStorage.getItem("token");
  const expires_in = sessionStorage.getItem("expires_in");
  const refresh_token = getRefreshTokenCookie();

  return new Promise(resolve => {
    // check the difference between now and expired time for current token
    const diff = moment.unix(Number(expires_in)).diff(moment(), "minutes");

    if (access_token && diff > 5) {
      resolve(access_token);
    } else {
      console.log("getToken ", diff);
      agent.Auth.refreshToken(refresh_token)
        .then(authData => {
          const { access_token, expires_in, refresh_token } = authData;
          const expires = moment().unix() + expires_in;

          sessionStorage.setItem("token", access_token);
          sessionStorage.setItem("expires_in", expires);
          setRefreshTokenCookie(refresh_token);

          resolve(access_token);
        })
        .catch(err => {
          dispatch && dispatch(logoutUser());
          resolve(access_token);
        });
    }
  });
}

export const drawerScrollHandler = function() {
  const {
    modalContentScroll,
    modalAddOrDiscardButtons,
    modalHeader
  } = this.refs;

  const modalContentScrollEl = findDOMNode(modalContentScroll);
  const modalContentScrollBox = modalContentScrollEl && modalContentScrollEl.getBoundingClientRect();
  const positionScrollEnd = modalContentScrollBox && (window.innerHeight - modalContentScrollBox.bottom);

  // calculate top threshold
  const modalHeaderEl = findDOMNode(modalHeader);
  const topThreshold = modalHeaderEl && modalHeaderEl.getBoundingClientRect().height;

  // calculate bottom threshold
  const lastChild = modalContentScrollEl && modalContentScrollEl.lastChild;
  const lastChildStyles =
    lastChild && (lastChild.currentStyle || window.getComputedStyle(lastChild));
  const lastChildBottomPaddingMargin =
    parseFloat((lastChildStyles && lastChildStyles.marginBottom) || 0) +
    parseFloat((lastChildStyles && lastChildStyles.paddingBottom) || 0);
  const modalAddOrDiscardButtonsEl = findDOMNode(modalAddOrDiscardButtons);
  const modalAddOrDiscardButtonsHeight = modalAddOrDiscardButtonsEl && modalAddOrDiscardButtonsEl.getBoundingClientRect()
    .height;
  const bottomThreshold =
    lastChildBottomPaddingMargin + modalAddOrDiscardButtonsHeight;

  this.setState({
    isScrolledStart: modalContentScrollBox && modalContentScrollBox.top < topThreshold,
    isScrolledEnd: positionScrollEnd < bottomThreshold
  });
  //TODO: check if we need this code
  // this.setState({ isScrolledEnd: positionScrollEnd > bottomThreshold });
  // this.setState({ isScrolledEnd: positionScrollEnd < bottomThreshold });
};

export const setHiddenAttributeToSlotsByCalendar = (
  slotsByDays: any[],
  calendarId: string
): void => {
  slotsByDays.forEach(dailySlots => {
    let dailySlotsWithCalendar = 0;
    dailySlots.slots.forEach(slot => {
      slot.isHiddenCalendar = false;
      if (
        !slot.calendars.find(
          slotCalendar => slotCalendar.calendarId === calendarId
        ) && calendarId !== "all"
      ) {
        slot.isHiddenCalendar = true;
      } else dailySlotsWithCalendar++;
    });
    dailySlots.isHiddenDay = !dailySlotsWithCalendar;
  });
};

export const setHiddenAttributeToAppointmentsByCalendar = (
  appointments: any[],
  calendarId: string
): void => {
  const filteredAppointments = [...appointments];
  filteredAppointments.forEach(day => {
    day.isHiddenDay = false;
    let dailyAppointmentsWithCalendar = 0;
    day.appointments.forEach(appointment => {
      if (appointment.calendar.id === calendarId || calendarId === "all") {
        dailyAppointmentsWithCalendar++;
      }
    });
    day.isHiddenDay = !dailyAppointmentsWithCalendar;
  });
};

export const getMinMaxBranchOpenHours = (openHoursModel: OpenHours[]): { minStartTime: number, maxEndTime: number } => {
  const openedDays = openHoursModel.filter(day => day.isOpen === true);
  let minStartTime = openedDays[0] && openedDays[0].startTime;
  let maxEndTime = openedDays[0] && openedDays[0].endTime;
  for (let i = 1; i < openedDays.length; ++i) {
    if (openedDays[i].isOpen) {
      if (openedDays[i].endTime > maxEndTime) maxEndTime = openedDays[i].endTime;
      if (openedDays[i].startTime < minStartTime) minStartTime = openedDays[i].startTime;
    }
  }
  const isRoundMaxEndTime = (maxEndTime || "").slice(3) === "00:00" ;
  return {
    minStartTime: Number((minStartTime || "").split(":")[0]),
    maxEndTime: isRoundMaxEndTime ? Number((maxEndTime || "").split(":")[0]) : Number((maxEndTime || "").split(":")[0]) + 1
  }
};

export const getSearchResultsQuantity = (searchResults: any[]): number => {
  let resultsQuantity = 0;
  searchResults.forEach(day => {
    day.appointments.forEach(appointment => {
      if (!appointment.isHiddenSearch && appointment.service.id !== ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID)
      resultsQuantity++;
    })
  });
  return resultsQuantity;
};

export const tableListSharedSortColumn = (val, nextVal, sortOrder) => {
  const name = (val || "").toUpperCase();
  const nextName = (nextVal || "").toUpperCase();
  const a = sortOrder === 'asc' ? name : nextName;
  const b = sortOrder === 'asc' ? nextName : name;
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const getRelevantOrganisations = (currentRole: any, organisations: any[]): any[] => {
  let result = [];

  switch (currentRole.role) {
    case RolesConstants.ADMIN:
      result = organisations;
      break;
    case RolesConstants.ORGANIZATION_ADMIN:
      result = organisations.filter(
          organisation => organisation.id === currentRole.organizationId
      );
      break;
    case RolesConstants.BRANCH_ADMIN:
      result = [{
        id: currentRole.organizationId,
        value: currentRole.organizationId,
        label: currentRole.organizationName,
        branches: [{
          id: currentRole.branchId,
          value: currentRole.branchId,
          label: currentRole.branchName,
        }]
      }];
      break;
    default:
      break;
  }

  return result;
};

export const columnSortingWithoutDefaultStateHook = function (currentSortConfig, destinationSortConfigs) {
  if (currentSortConfig.length > 0 && destinationSortConfigs.length === 0) {
    const columnSortPlugin = this.getPlugin('columnSorting');
    const firstColumnConfig = currentSortConfig[0];
    firstColumnConfig.sortOrder = 'asc';
    columnSortPlugin.sort(firstColumnConfig);
    return false;
  }
};

export const getOrganisationOption = (organisationDetails: any) => {
  const { name, organisationId } = organisationDetails;
  const branches = organisationDetails.branches.map(branch => {
    const { name, id } = branch;

    branch.label = name;
    branch.value = id;

    return branch;
  });

  return {
    ...organisationDetails,
    id: organisationId,
    label: name,
    value: organisationId,
    branches: branches
  };
};

export const getBranchOption = (organisation: Organisation, branchId: string) => {
  return organisation.branches.find(branch => branch.id === branchId);
};

export const getScreenWidth = (): boolean => window.innerWidth > PanelConstants.LOW_RESOLUTION_POINT;

export const getTomorrowDate = () => {
  let date = new Date();
  let tomorrow = date.getDate()+1;
  date.setDate(tomorrow);
  return date.toISOString().substr(0, 10);
}

export const getPreviousMonthDate = () => {
  let date = new Date();
  let previousMonthDate = new Date(date.setDate(date.getDate() - 30));

  return previousMonthDate;
};

export const splitArraybySize = (array, size) => 
Array.from(
    new Array(Math.ceil(array.length / size)),
    (_, i) => array.slice(i * size, i * size + size)
);