import React, { Component } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import {
  LinkIcon,
  IconChevronLeft,
  IconChevronRight,
  Button
} from "@patient-access/ui-kit";
import moment from "moment";

import { getNextDate, formatToolbarDate } from 'helpers/common';
import * as AppointmentConstants from "constants/AppointmentConstants";

import locale from "service/locale";

type Props = {
  isLowResolution: boolean,
  onView: (view: string) => any,
  onNavigate: (action: any, newDate: Date) => any,
  views: string[],
  date: Date,
  view: Object | string,
};

const mapStateToProps = (state) => ({
  isLowResolution: state.panel.isLowResolution
});

class CalendarToolbar extends Component<Props> {
  render() {
    const { isLowResolution, onView, onNavigate, views, date, view: currentView } = this.props;
    let viewsForSelect = [];

    views.forEach(view => {
      const label = view.charAt(0).toUpperCase() + view.slice(1);
      viewsForSelect.push({ label, value: view })
    });

    const defaultView = viewsForSelect.find(view => view.value === currentView);
    return (
      <div className="patient-care-calendar-info">
        <div className="patient-care-container-flex">
          <div className="patient-care-date-row">
            <div className="patient-care-date-wrap">
              <h2>{formatToolbarDate(currentView, date)}</h2>
            </div>
            <LinkIcon
              to={locale.CalendarPage.previousButtonTo}
              size="large"
              icon={<IconChevronLeft outline />}
              accessibilitySpan={locale.CalendarPage.previousButton}
              onClick={() =>
                onNavigate(
                  null,
                  getNextDate(
                    AppointmentConstants.CHANGE_DATE_ACTIONS.PREV,
                    currentView,
                    moment(date).toDate()
                  )
                )
              }
            />
            <LinkIcon
              to={locale.CalendarPage.nextButtonTo}
              size="large"
              icon={<IconChevronRight outline />}
              accessibilitySpan={locale.CalendarPage.nextButton}
              onClick={() =>
                onNavigate(
                  null,
                  getNextDate(
                    AppointmentConstants.CHANGE_DATE_ACTIONS.NEXT,
                    currentView,
                    moment(date).toDate()
                  )
                )
              }
            />
            <Button
              buttonType="blueline"
              messageKey="today-btn"
              defaultMessage={locale.CalendarPage.todayButton}
              onClick={() => onNavigate(null, moment())}
              data-id="today-btn"
              className="patient-care-btn-greyline"
              buttonSize="small"
            />
          </div>
          <div className="patient-care-calendar-switcher">
            {!isLowResolution ? (
              views.map(view => (
                <button
                  className={`patient-care-calendar-button ${view === currentView ? "active" : "" }`}
                  key={view}
                  onClick={() => onView(view)}
                >
                  {view}
                </button>
              ))
            ) : (
              <Select
                onChange={option => onView(option.value)}
                options={viewsForSelect}
                defaultValue={defaultView}
                classNamePrefix="patient-care"
                className="patient-care-select"
                isSearchable={false}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(CalendarToolbar);
