import locale from 'service/locale';

export const SET_CALENDARS = "SET_CALENDARS";
export const SET_OPEN_HOURS = "SET_OPEN_HOURS";
export const SET_TIME_OFF_MINUTES = "SET_TIME_OFF_MINUTES";
export const SET_CLOSED_DAYS = "SET_CLOSED_DAYS";
export const SET_BOOKING_CUTOFF = "SET_BOOKING_CUTOFF";
export const SET_BLOCKED_DAYS = "SET_BLOCKED_DAYS";
export const UPDATE_AVAILABILITY_PENDING = "UPDATE_AVAILABILITY_PENDING";
export const UPDATE_AVAILABILITY_SUCCESS = "UPDATE_AVAILABILITY_SUCCESS";
export const UPDATE_AVAILABILITY_ERROR = "UPDATE_AVAILABILITY_ERROR";
export const GET_AVAILABILITY_PENDING = "GET_AVAILABILITY_PENDING";
export const GET_AVAILABILITY_SUCCESS = "GET_AVAILABILITY_SUCCESS";
export const GET_AVAILABILITY_ERROR = "GET_AVAILABILITY_ERROR";
export const ADD_BLOCKED_PEROID= "ADD_BLOCKED_PEROID";
export const CLEAR_BLOCKED_PEROIDS= "CLEAR_BLOCKED_PEROIDS";
export const DELETE_BLOCKED_PEROID= "DELETE_BLOCKED_PEROID";
export const UPDATE_CALENDAR_PENDING = "UPDATE_CALENDAR_PENDING";
export const UPDATE_CALENDAR_SUCCESS = "UPDATE_CALENDAR_SUCCESS";
export const UPDATE_CALENDAR_ERROR = "UPDATE_CALENDAR_ERROR";
export const OPEN_UPDATE_AVAILABILITY_CONFLICT = "OPEN_UPDATE_AVAILABILITY_CONFLICT";
export const CLOSE_UPDATE_AVAILABILITY_CONFLICT = "CLOSE_UPDATE_AVAILABILITY_CONFLICT";
export const OPEN_CONFIRM_DELETION_BLOCKED = "OPEN_CONFIRM_DELETION_BLOCKED";
export const CLOSE_CONFIRM_DELETION_BLOCKED = "CLOSE_CONFIRM_DELETION_BLOCKED";
export const OPEN_ADD_BLOCKED_TODAY = "OPEN_ADD_BLOCKED_TODAY";
export const CLOSE_ADD_BLOCKED_TODAY = "CLOSE_ADD_BLOCKED_TODAY";

export const AvailabilityOptions = [
  { value: true, label: locale.AvailabilitySettings.open, id: 1 },
  { value: false, label: locale.AvailabilitySettings.closed, id: 0 }
];

export const EMPTY_VALUE = "00:00";
export const EMPTY_SERVER_VALUE = "00:00:00";
export const SERVER_TIME_FORMAT = "HH:mm:SS";
export const CLIENT_TIME_FORMAT = "HH:mm";
export const CLOSED_DAY_FORMAT = "DD/MM/YYYY";
export const DaysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const DefaultOpenHours = [
  {
    day: 1,
    timesSessions: [
      { from: EMPTY_VALUE, to: EMPTY_VALUE }
    ]
  },
  {
    day: 2,
    timesSessions: [
      { from: EMPTY_VALUE, to: EMPTY_VALUE }
    ]
  },
  {
    day: 3,
    timesSessions: [
      { from: EMPTY_VALUE, to: EMPTY_VALUE }
    ]
  },
  {
    day: 4,
    timesSessions: [
      { from: EMPTY_VALUE, to: EMPTY_VALUE }
    ]
  },
  {
    day: 5,
    timesSessions: [
      { from: EMPTY_VALUE, to: EMPTY_VALUE }
    ]
  },
  {
    day: 6,
    timesSessions: [
      { from: EMPTY_VALUE, to: EMPTY_VALUE }
    ]
  },
  {
    day: 0,
    timesSessions: [
      { from: EMPTY_VALUE, to: EMPTY_VALUE }
    ]
  },
];

export const PeriodsList = [
  { value: 5, label: '5 minutes' },
  { value: 10, label: '10 minutes' },
  { value: 15, label: '15 minutes' },
  { value: 20, label: '20 minutes' },
  { value: 25, label: '25 minutes' },
  { value: 30, label: '30 minutes' },
  { value: 35, label: '35 minutes' },
  { value: 40, label: '40 minutes' },
  { value: 45, label: '45 minutes' },
  { value: 50, label: '50 minutes' },
  { value: 55, label: '55 minutes' },
  { value: 60, label: '60 minutes' },
];

export const AdvancedOptions = [
  {
    value: 3,
    label: "3 months",
    type: "advance"
  },
  {
    value: 6,
    label: "6 months",
    type: "advance"
  },
  {
    value: 9,
    label: "9 months",
    type: "advance"
  },
  {
    value: 12,
    label: "12 months",
    type: "advance"
  },];

export const LastMinuteOptions = [
  {
    value: 0,
    label: "No cutoff",
    type: "lastMinute"
  },
  {
    value: 3,
    label: "3 hrs",
    type: "lastMinute"
  },
  {
    value: 6,
    label: "6 hrs",
    type: "lastMinute"
  },
  {
    value: 12,
    label: "12 hrs",
    type: "lastMinute"
  },
  {
    value: 24,
    label: "24 hrs",
    type: "lastMinute"
  },
  {
    value: 48,
    label: "48 hrs",
    type: "lastMinute"
  },
];

export const CALENDAR_UPDATED = 'CalendarUpdated';
export const CALENDAR_UPDATE_ERROR = 'CalendarUpdateError';

export const EVENT_EXTRA_SHORT_SUMMARY = 'summary-extra-short';
export const EVENT_SHORT_SUMMARY = 'summary-short';
export const EVENT_FULL_SUMMARY = 'summary-full';
export const MINUTES_INTERVAL_TO_EVENT_SUMMARY_TYPE = [
  { type: EVENT_EXTRA_SHORT_SUMMARY, interval: [-Infinity, 30] },
  { type: EVENT_SHORT_SUMMARY, interval: [30.001, 60] },
  { type: EVENT_FULL_SUMMARY, interval: [60.001, Infinity] }
];

export const defaultNewCalendarAvailability = {
  [DaysOfWeek[1]]: {
    status: locale.AddNewCalendar.dayAvailableOptions[1].value    
  },
  [DaysOfWeek[2]]: {
    status: locale.AddNewCalendar.dayAvailableOptions[1].value
  },
  [DaysOfWeek[3]]: {
    status: locale.AddNewCalendar.dayAvailableOptions[1].value
  },
  [DaysOfWeek[4]]: {
    status: locale.AddNewCalendar.dayAvailableOptions[1].value
  },
  [DaysOfWeek[5]]: {
    status: locale.AddNewCalendar.dayAvailableOptions[1].value
  },
  [DaysOfWeek[6]]: {
    status: locale.AddNewCalendar.dayAvailableOptions[1].value
  },
  [DaysOfWeek[0]]: {
    status: locale.AddNewCalendar.dayAvailableOptions[1].value
  },
};

export const timeMaskOptions = {
  '9': '[0-9]',
  'm': '[0-2]',
  'M': '[0-3]',
  's': '[0-5]',
  'S': '[0-9]'
};
