import React, { Component } from 'react';
import { connect } from "react-redux";
import { Checkbox } from '@patient-access/ui-kit';
import { CalendarFiltersSection, CalendarFiltersTitle, CalendarFiltersBody, CalendarFiltersBodyItem } from '../wrappers';
import { ButtonToggle } from '../button';
import { filterEventsByStatuses } from "actions/calendarView";
import moment from "moment";

type Props = {
  appointmentsList: any[],
  statusFilters: Object,
  filterEventsByStatuses: (selectedAppointments: Object) => any
}

type State = {
  isSectionExpanded: boolean,
  unselectedStatuses: any,
}

const mapStateToProps = state => ({
  appointmentsList: state.calendarView.events,
  statusFilters: state.calendarView.statusFilters,
});

const mapDispatchToProps = (dispatch: (action: any) => Action): any => ({
  filterEventsByStatuses: statusFilter => dispatch(filterEventsByStatuses(statusFilter))
});

class SectionStatuses extends Component<Props, State> {
  state = {
    isSectionExpanded: true,
    unselectedStatuses: [],
  }

  componentDidMount = () => {
    const { statusFilters } = this.props;
    var unselectedStatuses = [];
    unselectedStatuses = (statusFilters && statusFilters.unselectedStatuses && statusFilters.unselectedStatuses !== null) ? statusFilters.unselectedStatuses : [];
    this.setState({ unselectedStatuses: unselectedStatuses});
  }

  handleAllStatusesSelected = (e: any) => {
    const { appointmentsList, filterEventsByStatuses } = this.props;
    const { unselectedStatuses } = this.state;
    var allStatuses = [1, 2, 3, 4, 5, 6];
    var selectedAppointments = [];
    if (!e.target.checked) {
      allStatuses.forEach(status => {
        unselectedStatuses.push(status);
      });
      appointmentsList.forEach(appointment => appointment.appointmentId && selectedAppointments.push(appointment.appointmentId));
    } else {
      allStatuses.forEach(status => {
        var statusIndex = unselectedStatuses.indexOf(status)
        unselectedStatuses.splice(statusIndex, 1);
      });
    }
    this.setState({ unselectedStatuses });
    var statusFilter = { selectedAppointments: selectedAppointments, unselectedStatuses: this.state.unselectedStatuses}
    filterEventsByStatuses(statusFilter);
  }

  handleStatusFilter = (e: any) => { 
    const { unselectedStatuses } = this.state;
    const { appointmentsList, filterEventsByStatuses } = this.props;
    var selectedAppointments = [];
    var value = parseInt(e.target.value);
    if (!e.target.checked) {
      unselectedStatuses.push(value);
    } else {
      var statusIndex = unselectedStatuses.indexOf(value)
      unselectedStatuses.splice(statusIndex, 1);
    }
    this.setState({ unselectedStatuses });
    if (unselectedStatuses.includes(1)) {
      const currentDateTime = moment.utc().toISOString();

      var upcomingAppointments = appointmentsList.filter(appointment => appointment.status === 1 && moment.utc(appointment.endTime).toISOString() > currentDateTime);
      upcomingAppointments.map(appointment => selectedAppointments.push(appointment.appointmentId));
    }
    if (unselectedStatuses.includes(2)) {
      const currentDateTime = moment.utc().toISOString();
  
      var updateRequiredAppointments = appointmentsList.filter(appointment => appointment.status === 1 && appointment.outcomeStatus === 0 && moment.utc(appointment.endTime).toISOString() < currentDateTime);
      updateRequiredAppointments.map(appointment => selectedAppointments.push(appointment.appointmentId));
    }
    if (unselectedStatuses.includes(3)) {
      var cancelledAppointments = appointmentsList.filter(appointment => appointment.status === 3);
      cancelledAppointments.map(appointment => selectedAppointments.push(appointment.appointmentId));
    }
    if (unselectedStatuses.includes(4)) {
      var serviceProvidedAppointments = appointmentsList.filter(appointment => appointment.status === 2 && appointment.outcomeStatus === 1);
      serviceProvidedAppointments.map(appointment => selectedAppointments.push(appointment.appointmentId));
    }
    if (unselectedStatuses.includes(5)) {
      var serviceNotProvidedAppointments = appointmentsList.filter(appointment => appointment.status === 2 && appointment.outcomeStatus === 2);
      serviceNotProvidedAppointments.map(appointment => selectedAppointments.push(appointment.appointmentId));
    }
    if (unselectedStatuses.includes(6)) {
      var missedAppointments = appointmentsList.filter(appointment => appointment.status === 2 && appointment.outcomeStatus === 3);
      missedAppointments.map(appointment => selectedAppointments.push(appointment.appointmentId));
    }
    selectedAppointments = [...new Set(selectedAppointments)];
    var statusFilter = { selectedAppointments: selectedAppointments, unselectedStatuses: this.state.unselectedStatuses}
    filterEventsByStatuses(statusFilter);
  }

  render() {
    const { isSectionExpanded, unselectedStatuses } = this.state;
    return (
      <CalendarFiltersSection> 
        <CalendarFiltersTitle>
          <Checkbox size="small" id="section_statuses" name="section_statuses" value={0} label={`Statuses (${6 - unselectedStatuses.length})`} checked={!(unselectedStatuses.length > 0)} onChange={(e) => { this.handleAllStatusesSelected(e) }} />
          <ButtonToggle onClick={(e) => { e.preventDefault(); this.setState({ isSectionExpanded: !isSectionExpanded }); }} isExpanded={isSectionExpanded} />
        </CalendarFiltersTitle>
        <CalendarFiltersBody isExpanded={isSectionExpanded}>
          <CalendarFiltersBodyItem>
            <Checkbox size="small" id="section_statuses_01" name="section_statuses_01" value={1} label="Upcoming" checked={!unselectedStatuses.includes(1)} onChange={(e) => { this.handleStatusFilter(e) }} />
          </CalendarFiltersBodyItem>
          <CalendarFiltersBodyItem>
            <Checkbox size="small" id="section_statuses_02" name="section_statuses_02" value={2} label="Update required" checked={!unselectedStatuses.includes(2)} onChange={(e) => { this.handleStatusFilter(e) }} />
          </CalendarFiltersBodyItem>
          <CalendarFiltersBodyItem>
            <Checkbox size="small" id="section_statuses_03" name="section_statuses_03" value={3} label="Cancelled" checked={!unselectedStatuses.includes(3)} onChange={(e) => { this.handleStatusFilter(e) }} />
          </CalendarFiltersBodyItem>
          <CalendarFiltersBodyItem>
            <Checkbox size="small" id="section_statuses_04" name="section_statuses_04" value={4} label="Service provided" checked={!unselectedStatuses.includes(4)} onChange={(e) => { this.handleStatusFilter(e) }} />
          </CalendarFiltersBodyItem>
          <CalendarFiltersBodyItem>
            <Checkbox size="small" id="section_statuses_05" name="section_statuses_05" value={5} label="Service not provided" checked={!unselectedStatuses.includes(5)} onChange={(e) => { this.handleStatusFilter(e) }} />
          </CalendarFiltersBodyItem>
          <CalendarFiltersBodyItem>
            <Checkbox size="small" id="section_statuses_06" name="section_statuses_06" value={6} label="Did not attend" checked={!unselectedStatuses.includes(6)} onChange={(e) => { this.handleStatusFilter(e) }} />
          </CalendarFiltersBodyItem>
        </CalendarFiltersBody>
      </CalendarFiltersSection>
    );
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionStatuses);
