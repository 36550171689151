import type { Action } from "types/actions";
import { openSlots, closeSlots, openBooking, openInternalEvent, closeInternalEvent } from "actions/panel";
import { setResources, setEvents, setDate, showSelectedSlot } from "actions/calendarView";
import {
  // getEventsListFromSlots,
  getAllItemsListFromAllCalendars,
  getAllItemsListForCalendarView,
  setHiddenAttributeToSlotsByCalendar
} from "helpers/common";
import { closeAgenda, closeBooking } from "actions/panel";
import * as BookConstants from "constants/BookAppointmentConstants";
import { CALENDAR_VIEWS }  from "constants/AppointmentConstants";
import type { AppointmentDetailsType } from "constants/AppointmentDetailsConstants";
import * as AppointmentDetailsConstants from "constants/AppointmentDetailsConstants";
import agent from "service/agent";
import moment from "moment";
import locale from "service/locale";
import { getToken } from "helpers/common";
import { updateForm, clearForm } from 'actions/form';
import { showSnackbarStatus } from 'actions/snackbar';
import { getBranchCalendarsAppointments } from "actions/branches";
import { getAgendaAppointments } from "actions/agenda";
import type { SlotCalendar } from "types/appointments";
import * as ServiceConstants from "constants/ServicesConstants";
import { getAppointmentDuration } from "../helpers/formatData";

export const setCalendar = (calendar: any): Action => ({
  type: BookConstants.SET_BOOKING_CALENDAR,
  payload: calendar
});

export const setSlotCalendarsList = (slotCalendarsList: any): Action => ({
  type: BookConstants.SET_SLOT_CALENDARS_LIST,
  payload: slotCalendarsList
});

export const setService = (service: any): Action => ({
  type: BookConstants.SET_BOOKING_SERVICE,
  payload: service
});

export const setSlots = (slotsList: any[]): Action => ({
  type: BookConstants.SET_BOOKING_SLOTS,
  payload: slotsList
});

export const bookAppointmentPending = (): Action => ({
  type: BookConstants.BOOK_APPOINTMENT_PENDING,
});

export const bookAppointmentPendingStop = (): Action => ({
  type: BookConstants.BOOK_APPOINTMENT_PENDING_STOP,
});

export const bookAppointmentSuccess = (): Action => ({
  type: BookConstants.BOOK_APPOINTMENT_SUCCESS,
});

export const bookAppointmentError = (status?: boolean = false): Action => ({
  type: BookConstants.BOOK_APPOINTMENT_ERROR,
  payload: status
});

export const holdAppointmentError = (status?: boolean = false): Action => ({
  type: BookConstants.HOLD_APPOINTMENT_ERROR,
  payload: status
});

export const getSlotPending = (): Action => ({
  type: BookConstants.GET_SLOTS_PENDING,
});

export const getSlotSuccess = (): Action => ({
  type: BookConstants.GET_SLOTS_SUCCESS,
});

export const getSlotError = (): Action => ({
  type: BookConstants.GET_SLOTS_ERROR,
});

export const createInternalEventPending = (): Action => ({
  type: BookConstants.CREATE_INTERNAL_EVENT_PENDING
});

export const createInternalEventSuccess = (): Action => ({
  type: BookConstants.CREATE_INTERNAL_EVENT_SUCCESS
});

export const createInternalEventError = (): Action => ({
  type: BookConstants.CREATE_INTERNAL_EVENT_ERROR
});

export const setSlotTime = (
  startTime: string,
  endTime: string,
  slotId: string,
  appointmentId: string,
  calendarId: string,
): Action => ({
  type: BookConstants.SET_BOOKING_TIME,
  payload: { startTime, endTime, slotId, appointmentId, calendarId }
});

export const openBookingForm = (
  startTime: string,
  endTime: string,
  defaultCalendar: SlotCalendar,
  slotCalendarsList: SlotCalendar[]
) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const branchCalendarsList = currentState.branchDetails.calendarsList;
  const selectedCalendarId = currentState.book.calendar.id || defaultCalendar.calendarId;
  const previouslySelectedSlot = currentState.book.slotId;
  const slotId =
    selectedCalendarId === defaultCalendar.calendarId ?
    defaultCalendar.slotId :
    slotCalendarsList.find(slotCalendar => slotCalendar.calendarId === selectedCalendarId).slotId;
  const appointmentId = currentState.book.appointmentId;

  if (previouslySelectedSlot === slotId)
    return;

  const serviceId = currentState.book.service.id;
  const branchId = currentState.router.branchId;
  const duration = currentState.book.service.duration;
  const appointmentData = {
    branchId,
    serviceId,
    slotId,
    duration,
    start: startTime,
    patient: {
      postcode: null,
      email: null,
      phone: null,
      patientId: null,
      firstName: null,
      lastName: null,
      dateOfBirth: null
    }
  };
  const formattedSlotCalendarsList = [];
  slotCalendarsList.forEach(calendar => {
    const foundCalendar = branchCalendarsList.find(branchCalendar => branchCalendar.id === calendar.calendarId);
    if (foundCalendar) {
      formattedSlotCalendarsList.push({
        id: foundCalendar.id,
        name: foundCalendar.name,
        slotId: calendar.slotId
      })
    }
  });
  if (!!appointmentId) {
    getToken(dispatch).then(accessToken => {
      agent.Appointments.releaseSlot(appointmentId, accessToken)
        .then(() => {
          agent.Appointments.holdSlot(appointmentData, accessToken)
            .then(heldAppointment => {
              const {
                appointmentId
              } = heldAppointment;
              dispatch(setSlotTime(startTime, endTime, slotId, appointmentId, selectedCalendarId));
              dispatch(setSlotCalendarsList(formattedSlotCalendarsList));
              if (serviceId === ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID)
                dispatch(openInternalEvent());
              else
                dispatch(openBooking());
            })
            .catch(err => {
              console.log(err)
              if ((err && err.response && err.response.status === 404) || (err && err.response && err.response.status === 400)) {
                dispatch(holdAppointmentError(true));
              } else
                console.log("hold appointment server error", err);
            })
        });
    });
  } else {
    getToken(dispatch).then(accessToken => {
      agent.Appointments.holdSlot(appointmentData, accessToken)
        .then(heldAppointment => {
          const {
            appointmentId
          } = heldAppointment;
          dispatch(setSlotTime(startTime, endTime, slotId, appointmentId, selectedCalendarId));
          dispatch(setSlotCalendarsList(formattedSlotCalendarsList));
          if (serviceId === ServiceConstants.INTERNAL_EVENTS_AS_SERVICE_ID)
            dispatch(openInternalEvent());
          else
            dispatch(openBooking());
        })
        .catch(err => {
          console.log(err)
          if ((err && err.response && err.response.status === 404) || (err && err.response && err.response.status === 400)) {
            dispatch(holdAppointmentError(true));
          } else
            console.log("hold appointment server error", err);
        })
    });
  }
};

export const releaseSlot = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const appointmentId = currentState.book.appointmentId;
  dispatch(setSlotTime('', '', '', '', ''));
  return getToken(dispatch).then(accessToken => {
    return agent.Appointments.releaseSlot(appointmentId, accessToken);
  });
};

export const filterSlotsByCalendar = (calendar: any) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const newSlotsList = [...currentState.book.slots];
  const newEventsList = currentState.calendarView.events
    .map(calendarEvent => {
      const { start, end, title, id, calendars } = calendarEvent;
      const isHiddenCalendar =
          calendar.value !== "all"
          && !calendarEvent.calendars.find(slotCalendar => slotCalendar.calendarId === calendar.value);
      return ({ start, end, id, title, calendars, isHiddenCalendar })
    });
  newSlotsList.forEach(dailySlots => {
    let dailySlotsWithCalendar = 0;
    dailySlots.slots.forEach(slot => {
      slot.isHiddenCalendar = false;
      if (calendar.value !== "all" && !slot.calendars.find(slotCalendar => slotCalendar.calendarId === calendar.value)) {
        slot.isHiddenCalendar = true;
      } else dailySlotsWithCalendar++;
    });
    dailySlots.isHiddenDay = !dailySlotsWithCalendar;
  });
  dispatch(setEvents(newEventsList));
  dispatch(setSlots(newSlotsList));
  dispatch(setCalendar({
      name: calendar.label,
      id: calendar.value
    }))
  return Promise.resolve();
};

export const changeCalendarForSelectedSlot = (calendar: any) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const formData = currentState.form;
  const appointmentId = currentState.book.appointmentId;
  const serviceId = currentState.book.service.id;
  const branchId = currentState.router.branchId;
  const startTime = currentState.book.startTime;
  const endTime = currentState.book.endTime;
  const slotId = calendar.slotId;
  const duration = currentState.book.service.duration;
  const appointmentData = {
    branchId,
    serviceId,
    slotId,
    start: startTime,
    duration,
    patient: {
      postcode: formData.postcode,
      email: formData.email,
      phone: formData.phone,
      patientId: null,
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth: formData.DOB
    }
  };
  dispatch(setCalendar({
      name: calendar.label,
      id: calendar.value
    }));
  getToken(dispatch).then(accessToken => {
    agent.Appointments.releaseSlot(appointmentId, accessToken)
      .then(() => {
        agent.Appointments.holdSlot(appointmentData, accessToken)
          .then(heldAppointment => {
              const { appointmentId } = heldAppointment;
              dispatch(setSlotTime(startTime, endTime, slotId, appointmentId, calendar.value));
          })
      })
  });
};

export const openSlotsPanel = (service: Object) => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const currentDate = currentState.calendarView.currentDate;
  const viewPeriod = currentState.calendarView.currentView;
  const todayDate = moment.utc().toISOString();
  const currentDateDate = moment.utc(currentDate).toISOString();
  if (currentDateDate < todayDate)
   dispatch(setDate(moment.utc().startOf(viewPeriod).toDate()));
  else
    dispatch(setDate(moment.utc(currentDateDate).startOf(viewPeriod).toDate()));
  dispatch(setResources(null));
  dispatch(
    setService({
      name: service.label,
      id: service.value,
      duration: service.duration,
      price: service.price,
      lastMinute: service.lastMinute,
      type: service.appointmentType,
      modes: service.modes
    })
  );
  dispatch(openSlots());
  dispatch(closeAgenda());
};

export const closeSlotsPanel = () => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const branchCalendarsList = currentState.branchDetails.calendarsList;
  const branchId = currentState.router.branchId;
  const currentViewPeriod = currentState.calendarView.currentView;
  const currentDate = currentState.calendarView.currentDate;
  const isShowCancellation = currentState.calendarView.isShowCancellation;
  const calendarFilters = currentState.calendarView.calendarFilters;
  const serviceFilters = currentState.calendarView.serviceFilters;
  const statusFilters = currentState.calendarView.statusFilters;
  dispatch(setCalendar({ id: "", name: "[ Auto selected from avail. ]" }));
  const currentDateDate = moment.utc(currentDate).toISOString();
  const startTime = moment.utc(currentDateDate).startOf(currentViewPeriod + "s").toISOString();
  const endTime = currentViewPeriod === CALENDAR_VIEWS.MONTH
    ? moment.utc(currentDateDate).endOf(currentViewPeriod + "s").toISOString()
    : moment.utc(currentDateDate).add(1, currentViewPeriod + "s").toISOString();
  getToken(dispatch).then(accessToken => {
    agent.Appointments.getAllItemsForCalendar(
      branchId,
      startTime,
      endTime,
      accessToken
    ).then(({ allItemsByDate }) => {
      const formattedAppointmentsList = getAllItemsListFromAllCalendars(allItemsByDate);
      dispatch(setEvents(getAllItemsListForCalendarView(formattedAppointmentsList, isShowCancellation, calendarFilters, serviceFilters, statusFilters, branchCalendarsList)));
      dispatch(
        setResources(
          branchCalendarsList.map(calendar => ({
            resourceId: calendar.id,
            resourceTitle: calendar.name[0].toUpperCase()
          }))
        )
      );
      dispatch(closeSlots());
      dispatch(
        setService({
          name: "",
          id: "",
          duration: "",
          price: "",
          lastMinute: 0,
          type: "",
          modes: []
        })
      );
    });
  });
};

export const getSlots = (branchId: string) => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const serviceId = currentState.book.service.id;
  const selectedCalendarId = currentState.book.calendar.id;
  const viewPeriod = currentState.calendarView.currentView;
  let currentDate = currentState.calendarView.currentDate;
  const todayDate = moment().utc().toISOString();
  const currentDateDate = moment.utc(currentDate).toISOString();
  let start;
  let end;
  let duration = currentState.book.service.duration;
  if (currentDateDate <= todayDate) {
    start = todayDate;
  } else {
    start = moment.utc(currentDateDate).startOf(viewPeriod + "s").toISOString();
  }
  end = viewPeriod === CALENDAR_VIEWS.MONTH
    ? moment.utc(currentDateDate).endOf(viewPeriod + "s").toISOString()
    : moment.utc(currentDateDate).add(1, viewPeriod + "s").toISOString();
  const slotData = {
    branchId,
    serviceId,
    start,
    end,
    duration
  };
  let emptySlots = [];
  dispatch(setSlots(emptySlots));
  dispatch(getSlotPending());
  return getToken(dispatch).then(accessToken => {
    return agent.Appointments.getSlots(slotData, accessToken)
      .then(({ slots }) => {
        let slotEvents = [];
        let zIndex = 10;
        slots.forEach((date, dateIndex) => {
          slotEvents.push({ date: date.date, slots: [] });
          zIndex++;
          date.slots.forEach((slot, slotIndex) => {
            const formattedStartTime = date.date.split("T")[0] + "T" + slot.start;
            const formattedEndTime = date.date.split("T")[0] + "T" + slot.end;
            const formattedSlotTitle = slot.start.slice(0, -3) + "-" + slot.end.slice(0, -3);
            slotEvents[dateIndex].slots.push({ start: "", end: "", title: "", zIndex});
            const start = moment(formattedStartTime).toDate();
            const end = moment(formattedEndTime).toDate();
            slotEvents[dateIndex].slots[slotIndex].start = start;
            slotEvents[dateIndex].slots[slotIndex].end = end;
            slotEvents[dateIndex].slots[slotIndex].title = formattedSlotTitle;
            slotEvents[dateIndex].slots[slotIndex].calendars = slot.calendars;
            if (selectedCalendarId) {
              slotEvents[dateIndex].slots[slotIndex].isHiddenCalendar =
                !slot.calendars
                  .find(slotCalendar => slotCalendar.calendarId === selectedCalendarId)
            }
            slot.timePeriod = slot.start.split(":")[0] < BookConstants.AFTERNOON_HOUR ? locale.AppointmentSlots.morning : locale.AppointmentSlots.afternoon;
            slot.title = slot.start;
            slot.start = formattedStartTime;
            slot.end = formattedEndTime;
            slot.zIndex = zIndex;
            zIndex++;
          });
        });
        if (selectedCalendarId) {
          setHiddenAttributeToSlotsByCalendar(slots, selectedCalendarId);
        }
        // this is causing an anomaly in the calendarView.events reducer
        // dispatch(setEvents(getEventsListFromSlots(slotEvents)));
        dispatch(setEvents([]));
        dispatch(showSelectedSlot(null));
        dispatch(setSlots(slots));
        dispatch(getSlotSuccess());
      })
      .catch(err => {
        dispatch(getSlotError());
        console.log("getSlots server error", err);
      });
  });
};

export const bookAppointment = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const appointmentId = currentState.book.appointmentId;
  const { book_firstName, book_lastName, book_phoneNumber, book_email, book_DOB, book_postcode, book_appointment_title, book_servicePrice, appointmentReason, book_appointmentType } = currentState.form;
  const splittedDOB = book_DOB ? book_DOB.replace(/[/.]/g, "-").split("-") : null;
  const formattedDOB = splittedDOB ? [splittedDOB[0], splittedDOB[1], splittedDOB[2]].join("-") : null;
  const bookingData = {
    patient: {
      postcode: book_postcode ? book_postcode : null,
      email: book_email ? book_email : null,
      phone: book_phoneNumber ? book_phoneNumber : null,
      patientId: null,
      firstName: book_firstName ? book_firstName : null,
      lastName: book_lastName ? book_lastName : null,
      dateOfBirth: formattedDOB
    },
    reason: appointmentReason,
    title: book_appointment_title,
    price: book_servicePrice,
    appointmentType: book_appointmentType.value
  };
  dispatch(bookAppointmentPending());
  getToken(dispatch)
    .then(accessToken => {
      agent.Appointments.bookAppointment(bookingData, appointmentId, accessToken)
        .then(() => {
          dispatch(bookAppointmentSuccess());
          dispatch(setEvents([]));
          dispatch(updateForm());
          dispatch(closeBooking());
          dispatch(closeSlots());
          dispatch(setSlotTime('', '', '', '', ''));
          dispatch(getBranchCalendarsAppointments());
          dispatch(getAgendaAppointments());
          dispatch(showSnackbarStatus("Appointment successfully booked"));
        })
        .catch(err => {
          if (err && err.response && err.response.status === 404) {
            dispatch(bookAppointmentError(true));
          }
          else
            console.log("book appointment server error", err);
        })
    })
};

export const createInternalEvent = (title: string) => (
  dispatch: Function, getState: Function) => {
  const currentState = getState();
  const appointmentId = currentState.book.appointmentId;
  const eventData = {
    title: title
  };
  dispatch(createInternalEventPending());
  getToken(dispatch).then(accessToken => {
        agent.Appointments.createInternalEvent(eventData, appointmentId, accessToken)
          .then(() => {
            dispatch(setEvents([]));
            dispatch(createInternalEventSuccess());
            dispatch(closeInternalEvent());
            dispatch(closeSlots());
            dispatch(setSlotTime('', '', '', '', ''));
            dispatch(getBranchCalendarsAppointments());
            dispatch(getAgendaAppointments());
            dispatch(clearForm());
            dispatch(showSnackbarStatus(locale.Snackbar.internalEventCreated));
          })
          .catch(err => {
            dispatch(createInternalEventError());
            dispatch(showSnackbarStatus(locale.Snackbar.internalEventNotCreated));
            console.log("creating new internal event server error", err);
          });
  });
}

export const getCalendarsWithParticularEmptySlot = (type: AppointmentDetailsType) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  let appointmentDetails =
      type === AppointmentDetailsConstants.APPOINTMENT_DETAILS_TYPES.AGENDA
      ? currentState.appointmentDetails
      : currentState.search.details;
  appointmentDetails = appointmentDetails || { branch: {}, service: {} };
  const branchId = appointmentDetails.branch.id;
  const serviceId = appointmentDetails.service.id;
  const appointmentCalendars = currentState.branchDetails.calendarsList;
  const reassignedAppointmentId = appointmentDetails.appointmentId;
  const duration = getAppointmentDuration(appointmentDetails.startTime, appointmentDetails.endTime);
  const slotData = { branchId, serviceId, start: null, end: null, reassignedAppointmentId, duration };
  return getToken(dispatch).then(accessToken => {
    return agent.Appointments.getSlots(slotData, accessToken)
      .then(({slots}) => {
        return slots.length && slots[0].slots[0].calendars.map(calendar => {
          return {
            slotId: calendar.slotId,
            id: calendar.calendarId,
            name: appointmentCalendars.find(appointmentCalendar => appointmentCalendar.id === calendar.calendarId).name,
          };
        });
      })
      .catch(err => {
        console.log("getCalendarsWithParticularEmptySlot server error", err);
      });
  });
};
