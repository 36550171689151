export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const SET_CALENDAR_DATE = "SET_CALENDAR_DATE";
export const SET_CALENDAR_PERIOD = "SET_CALENDAR_PERIOD";
export const SET_CALENDAR_RESOURCES = "SET_CALENDAR_RESOURCES";
export const SET_CALENDAR_FILTERS = "SET_CALENDAR_FILTERS";
export const SET_CALENDAR_SERVICE_FILTERS = "SET_CALENDAR_SERVICE_FILTERS";
export const SET_CALENDAR_APPOINTMENT_STATUS_FILTERS = "SET_CALENDAR_APPOINTMENT_STATUS_FILTERS";
export const SET_BRANCH_NAME= "SET_BRANCH_NAME";
export const SET_CALENDAR_BRANCH_ID = "SET_CALENDAR_BRANCH_ID";
export const SET_CALENDAR_ORGANISATION_ID = "SET_CALENDAR_ORGANISATION_ID";
export const SET_CALENDAR_CANCELLATION_VIEW = "SET_CALENDAR_CANCELLATION_VIEW";
export const SET_CALENDAR_TIME_INTERVAL = "SET_CALENDAR_TIME_INTERVAL";
export const SHOW_SELECTED_SLOT = "SHOW_SELECTED_SLOT";
export const SET_BRANCH_RESOURCES = "SET_BRANCH_RESOURCES";

export const COLOR_MAX_NUMBER = 16;
